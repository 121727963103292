import React, { FC } from "react";
import { Field, FieldProps } from "formik";
import { FormItem, Select } from "formik-antd";
import { kALLOWED_CHART_TYPES } from "../../../../types/charts/Chart";
import * as Yup from "yup";
import { ChartSchemaDefinition } from "../../../../types/Chart";
import { PropertiesField } from "./PropertiesField";
import { GraphicPrerenderer } from "./GraphicPrerenderer";
import { ValuesField } from "./ValuesField";
import { GaugeField } from "./RangesField";

export const kCHART_SCHEMA_SCHEMA = Yup.object<ChartSchemaDefinition>({
  chartType: Yup.string().required("Especifica el tipo de gráfico"),
  properties: Yup.array().of(
    Yup.object({
      name: Yup.string().required(
        "ID de la propiedad de la que se extraerá el dato"
      ),
      title: Yup.string().required("Título para mostrar en el gráfico"),
      color: Yup.string(),
      type: Yup.string(),
    })
  ),
  ranges: Yup.array().of(
    Yup.object({
      title: Yup.string(),
      tick: Yup.number(),
      color: Yup.string(),
    })
  ),
});
export const FormikChartSchemaEditor: FC<any> = () => {
  return (
    <Field name={"schema"}>
      {(props: any) => (
        <div className={"my-2 p-2"}>
          {!!props.field.value && <FormikChartSchemaField {...props} />}
        </div>
      )}
    </Field>
  );
};

export const FormikChartSchemaField: FC<FieldProps<ChartSchemaDefinition>> = (
  props
) => {
  const {
    field: { value, name },
  } = props;
  return (
    <div className={"w-full grid gap-2 grid-cols-2"}>
      <div className={"col-span-1"}>
        <FormItem required name={`${name}.chartType`} label={"Tipo de gráfica"}>
          <Select
            name={`${name}.chartType`}
            placeholder={"Seleccione un tipo de gráfico."}
            allowClear
            options={kALLOWED_CHART_TYPES.map((it) => ({
              label: it.title,
              value: it.key,
            }))}
          />
        </FormItem>

        {value.chartType === "line" ||
        value.chartType === "bar" ||
        value.chartType === "area" ? (
          <PropertiesField {...props} />
        ) : null}
        {value.chartType === "hplot" && <ValuesField {...props} />}
        {value.chartType === "gauge" && <GaugeField {...props} />}
      </div>
      <div className={"px-4"}>
        <GraphicPrerenderer schema={value} showButton={true} />
      </div>
    </div>
  );
};
