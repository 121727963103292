import color from "string-to-color";
import { ChartSchemaDefinition } from "../types/Chart";
import { fastFormatDate, formatDate } from "./Dates";
import { ChartData, ChartData1, ChartData2 } from "../types/charts/Chart";
import { sample } from "lodash";
import { DateTime } from "luxon";

export function wordToColor(str: string) {
  return color(str);
}

export function generateChartColors(schema?: ChartSchemaDefinition) {
  const colors: any = {};
  if (!schema) return colors;
  if (!!schema.properties) {
    for (let prop of schema.properties) {
      colors[prop.name] = prop.color ? prop.color : wordToColor(prop.name);
    }
  }
  return colors;
}

export function generateChartTitles(schema?: ChartSchemaDefinition) {
  const titles: any = {};
  if (!schema) return titles;
  if (!!schema.properties) {
    for (let prop of schema.properties) {
      titles[prop.name] = prop.title ? prop.title : prop.name;
    }
  }
  return titles;
}

export function parseChartDate(
  date: string | Date,
  range: [Date, Date] | undefined
) {
  return typeof date === "string" ? date : formatDate(range, date as Date);
}

export function generateChartMockingData(
  schema: ChartSchemaDefinition
): ChartData[] {
  if (schema.chartType === "gauge") {
    console.log(schema);
    const arr: Array<any> = [];
    schema.gauge!!.forEach((gauge) => {
      arr.push({
        name: gauge.property,
        title: gauge.title,
        value: 0.5,
        date: fastFormatDate(DateTime.now().toJSDate(), "HH:mm:ss"),
      });
    });
    return [arr] as any;
  }
  if (
    !!schema.chartType &&
    !!schema.properties &&
    schema.properties.length > 0
  ) {
    const properties = schema.properties;
    switch (schema.chartType) {
      case "line":
      case "area":
        const result: Array<ChartData1<number>> = [];
        if (result.length < 50) {
          for (let i = -1; ++i < 50; ) {
            const randomProperty = sample(properties)!!;
            result.push({
              name: randomProperty.name,
              title: randomProperty.title || randomProperty.name,
              value: Math.round(Math.random() * i),
              date: DateTime.now().plus({ minute: i }).toJSDate(),
            });
          }
        }
        return result;
      case "bar":
        const result2: Array<ChartData1<number>> = [];
        if (result2.length < 50) {
          for (let i = -1; ++i < 15; ) {
            const randomProperty = sample(properties)!!;
            result2.push({
              name: randomProperty.name,
              title: randomProperty.title || randomProperty.name,
              value: Math.round(Math.random() * i),
              date: `05:${i}`,
            });
            const randomProperty2 = sample(properties)!!;
            result2.push({
              name: randomProperty2.name,
              title: randomProperty2.title || randomProperty2.name,
              value: Math.round(Math.random() * i),
              date: `05:${i}`,
            });
          }
        }
        return result2;
      case "hplot":
        const result3: Array<ChartData2> = [];
        if (result3.length < 30) {
          let repeats = 0;
          let lastDatetime = DateTime.now();
          for (let i = -1; ++i < 15; ) {
            const randomProperty = sample(properties)!!;
            const start = lastDatetime.plus({
              minute: i,
            });
            repeats = Math.round(Math.random() * 10);
            const end = start.plus({
              minute: repeats,
            });
            result3.push({
              title: randomProperty.title || randomProperty.name,
              name: randomProperty.name,
              repeats,
              end: end.toJSDate(),
              start: start.toJSDate(),
            });
            lastDatetime = end;
          }
        }
        return result3;
    }
  }
  return [];
}

export function parseChartData1ForApex(
  schema: ChartSchemaDefinition,
  items: ChartData1<number>[]
) {
  const result: ApexAxisChartSeries = schema.properties.map((it) => {
    const _currIt = items
      .filter((it2) => it2.name === it.name)
      .map((it2) => ({
        x: it2.date,
        y: it2.value,
      }));
    return {
      data: _currIt,
    };
  });
  return result;
}
