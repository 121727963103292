import { FC } from "react";
import AcmeIcon from "../../../assets/AICON.png";
import LogoAcme from "../../../assets/Logo-ACME.png";
import LogoAcmeWhite from "../../../assets/Logo-ACME-white.png";
import { Layout } from "antd";
import { DashboardSiderMenu } from "./Menu";
import {useDayNightTheme} from "../../hooks/SwitchTheme";

type Props = {
  collapsed: boolean;
  isMobile: boolean;
};
export const DashboardLayoutSiderContent: FC<Props> = ({
  collapsed,
  isMobile,
}) => {
    const {darkMode} = useDayNightTheme()
  return (
    <div className={"w-full h-full overflow-hidden flex flex-col px-2"}>
      {collapsed || isMobile ? (
        <Layout.Header
          className={
            "flex bg-transparent justify-center items-center w-full overflow-hidden p-0 py-3 m-0"
          }
        >
          <img
            alt={"icon"}
            src={AcmeIcon}
            className={"h-fit w-fit object-contain max-h-full max-w-full"}
          />
        </Layout.Header>
      ) : (
        <Layout.Header
          className={
            "flex bg-transparent justify-center items-center w-full overflow-hidden p-0 pt-8 m-0"
          }
        >
          <img
            alt={"icon"}
            src={darkMode ? LogoAcmeWhite : LogoAcme}
            className={"h-fit w-fit object-contain max-h-full max-w-full"}
          />
        </Layout.Header>
      )}
      <div className={`${collapsed ? "mt-0" : "mt-8"}`} />
      <DashboardSiderMenu />
    </div>
  );
};
