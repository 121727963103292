import { ProDescriptions } from "@ant-design/pro-components";
import { FC, useEffect, useState } from "react";
import {
  Button,
  ColorPicker,
  Descriptions,
  Form,
  notification,
  Typography,
  Image,
} from "antd";
import { Customer } from "../../../../../types/Customer";
import dayjs from "dayjs";
import { useCustomerViewModel } from "../../../../../viewmodel/Customer";
import { AppLoader } from "../../../../components/AppLoader";
import { useFormValidation } from "../../../../hooks/Validation";
import Yup from "yup";
import { UpdateCustomerStyleFormData } from "../../../../../types/CustomerStyle";
import { Upload } from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCancel,
  faEdit,
  faPlus,
  faSave,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import NoImage from "../../../../../assets/NoImage.js";
import {DashboardStateContainer} from "../../../../components/DashboardStateContainer";

type Props = {
  customer: Customer;
};
export const CustomerAppearanceTab: FC<Props> = ({ customer }) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [primaryColor, setPrimaryColor] = useState<string | null>(null);
  const [secondaryColor, setSecondaryColor] = useState<string | null>(null);
  const [pickedLogo, setPickedLogo] = useState<UploadFile<any>[] | undefined>();
  const [previewImage, setPreviewImage] = useState<string | undefined>();

  const {
    fetchCustomerStyle,
    onFetchCustomerStyleStateReceived,
    customerStyle,
    fetchCustomerStyleState,
    updateCustomerStyle,
    updateCustomerLogo,
    onUpdateCustomerStyleStateReceived,
    updateCustomerStyleState,
    onUpdateCustomerLogoStateReceived,
    updateCustomerLogoState,
  } = useCustomerViewModel();

  const onImagePicked: UploadProps["onChange"] = (images) => {
    const isInvalid = images.fileList.filter(
      (image) => image.type !== "image/png" && image.type !== "image/jpeg",
    );
    if (isInvalid.length > 0) {
      notification.error({
        message: "El formato de la imagen no es válido.",
      });
      setPickedLogo(undefined);
    } else {
      setPickedLogo(images.fileList);
    }

    if (!!images.fileList && images.fileList.length >= 1) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const dataUrl = event.target!!.result;
        console.log(dataUrl);
        setPreviewImage(dataUrl as string);
      };
      reader.readAsDataURL(images!!.fileList[0]!!.originFileObj!!);
    } else {
      setPreviewImage(undefined);
    }
  };

  function onButtonClicked() {
    if (editMode) {
      const d: any = {};
      if (!!primaryColor || !!secondaryColor) {
        if (!!primaryColor) d.primaryColor = primaryColor;
        if (!!secondaryColor) d.secondaryColor = secondaryColor;
        d.reason = "Update customer style...";
        void updateCustomerStyle(customer.id, d);
      }
    } else {
      setEditMode(true);
    }
  }

  function onCancelClicked() {
    setPrimaryColor(customerStyle?.primaryColor || "#00000000");
    setSecondaryColor(customerStyle?.secondaryColor || "#00000000");
    setEditMode(false);
    setPickedLogo(undefined);
    setPreviewImage(undefined);
  }

  useEffect(() => {
    void fetchCustomerStyle(customer.id);
  }, []);

  useEffect(() => {
    if (!!fetchCustomerStyleState && !fetchCustomerStyleState.loading) {
      if (fetchCustomerStyleState.hasError) {
        notification.error({
          message: "Ocurrió un error al obtener la apariencia del cliente.",
        });
      }
      onFetchCustomerStyleStateReceived();
    }
  }, [fetchCustomerStyleState]);

  useEffect(() => {
    if (!!updateCustomerStyleState && !updateCustomerStyleState.loading) {
      if (updateCustomerStyleState.hasError) {
        notification.error({
          message: "Ocurrió un error al actualizar la apariencia del cliente.",
        });
      } else {
        if (!!pickedLogo && pickedLogo.length > 0) {
          void updateCustomerLogo(customer.id, pickedLogo[0]!!.originFileObj!!);
        }
      }
      onFetchCustomerStyleStateReceived();
    }
  }, [updateCustomerStyleState]);

  return (
    <DashboardStateContainer>
      <AppLoader
        loading={
          (!!fetchCustomerStyleState && fetchCustomerStyleState.loading) ||
          (!!updateCustomerStyleState && updateCustomerStyleState.loading)
        }
      />
      <div className={"p-3 flex flex-col gap-3"}>
        <Descriptions>
          <Descriptions.Item
            label={"Color Primario"}
            labelStyle={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ColorPicker
              disabled={!editMode}
              defaultValue={customerStyle?.primaryColor || "#00000000"}
              onChange={(_, hex) => {
                setPrimaryColor(hex);
              }}
              value={primaryColor}
            />
          </Descriptions.Item>
          <Descriptions.Item
            label={"Color Secundario"}
            labelStyle={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ColorPicker
              disabled={!editMode}
              defaultValue={customerStyle?.secondaryColor || "#00000000"}
              onChange={(_, hex) => {
                setSecondaryColor(hex);
              }}
              value={secondaryColor}
            />
          </Descriptions.Item>
        </Descriptions>
        {!!customerStyle?.logo ? (
          <Form layout={"vertical"}>
            <Form.Item label={"Logo"}>
              <Image src={customerStyle?.logo} fallback={NoImage} />
            </Form.Item>
          </Form>
        ) : null}

        {editMode ? (
          <Form layout={"vertical"}>
            <Form.Item label={"Logo"}>
              <div
                className={
                  "w-full flex flex-col overflow-x-hidden justify-start items-start gap-3"
                }
              >
                {!!previewImage && (
                  <Image className={"max-w-xs max-h-44"} src={previewImage} />
                )}
                <Upload
                  type={"select"}
                  accept={"image/*"}
                  maxCount={1}
                  fileList={pickedLogo}
                  beforeUpload={(file) => {
                    setPickedLogo([file]);
                    return false;
                  }}
                  onChange={onImagePicked}
                >
                  <Button icon={<FontAwesomeIcon icon={faUpload} />}>
                    Seleccionar
                  </Button>
                </Upload>
              </div>
            </Form.Item>
          </Form>
        ) : null}

        <Button.Group>
          <Button
            type={"primary"}
            onClick={onButtonClicked}
            icon={<FontAwesomeIcon icon={editMode ? faEdit : faSave} />}
            disabled={
              editMode
                ? !(
                    !!primaryColor ||
                    !!secondaryColor ||
                    (!!pickedLogo && pickedLogo.length > 0)
                  )
                : false
            }
          >
            {editMode ? "Guardar" : "Editar"}
          </Button>
          {editMode && (
            <Button
              onClick={onCancelClicked}
              icon={<FontAwesomeIcon icon={faCancel} />}
            >
              Cancelar
            </Button>
          )}
        </Button.Group>
      </div>
    </DashboardStateContainer>
  );
};
