import { FC, useCallback, useEffect, useState } from "react";
import { DashboardSubpageHeader } from "../../components/DashboardHeader";
import {useNavigate, useParams} from "react-router-dom";
import { Button, notification } from "antd";
import { AppLoader } from "../../components/AppLoader";
import { DashboardStateContainer } from "../../components/DashboardStateContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRefresh,
  faHashtag,
  faTag,
  faTextSlash,
  faAt,
  faCalendar,
  faIdCard,
  faUserTag,
  faToggleOn,
  faBuilding,
  faPeopleGroup, faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { fastFormatDate } from "../../../utils/Dates";
import {
  DescriptionsList,
  DescriptionsItem,
} from "../../components/DescriptionsList";
import { DashboardBreadCrumb } from "../../components/DashboardBreadcrumb";
import * as yup from "yup";
import {
  UpdateBottomSheet,
  UpdateBottomSheetFormItemType,
} from "../../components/UpdateBottomSheet";
import { useDashboardHeader } from "../../hooks/Header";
import { useCustomerUserViewModel } from "../../../viewmodel/CustomerUser";
import { UpdateCustomerUserFormData } from "../../../types/CustomerUser";
import {DeleteBottomSheet} from "../../components/DeleteBottomSheet";
import {toNumber} from "lodash";

const kUPDATE_USER_SCHEMA = yup.object({
  email: yup.string().email("Ingresa un email válido"),
  corporateEmail: yup.string().email("Ingresa un email válido"),
  name: yup.string().min(3, "Ingrese un nombre válido."),
  lastname: yup.string().min(3, "Ingrese un apellido válido."),
  username: yup.string().min(6, "Es necesario al menos 6 caracteres."),
  birthDate: yup.date(),
  reason: yup.string().required("Detalle la descripción de esta actividad: "),
});

const kUPDATE_USER_ITEMS: UpdateBottomSheetFormItemType[] = [
  {
    name: "name",
    label: "Nombre(s)",
    type: "string",
    renderProps: {
      placeholder: "Ingresar nombre(s)",
    },
  },
  {
    name: "lastname",
    label: "Apellido(s)",
    type: "string",
    renderProps: {
      placeholder: "Ingresar apellido(s)",
    },
  },
  {
    name: "username",
    label: "Nombre de usuario",
    type: "string",
    renderProps: {
      placeholder: "Ingresar nombre de usuario",
    },
  },
  {
    name: "email",
    label: "Email secundario",
    type: "string",
    renderProps: {
      placeholder: "Ingresar email secundario",
    },
  },
  {
    name: "corporateEmail",
    label: "Email corporativo",
    type: "string",
    renderProps: {
      placeholder: "Ingresar email corporativo",
    },
  },
  {
    name: "birthDate",
    label: "Cumpleaños",
    type: "date",
    renderProps: {
      className: "w-full",
    },
  },
  {
    name: "enabled",
    label: "Usuario habilitado",
    type: "switch",
  },
];

export const DashboardCustomerUserPage: FC = () => {
  const { id } = useParams();
  const {
    customerUser,
    fetchUserState,
    fetchUser,
    onFetchUserStateReceived,
    updateUser,
    updateState,
    onUpdateUserStateReceived,
      deleteCustomerUser,
      deleteState,
      onDeleteStateReceived
  } = useCustomerUserViewModel();
  const { setTitle } = useDashboardHeader();
  const [activeEditableItem, setActiveEditableItem] = useState<
    string[] | undefined
  >();
  const [initialValues, setInitialValues] = useState<
    Partial<UpdateCustomerUserFormData>
  >({});
  const onEditClicked = useCallback(
    (name: string) => {
      if (name === "enabled") {
        setInitialValues({
          enabled: customerUser?.enabled,
        });
      } else {
        setInitialValues({});
      }
      setActiveEditableItem([name]);
    },
    [setActiveEditableItem, setInitialValues, customerUser],
  );
  const fetchUserData = () => {
    void fetchUser(Number(id));
  };
  const navigate = useNavigate()
  const [deleteEvent, setDeleteEvent] = useState(false)
  const toggleDelete = useCallback(()=>{
    setDeleteEvent(old=>!old)
  }, [setDeleteEvent])

  useEffect(() => {
    fetchUserData();
    setTitle("Usuario de Cliente");
  }, []);

  useEffect(() => {
    if (!!fetchUserState && !fetchUserState.loading) {
      if (fetchUserState.hasError) {
        notification.error({
          message: "Error al obtener el usuario para el cliente",
        });
      }
      onFetchUserStateReceived();
    }
  }, [fetchUserState]);

  useEffect(() => {
    if (!!updateState && !updateState.loading) {
      if (updateState.hasError) {
        notification.error({
          message: updateState.error?.message,
        });
      } else {
        fetchUserData();
        notification.success({
          message: "Información actualizada.",
        });
      }
      onUpdateUserStateReceived();
    }
  }, [updateState]);

  useEffect(() => {
    if (!!deleteState && !deleteState.loading) {
      if (deleteState.hasError) {
        notification.error({
          message: deleteState.error?.message,
        });
      } else {
        notification.success({
          message: "Usuario eliminado.",
        });
        navigate(-1)
      }
      onDeleteStateReceived();
    }
  }, [deleteState]);

  return (
    <DashboardStateContainer
      state={fetchUserState}
      className={"w-full h-fit overflow-hidden"}
    >
      <DashboardBreadCrumb
        items={[
          {
            pathname: "/dashboard/customers",
            title: "Clientes",
            icon: <FontAwesomeIcon icon={faPeopleGroup} />,
          },
          {
            pathname: `/dashboard/customers/${customerUser?.Customer.id}?tab=users`,
            title: customerUser?.Customer.businessName,
          },
          {
            active: true,
            title: customerUser?.username,
          },
        ]}
      />
      <UpdateBottomSheet<UpdateCustomerUserFormData>
        defaultValues={initialValues}
        validationSchema={kUPDATE_USER_SCHEMA}
        rowKey={"user"}
        title={"Actualizar Información"}
        items={kUPDATE_USER_ITEMS}
        onSubmit={updateUser}
        open={!!activeEditableItem}
        activeItems={activeEditableItem}
        onClose={() => setActiveEditableItem(undefined)}
      />
      <AppLoader
        loading={
          (!!fetchUserState && fetchUserState.loading) ||
          (!!updateState && updateState.loading) ||
            (!!deleteState && deleteState.loading)
        }
      />
      <DeleteBottomSheet rowKey={"deleteCustomerUser"} title={"Eliminar usuario"} id={toNumber(id!!)}
                         onSubmit={deleteCustomerUser} onClose={toggleDelete} open={deleteEvent} />
      <DashboardSubpageHeader
        title={`@${customerUser?.username}`}
        subtitle={customerUser?.Customer.businessName}
        extra={
          <Button.Group>
            <Button
              type={"default"}
              icon={<FontAwesomeIcon icon={faRefresh} />}
              onClick={fetchUserData}
            >
              Actualizar
            </Button>
            <Button type={"primary"} icon={<FontAwesomeIcon icon={faTrash} />} onClick={toggleDelete}>
              Eliminar
            </Button>
          </Button.Group>
        }
      />
      <DescriptionsList rowKey={"user"} onEditClicked={onEditClicked}>
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faHashtag} />}
          title={"ID"}
          label={customerUser?.id}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faBuilding} />}
          title={"Cliente"}
          label={customerUser?.Customer.businessName}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faTag} />}
          title={"Nombre(s)"}
          label={customerUser?.name}
          editable={true}
          itemKey={"name"}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faTag} />}
          title={"Apellido(s)"}
          label={customerUser?.lastname}
          editable={true}
          itemKey={"lastname"}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faTextSlash} />}
          title={"Nombre de usuario"}
          label={customerUser?.username}
          editable={true}
          itemKey={"username"}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faAt} />}
          title={"Email Principal"}
          label={customerUser?.corporateEmail}
          editable={true}
          itemKey={"corporateEmail"}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faAt} />}
          title={"Email Secundario"}
          label={customerUser?.email}
          editable={true}
          itemKey={"email"}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faCalendar} />}
          title={"Cumpleaños"}
          label={fastFormatDate(customerUser?.birthDate!!, "dd MMM yyyy")}
          editable={true}
          itemKey={"birthDate"}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faCalendar} />}
          title={"Miembro desde"}
          label={fastFormatDate(customerUser?.createdAt!!, "dd MMM yyyy")}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faIdCard} />}
          title={"DNI"}
          label={customerUser?.dni}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faUserTag} />}
          title={"Tipo de Usuario"}
          label={customerUser?.Role.label}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faToggleOn} />}
          title={"Estado"}
          label={
            customerUser?.enabled
              ? "Usuario habilitado"
              : "Usuario deshabilitado"
          }
          editable={true}
          itemKey={"enabled"}
        />
      </DescriptionsList>
    </DashboardStateContainer>
  );
};
