import { RemoteSourceResponse, UpdateResult } from "../../../types/Source";
import { apiService } from "../../../services/RemoteClient";
import { BaseRemoteSource } from "../base/Remote";
import { Chart, NewChart, UpdateChart } from "../../../types/Chart";
import { DeleteData } from "../../../types/App";

export class RemoteChartSource extends BaseRemoteSource {
  static async getChart(id: number): Promise<RemoteSourceResponse<Chart>> {
    try {
      const chart = await apiService.postWithAuth("/chart/get", { id });
      this.checkResponseCredentials(chart);
      return chart.data as RemoteSourceResponse<Chart>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async getAllCharts(): Promise<RemoteSourceResponse<Chart[]>> {
    try {
      const chartList = await apiService.getWithAuth("/chart/list");
      this.checkResponseCredentials(chartList);
      return chartList.data as RemoteSourceResponse<Chart[]>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async createChart(
    data: NewChart
  ): Promise<RemoteSourceResponse<Chart>> {
    try {
      const response = await apiService.postWithAuth("/chart/create", data);
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse<Chart>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async updateChart(
    updateData: UpdateChart
  ): Promise<RemoteSourceResponse> {
    try {
      const response = await apiService.putWithAuth(
        "/chart/update",
        updateData
      );
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async deleteChart(data: DeleteData) {
    try {
      const response = await apiService.deleteWithAuth("/chart/delete", {
        data,
      });
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse;
    } catch (e) {
      throw this.parseError(e);
    }
  }
}
