import { LoaderFunction, LoaderFunctionArgs } from "react-router-dom";
import { toNumber } from "lodash";

export function ParamTypeValidatorLoader(paramName: string): LoaderFunction {
  return function (c: LoaderFunctionArgs) {
    const p = c.params[paramName];
    return new Promise<boolean>((resolve, reject) => {
      if (isNaN(toNumber(p)))
        reject(new Error("El parámetro " + paramName + " no es válido."));
      else resolve(false);
    });
  };
}
