import { Vehicle } from "../../../../../types/Vehicle";
import { FC, useMemo } from "react";
import { Tabs, TabsProps } from "antd";
import { VehicleTrackingHistoryTab } from "../tracking_history/TabHistory";
import { VehicleDeviceHistoryTab } from "../device_history/TabDeviceHistory";
import { DashboardSubpageContainer } from "../../../../components/DashboardContainer";

type Props = {
  vehicle: Vehicle;
};

export const VehicleHistoryTab: FC<Props> = ({ vehicle }) => {
  const items = useMemo<TabsProps["items"]>(() => {
    return [
      {
        key: "2",
        label: "Variables",
        children: <VehicleDeviceHistoryTab vehicle={vehicle} />,
      },
      {
        key: "1",
        label: "Tracking",
        children: <VehicleTrackingHistoryTab vehicle={vehicle} />,
      },
    ];
  }, [vehicle]);
  return (
    <DashboardSubpageContainer>
      <Tabs items={items} defaultActiveKey={"2"} />
    </DashboardSubpageContainer>
  );
};
