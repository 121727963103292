import { Typography } from "antd";
import { parseChartDate } from "../../../utils/Charts";
import { toNumber } from "lodash";

type Props = {
  title: string;
  items: any[];
  titles?: any;
};

export function CustomChartTooltip({ title, items, titles }: Props) {
  if (!!items && items.length > 0) {
    return (
      <div className={"py-4 px-2 gap-1 flex flex-col"}>
        <Typography.Text strong type={"secondary"}>
          {title}
        </Typography.Text>
        {items.map((it) => (
          <div className={"flex flex-row gap-1"} key={`l-${it.name}`}>
            <span
              style={{
                background: it.color,
                width: 15,
                height: 15,
                borderRadius: 15,
              }}
            />
            <Typography.Text
              type={"secondary"}
              className={"text-xs flex flex-row gap-1"}
            >
              <span>
                {it.data.date ? it.data.date : null}
              </span>
              <span className={"font-semibold"}>
                {titles ? titles[it.data.name] : it.data.title}:
              </span>
              <span>
                {isNaN(it.data.value) ? it.data.value : toNumber(it.data.value)}
              </span>
            </Typography.Text>
          </div>
        ))}
      </div>
    );
  } else {
    return null;
  }
}
