import { RemoteDeviceDataSource } from "../source/device_data/Remote";

export class DeviceDataRepository {
  static async getDeviceDataList(
    gatewayKey: string,
    deviceKey: string[],
    dateStart: Date,
    dateEnd: Date,
  ) {
    return await RemoteDeviceDataSource.getDeviceData(
      gatewayKey,
      deviceKey,
      dateStart,
      dateEnd,
      "ASC",
    );
  }

  static async downloadDeviceData(
    gatewayKey: string,
    deviceKey: string[],
    dateStart: Date,
    dateEnd: Date,
    type: string,
  ) {
    await RemoteDeviceDataSource.downloadDeviceData(
      gatewayKey,
      deviceKey,
      dateStart,
      dateEnd,
      type,
    );
  }

  static async getDeviceLocation(gatewayKey: string, date: Date) {
    return await RemoteDeviceDataSource.getDeviceLocation(gatewayKey, date);
  }
}
