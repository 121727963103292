import { FC, useCallback } from "react";
import { SubmitButton, Form, Input, Select, FormItem } from "formik-antd";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faICursor,
  faUserGear,
  faCar,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { NewVehicleFormData } from "../../../../types/Vehicle";
import { Customer } from "../../../../types/Customer";
import { Formik, FormikHelpers } from "formik";
import { FormikActivityReasonField } from "../../../components/FormikActivityReasonField";

type Props = {
  customerList?: Customer[] | null;
  onFinish: (data: NewVehicleFormData) => Promise<boolean | undefined>;
  initialValues?: Partial<NewVehicleFormData>;
};

const kNEW_VEHICLE_VALIDATION = yup.object({
  name: yup.string().min(3, "Ingrese un nombre válido."),
  plate: yup
    .string()
    .matches(/^[A-Z]{3}-?[0-9]{3}$/, "Ingresa una placa válida."),
  brand: yup
    .string()
    .required("Es necesario especificar la marca del vehículo."),
  model: yup
    .string()
    .required("Es necesario especificar el modelo del vehículo."),
  customerId: yup
    .number()
    .required("Es necesario especificar un cliente para el vehículo."),
  reason: yup
    .string()
    .required("Especifique el motivo porque registra el nuevo vehículo.")
    .min(16, "Debe contener al menos 16 caracteres."),
});

export const VehicleCreateForm: FC<Props> = ({
  customerList,
  onFinish,
  initialValues,
}) => {
  const onFormSubmit = useCallback(
    (data: NewVehicleFormData, helpers: FormikHelpers<NewVehicleFormData>) => {
      onFinish(data).then((res) => {
        helpers.setSubmitting(false);
        if (res) helpers.resetForm();
      });
    },
    [onFinish],
  );

  return (
    <Formik<NewVehicleFormData>
      initialValues={initialValues as NewVehicleFormData}
      enableReinitialize
      onSubmit={onFormSubmit}
      validationSchema={kNEW_VEHICLE_VALIDATION}
      validateOnMount={true}
    >
      <Form layout={"vertical"}>
        <FormItem
          name={"name"}
          label={"Nombre"}
          tooltip={"Será utilizado como un identificador del vehículo"}
          required
        >
          <Input
            name={"name"}
            allowClear
            prefix={<FontAwesomeIcon icon={faICursor} />}
          />
        </FormItem>
        <FormItem
          required
          name={"plate"}
          label={"Placa"}
          tooltip={"Ej. ABC-123 ABC123"}
        >
          <Input
            name={"plate"}
            allowClear
            prefix={<FontAwesomeIcon icon={faCar} />}
          />
        </FormItem>
        <FormItem required name={"brand"} label={"Marca"}>
          <Input
            name={"brand"}
            allowClear
            prefix={<FontAwesomeIcon icon={faCar} />}
          />
        </FormItem>

        <FormItem required label={"Modelo"} name={"model"}>
          <Input
            prefix={<FontAwesomeIcon icon={faCar} />}
            name={"model"}
            allowClear
          />
        </FormItem>

        <FormItem
          label={"Detalles"}
          tooltip={
            "Puede detallar una breve descripción del vehículo o su finalidad."
          }
          name={"description"}
        >
          <Input.TextArea
            autoComplete={"off"}
            allowClear
            name={"description"}
          />
        </FormItem>

        <FormItem
          hidden={!!initialValues?.customerId}
          label={"Cliente"}
          name={"customerId"}
        >
          <Select
            placeholder={"Seleccione un cliente"}
            suffixIcon={<FontAwesomeIcon icon={faUserGear} />}
            options={customerList as any}
            allowClear
            fieldNames={{
              label: "businessName",
              value: "id",
            }}
            name={"customerId"}
          />
        </FormItem>

        <FormikActivityReasonField />
        <SubmitButton icon={<FontAwesomeIcon icon={faPaperPlane} />}>
          Registrar
        </SubmitButton>
      </Form>
    </Formik>
  );
};
