import {Vehicle} from "../../../../../types/Vehicle";
import {
    FC, useMemo,
} from "react";
import {Tabs, TabsProps} from "antd";
import {DashboardSubpageContainer} from "../../../../components/DashboardContainer";
import {VehicleTrackingTab} from "../tracking/TabTracking";
import {VehicleDeviceTab} from "../device/TabDevice";

type Props = {
    vehicle: Vehicle;
};

export const VehicleDashboardTab: FC<Props> = ({vehicle}) => {
    const items = useMemo<TabsProps["items"]>(() => {
        return ([{
            key: '1',
            label: 'Variables',
            children: <VehicleDeviceTab vehicle={vehicle}/>,
        },{
            key: '2',
            label: 'Tracking',
            children:  <VehicleTrackingTab vehicle={vehicle}/>,
        },
        ])
    }, [vehicle])
    return <DashboardSubpageContainer>
        <Tabs items={items} defaultActiveKey={"1"}/>
    </DashboardSubpageContainer>
};
