import { omit } from "lodash";
import { NewChartFormData, UpdateChartFormData } from "../../types/Chart";
import { DeleteFormData } from "../../types/App";
import { RemoteChartSource } from "../source/chart/Remote";

export class ChartRepository {
  static async getChartList() {
    return await RemoteChartSource.getAllCharts();
  }

  static async createChart(data: NewChartFormData) {
    const activity = {
      reason: data.reason,
    };
    return await RemoteChartSource.createChart({
      activity,
      data: omit(data, ["reason"]),
    });
  }

  static async getChart(id: number) {
    return await RemoteChartSource.getChart(id);
  }

  static async updateChart(id: number, data: UpdateChartFormData) {
    const activity = {
      reason: data.reason,
    };
    return await RemoteChartSource.updateChart({
      activity,
      data: omit(data, ["reason"]),
      id,
    });
  }

  static async deleteChart(data: DeleteFormData) {
    return RemoteChartSource.deleteChart({
      activity: {
        reason: data.reason,
      },
      id: data.id,
    });
  }
}
