import { useState } from "react";
import { AppState, TaskState } from "../data/domain/State";
import { NewUserFormData, UpdateUserFormData, User } from "../types/User";
import { UserRepository } from "../data/repository/User";
import {DeleteFormData} from "../types/App";

export function useUserViewModel() {
  const [createState, setCreateState] = useState<AppState<boolean> | null>(
    null,
  );
  const [user, setUser] = useState<User | null>(null);
  const [fetchUserState, setFetchUserState] =
    useState<AppState<boolean> | null>(null);
  const [updateState, setUpdateState] = useState<AppState<boolean> | null>();
  const [deleteState, setDeleteState] = useState<AppState<boolean> | null>()
  const [fetchListState, setFetchListState] =
    useState<AppState<boolean> | null>(null);
  const [userList, setUserList] = useState<Array<User> | undefined>();

  async function fetchList() {
    if (fetchListState?.loading) return;
    setFetchListState(TaskState.loading());
    try {
      const users = await UserRepository.getUserList();
      if (users!.ok) {
        setUserList(users!.data!!);
        setFetchListState(TaskState.success(true));
      } else {
        setFetchListState(TaskState.error(new Error(users!.message)));
      }
    } catch (e: any) {
      setFetchListState(TaskState.error(e));
    }
  }

  function onFetchListStateReceived() {
    setFetchListState(null);
  }

  async function fetchUser(id: number) {
    if (fetchUserState?.loading) return;
    setFetchUserState(TaskState.loading());
    try {
      const result = await UserRepository.getUser(id);
      if (result!!.ok) {
        setUser(result!!.data!!);
        setFetchUserState(TaskState.success(true));
      } else {
        setFetchUserState(TaskState.error(new Error(result!!.message)));
      }
    } catch (e: any) {
      setFetchUserState(TaskState.error(e));
    }
  }

  function onFetchUserStateReceived() {
    setFetchUserState(null);
  }
  async function createUser(data: NewUserFormData) {
    if (createState?.loading) return false;
    setCreateState(TaskState.loading());
    try {
      const res = await UserRepository.createUser(data);
      if (res!!.ok) {
        setCreateState(TaskState.success(true));
        return true;
      } else {
        setCreateState(TaskState.error(new Error(res!!.message)));
        return false;
      }
    } catch (e: any) {
      setCreateState(TaskState.error(e));
      return false;
    }
  }

  function onCreateStateReceived() {
    setCreateState(null);
  }

  async function updateUser(
    data: UpdateUserFormData,
  ): Promise<boolean | undefined> {
    if (updateState?.loading) return;
    setUpdateState(TaskState.loading());
    if (!user) {
      setUpdateState(TaskState.error(new Error("Usuario no encontrado.")));
      return;
    }
    try {
      const result = await UserRepository.updateUser(user.id, data);
      if (result.ok) {
        setUpdateState(TaskState.success(true));
        console.log(result.data)
        return true;
      } else {
        setUpdateState(TaskState.error(new Error(result.message)));
      }
    } catch (e: any) {
      setUpdateState(TaskState.error(e));
    }
  }

  function onUpdateUserStateReceived() {
    setUpdateState(null);
  }

  async function deleteUser(data: DeleteFormData) {
    if(deleteState?.loading) return
    setDeleteState(TaskState.loading())
    try {
      await UserRepository.deleteUser(data)
      setDeleteState(TaskState.success(true))
      return true
    } catch (e: any) {
      setDeleteState(TaskState.error(e))
    }
  }

  function onDeleteStateReceived() {
    setDeleteState(null)
  }

  return {
    createState,
    createUser,
    onCreateStateReceived,
    user,
    fetchUser,
    fetchUserState,
    onFetchUserStateReceived,
    updateUser,
    updateState,
    onUpdateUserStateReceived,
    fetchListState,
    fetchList,
    onFetchListStateReceived,
    userList,
    deleteState,
    onDeleteStateReceived,
    deleteUser
  };
}
