import {RemoteGatewaySource} from "../source/gateway/Remote";
import {NewGatewayFormData, UpdateGatewayFormData} from "../../types/Gateway";
import {omit} from "lodash"
import {DeleteFormData} from "../../types/App";

export class GatewayRepository {
    static async getGatewayList(vehicleId: number | undefined = undefined) {
        return await RemoteGatewaySource.getAllGateways(vehicleId)
    }

    static async getAvailableGateway() {
        return await RemoteGatewaySource.getAvailableGateways()
    }

    static async createGateway(data: NewGatewayFormData) {
        const activity = {
            reason: data.reason
        }
        return await RemoteGatewaySource.createGateway({
            activity,
            data: omit(data, ['reason'])
        })
    }

    static async getGateway(id: number) {
        return await RemoteGatewaySource.getGateway(id)
    }

    static async updateGateway(id: number, data: UpdateGatewayFormData) {
        const activity = {
            reason: data.reason
        }
        return await RemoteGatewaySource.updateGateway({
            activity,
            data: omit(data, ['reason', 'gatewayId']),
            id
        })
    }

    static async deleteGateway(data: DeleteFormData) {
        return RemoteGatewaySource.deleteGateway({
            activity: {
                reason: data.reason
            },
            id: data.id
        })
    }
}