import { AuthPage } from "../ui/pages/auth";
import { AuthRepository } from "../data/repository/Auth";
import { Navigate, NavLink, Outlet } from "react-router-dom";
import { PasswordRecoveryPage } from "../ui/pages/password";
import { PasswordRecoveryValidationPage } from "../ui/pages/recover";
import { DashboardLayout } from "../ui/layouts/DashboardLayout";
import { AppRoute } from "../types/Route";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartSimple,
  faMagnifyingGlassChart,
  faMapLocation,
  faPeopleGroup,
  faTruck,
  faUserGroup,
  faHomeAlt,
  faUserGear,
  faUsersGear,
  faServer,
  faProjectDiagram,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import { DashboardUserListPage } from "../ui/dashboard/user_list/UserList";
import { DashboardUserCreatePage } from "../ui/dashboard/user_create/UserCreate";
import { DashboardUserRolesPage } from "../ui/dashboard/user_roles/UserRoles";
import { DashboardRolePermissionsListPage } from "../ui/dashboard/role_permissions/main/RolePermissions";
import { DashboardRolePermissionPage } from "../ui/dashboard/role_permissions/role/RolePermission";
import { DashboardCustomerListPage } from "../ui/dashboard/customer_list/CustomerList";
import { DashboardCustomerCreatePage } from "../ui/dashboard/customer_create/CustomerCreate";
import { DashboardVehicleListPage } from "../ui/dashboard/vehicle_list/VehicleList";
import { DashboardVehicleCreatePage } from "../ui/dashboard/vehicle_create/VehicleCreate";
import { DashboardDeviceListPage } from "../ui/dashboard/device_list/DeviceList";
import { DashboardDeviceCreatePage } from "../ui/dashboard/device_create/DeviceCreate";
import { DashboardOverviewPage } from "../ui/dashboard/overview/DashboardOverview";
import { DashboardGatewayListPage } from "../ui/dashboard/gateway_list/GatewayList";
import { DashboardGatewayCreatePage } from "../ui/dashboard/gateway_create/GatewayCreate";
import { Button, Layout, Result } from "antd";
import { DashboardVehiclePage } from "../ui/dashboard/vehicle/Vehicle";
import { LoadingError } from "../ui/components/LoadingError";
import { DashboardUserPage } from "../ui/dashboard/user/User";
import { DashboardCustomerPage } from "../ui/dashboard/customer/Customer";
import { DashboardDeviceUpdatePage } from "../ui/dashboard/device_update/DeviceUpdate";
import { MainLayout } from "../ui/layouts/MainLayout";
import { DashboardCustomerUserCreatePage } from "../ui/dashboard/customer_user_create/UserCreate";
import { DashboardHeaderProvider } from "../ui/context/Header";
import { ParamTypeValidatorLoader } from "./Params";
import { DashboardGatewayPage } from "../ui/dashboard/gateway/Gateway";
import { DashboardCustomerUserPage } from "../ui/dashboard/customer_user/User";
import { DashboardChartListPage } from "../ui/dashboard/chart_list/ChartList";
import { DashboardChartCreatePage } from "../ui/dashboard/chart_create/ChartCreate";
import { DashboardChartUpdatePage } from "../ui/dashboard/chart_update/ChartUpdate";
import { DashboardUserRoleCreatePage } from "../ui/dashboard/user_role_create/UserRoleCreate";

export const zAPP_ROUTES: AppRoute[] = [
  {
    path: "/dashboard",
    element: (
      <DashboardHeaderProvider>
        <DashboardLayout />
      </DashboardHeaderProvider>
    ),

    loader: async () => AuthRepository.getSession(),
    errorElement: <Navigate to={"/auth"} />,
    info: {
      icon: <FontAwesomeIcon icon={faHomeAlt} />,
      label: "Inicio",
    },
    children: [
      {
        path: "/dashboard/overview",
        element: <DashboardOverviewPage />,
        info: {
          label: "Resúmen",
          icon: <FontAwesomeIcon icon={faChartSimple} />,
        },
      },

      {
        path: "/dashboard/customers",
        element: <DashboardCustomerListPage />,
        info: {
          label: "Clientes",
          icon: <FontAwesomeIcon icon={faPeopleGroup} />,
        },
      },
      {
        path: "/dashboard/vehicles",
        element: <DashboardVehicleListPage />,
        info: {
          label: "Vehículos",
          icon: <FontAwesomeIcon icon={faTruck} />,
        },
      },
      {
        path: "/dashboard/gateways",
        element: <DashboardGatewayListPage />,
        info: {
          label: "Gateways",
          icon: <FontAwesomeIcon icon={faServer} />,
        },
      },
      {
        path: "/dashboard/devices",
        element: <DashboardDeviceListPage />,
        info: {
          label: "Variables",
          icon: <FontAwesomeIcon icon={faProjectDiagram} />,
        },
      },
      {
        path: "/dashboard/charts",
        element: <DashboardChartListPage />,
        info: {
          label: "Charts",
          icon: <FontAwesomeIcon icon={faChartSimple} />,
        },
      },
      {
        path: "/dashboard/users",
        element: <Outlet />,
        errorElement: <LoadingError />,
        info: {
          label: "Administración de acceso",
          icon: <FontAwesomeIcon icon={faKey} />,
        },
        children: [
          {
            path: "/dashboard/users",
            element: <DashboardUserListPage />,
            errorElement: <LoadingError />,
            info: {
              label: "Usuarios",
              icon: <FontAwesomeIcon icon={faUserGroup} />,
            },
          },
          {
            path: "/dashboard/users/roles",
            element: <DashboardUserRolesPage />,
            info: {
              label: "Roles",
              icon: <FontAwesomeIcon icon={faUsersGear} />,
            },
          },
          {
            path: "/dashboard/users/policies",
            element: <DashboardRolePermissionsListPage />,
            info: {
              label: "Políticas",
              icon: <FontAwesomeIcon icon={faUserGear} />,
            },
          },
          {
            path: "/dashboard/users/roles/create",
            element: <DashboardUserRoleCreatePage />,
          },
        ],
      },
      {
        path: "/dashboard/charts/create",
        element: <DashboardChartCreatePage />,
        info: {
          ignore: true,
        },
      },
      {
        path: "/dashboard/charts/:id",
        element: <DashboardChartUpdatePage />,
        info: {
          ignore: true,
        },
        loader: ParamTypeValidatorLoader("id"),
      },
      {
        path: "/dashboard/devices/create",
        element: <DashboardDeviceCreatePage />,
        info: {
          ignore: true,
        },
      },
      {
        path: "/dashboard/devices/:id",
        element: <DashboardDeviceUpdatePage />,
        info: {
          ignore: true,
        },
        loader: ParamTypeValidatorLoader("id"),
      },
      {
        path: "/dashboard/gateways/create",
        element: <DashboardGatewayCreatePage />,
        info: {
          ignore: true,
        },
      },
      {
        path: "/dashboard/gateways/:id",
        element: <DashboardGatewayPage />,
        info: {
          ignore: true,
        },
        loader: ParamTypeValidatorLoader("id"),
      },
      {
        path: "/dashboard/vehicles/create",
        element: <DashboardVehicleCreatePage />,
        info: {
          ignore: true,
        },
      },
      {
        path: "/dashboard/vehicles/:id",
        element: <DashboardVehiclePage />,
        errorElement: <LoadingError />,
        info: {
          ignore: true,
        },
        loader: ParamTypeValidatorLoader("id"),
      },
      {
        path: "/dashboard/customers/users/create",
        element: <DashboardCustomerUserCreatePage />,
        info: {
          ignore: true,
        },
      },
      {
        path: "/dashboard/customers/users/:id",
        element: <DashboardCustomerUserPage />,
        info: {
          ignore: true,
        },
        loader: ParamTypeValidatorLoader("id"),
      },
      {
        path: "/dashboard/customers/create",
        element: <DashboardCustomerCreatePage />,
        info: {
          ignore: true,
        },
        errorElement: <LoadingError />,
      },
      {
        path: "/dashboard/customers/:id",
        element: <DashboardCustomerPage />,
        errorElement: <LoadingError />,
        info: {
          ignore: true,
        },
        loader: ParamTypeValidatorLoader("id"),
      },
      {
        path: "/dashboard/users_roles/permissions/:id",
        element: <DashboardRolePermissionPage />,
        info: {
          ignore: true,
        },
        loader: ParamTypeValidatorLoader("id"),
      },
      {
        path: "/dashboard/users/:id",
        element: <DashboardUserPage />,
        errorElement: <LoadingError />,
        info: {
          ignore: true,
        },
        loader: ParamTypeValidatorLoader("id"),
      },
      {
        path: "/dashboard/users/create",
        element: <DashboardUserCreatePage />,
        info: {
          ignore: true,
        },
        errorElement: <LoadingError />,
      },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to={"/dashboard/overview"} />,
      },
      {
        path: "/auth",
        errorElement: <AuthPage />,
        loader: async () => AuthRepository.getSession(),
        element: <Navigate to={"/dashboard/overview"} />,
      },
      {
        path: "/recover-password",
        errorElement: <PasswordRecoveryPage />,
        loader: async () => AuthRepository.getSession(),
        element: <Navigate to={"/dashboard/overview"} />,
      },
      {
        path: "/password-recovery-validation",
        errorElement: <PasswordRecoveryValidationPage />,
        loader: async () => AuthRepository.getSession(),
        element: <Navigate to={"/dashboard/overview"} />,
      },
      {
        path: "*",
        element: (
          <Layout className={"w-full h-full"}>
            <Result
              status="404"
              title="404"
              subTitle="La página que intentas acceder no existe."
              extra={
                <Button type="primary">
                  <NavLink to={"/dashboard/overview"}>Ir al Dashboard</NavLink>
                </Button>
              }
            />
          </Layout>
        ),
      },
    ],
  },
];
