import { ChartData1 } from "../../../types/charts/Chart";
import React from "react";
import { BaseLinearChart } from "./BaseLinear";
import { ChartSchemaDefinition } from "../../../types/Chart";

type Props = {
  tooltipTitle: string;
  items: Array<ChartData1<number>>;
  id: string;
  schema: ChartSchemaDefinition;
};

export function BarChart2(props: Props) {
  return <BaseLinearChart {...props} type={"line"} />;
}
