import { FC, useCallback } from "react";
import { NewCustomerUserFormData } from "../../../../types/CustomerUser";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faICursor,
  faIdCard,
  faAt,
  faKey,
  faUserGear,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { Role } from "../../../../types/Role";
import { Customer } from "../../../../types/Customer";
import { Formik, FormikHelpers } from "formik";
import {
  FormItem,
  SubmitButton,
  Form,
  Select,
  Input,
  Switch,
} from "formik-antd";
import { FormikActivityReasonField } from "../../../components/FormikActivityReasonField";
import { FormikAntDatePicker } from "../../../components/FormikAntDatePicker";

type Props = {
  userRoleList?: Role[];
  onFinish: (data: NewCustomerUserFormData) => Promise<boolean | undefined>;
  initialValues?: Partial<NewCustomerUserFormData>;
  customerList?: Customer[];
};

const kNEW_CUSTOMER_USER_VALIDATION = yup.object({
  email: yup.string().email("Ingresa un email válido"),
  password: yup
    .string()
    .required("Ingresa una contraseña")
    .min(8, "La contraseña necesita al menos 8 caracteres."),
  corporateEmail: yup
    .string()
    .required("Ingresa un email")
    .email("Ingresa un email válido"),
  dni: yup
    .string()
    .required("Ingresa un número de DNI.")
    .length(8, "Ingrese un número de DNI válido.")
    .matches(/^(0|1)?\d{8}$/, "Ingrese un DNI válido."),
  name: yup.string().min(3, "Ingrese un nombre válido."),
  lastname: yup.string().min(3, "Ingrese un apellido válido."),
  username: yup
    .string()
    .required("Es necesario un nombre de usuario.")
    .min(6, "Es necesario al menos 6 caracteres."),
  birthDate: yup
    .date()
    .required("Es necesario registrar la fecha de nacimiento."),
  roleId: yup
    .number()
    .required("Es necesario especificar un rol para el usuario."),
  customerId: yup
    .number()
    .required("Es necesario especificar un cliente para el usuario."),
  reason: yup
    .string()
    .required("Especifique el motivo porque registra al nuevo usuario.")
    .min(16, "Debe contener al menos 16 caracteres."),
  enabled: yup.boolean(),
});

export const CustomerUserCreateForm: FC<Props> = ({
  userRoleList,
  onFinish,
  customerList,
  initialValues,
}) => {
  const onFormSubmit = useCallback(
    (
      data: NewCustomerUserFormData,
      helpers: FormikHelpers<NewCustomerUserFormData>,
    ) => {
      onFinish(data).then((res) => {
        helpers.setSubmitting(false);
        if (res) helpers.resetForm();
      });
    },
    [onFinish],
  );

  return (
    <Formik<NewCustomerUserFormData>
      initialValues={initialValues as NewCustomerUserFormData}
      enableReinitialize
      onSubmit={onFormSubmit}
      validationSchema={kNEW_CUSTOMER_USER_VALIDATION}
      validateOnMount={true}
    >
      <Form layout={"vertical"}>
        <FormItem
          hidden={!!initialValues?.customerId}
          label={"Cliente"}
          name={"customerId"}
        >
          <Select
            placeholder={"Seleccione un cliente"}
            suffixIcon={<FontAwesomeIcon icon={faUserGear} />}
            options={customerList as any}
            allowClear
            fieldNames={{
              label: "businessName",
              value: "id",
            }}
            name={"customerId"}
          />
        </FormItem>

        <FormItem name={"name"} label={"Nombre(s)"} required>
          <Input
            allowClear
            prefix={<FontAwesomeIcon icon={faICursor} />}
            name={"name"}
            placeholder={"Nombre(s)"}
          />
        </FormItem>
        <FormItem name={"lastname"} label={"Apellido(s)"} required>
          <Input
            allowClear
            prefix={<FontAwesomeIcon icon={faICursor} />}
            name={"lastname"}
            placeholder={"Apellido(s)"}
          />
        </FormItem>
        <FormItem name={"dni"} label={"DNI"} required>
          <Input
            prefix={<FontAwesomeIcon icon={faIdCard} />}
            name={"dni"}
            placeholder={"N° DNI"}
          />
        </FormItem>
        <FormItem name={"corporateEmail"} label={"Correo corporativo"} required>
          <Input
            allowClear
            prefix={<FontAwesomeIcon icon={faAt} />}
            name={"corporateEmail"}
            placeholder={"Correo Corporativo"}
          />
        </FormItem>

        <FormItem name={"email"} label={"Correo secundario"}>
          <Input
            allowClear
            prefix={<FontAwesomeIcon icon={faAt} />}
            name={"email"}
            placeholder={"Correo Secundario"}
          />
        </FormItem>

        <FormItem name={"username"} label={"Nombre de Usuario"} required>
          <Input
            autoComplete={"off"}
            allowClear
            prefix={<FontAwesomeIcon icon={faICursor} />}
            name={"username"}
            placeholder={"Nombre de Usuario"}
          />
        </FormItem>

        <FormItem name={"password"} label={"Contraseña"} required>
          <Input.Password
            allowClear
            autoComplete={"off"}
            prefix={<FontAwesomeIcon icon={faKey} />}
            name={"password"}
            placeholder={"Contraseña"}
          />
        </FormItem>

        <FormItem name={"birthDate"} required>
          <FormikAntDatePicker name={"birthDate"} />
        </FormItem>
        <FormItem name={"enabled"} label={"Estado"}>
          <Switch name={"enabled"} />
        </FormItem>

        <FormItem name={"roleId"} required>
          <Select
            placeholder={"Seleccione un rol de usuario"}
            suffixIcon={<FontAwesomeIcon icon={faUserGear} />}
            options={userRoleList}
            name={"roleId"}
            fieldNames={{
              label: "label",
              value: "id",
            }}
          />
        </FormItem>

        <FormikActivityReasonField />
        <SubmitButton icon={<FontAwesomeIcon icon={faPaperPlane} />}>
          Registrar
        </SubmitButton>
      </Form>
    </Formik>
  );
};
