import { Vehicle } from "../../../../../types/Vehicle";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGatewayDeviceViewModel } from "../../../../../viewmodel/GatewayDevice";
import {
  Card,
  Checkbox,
  Divider,
  Form,
  notification,
  Radio,
  Typography,
} from "antd";
import { useGatewayViewModel } from "../../../../../viewmodel/Gateway";
import { AppLoader } from "../../../../components/AppLoader";
import { EmptyData } from "../../../../components/Empty";
import { FullscreenToggle } from "../../../../components/Fullscreen";
import animationCharts from "../../../../../assets/animations/animation_charts.json";
import { DashboardSubpageContainer } from "../../../../components/DashboardContainer";
import { Gateway } from "../../../../../types/Gateway";
import { DashboardChartRenderer } from "./components/ChartRenderer";
import { Device } from "../../../../../types/Device";

type Props = {
  vehicle: Vehicle;
};
export const VehicleDeviceTab: FC<Props> = ({ vehicle }) => {
  const [selectedGw, setSelectedGw] = useState<Gateway | null>(null);
  const [selectedDevices, setSelectedDevices] = useState<Array<Device>>();
  const {
    fetchList,
    onFetchListStateReceived,
    gatewayDeviceList,
    fetchListState,
  } = useGatewayDeviceViewModel();
  const {
    fetchVehicleGateway,
    fetchGatewayState,
    onFetchGatewayStateReceived,
    vehicleGateway,
  } = useGatewayViewModel();
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!!selectedGw)
      void fetchList(selectedGw.id, {
        dashboard: true,
      });
  }, [selectedGw]);

  useEffect(() => {
    void fetchList(vehicle.id, {
      dashboard: true,
    });
    void fetchVehicleGateway(vehicle.id);
  }, []);

  useEffect(() => {
    if (!!fetchListState && !fetchListState.loading) {
      if (fetchListState.hasError) {
        notification.error({
          message: "Error al obtener los dispositivos.",
          description: fetchListState.error?.message,
        });
      }
      onFetchListStateReceived();
    }
  }, [fetchListState]);

  useEffect(() => {
    if (!!fetchGatewayState && !fetchGatewayState.loading) {
      if (fetchGatewayState.hasError) {
        notification.error({
          message: "Error al obtener el gateway.",
          description: fetchGatewayState.error?.message,
        });
      } else {
        if (!!vehicleGateway && vehicleGateway?.length > 0) {
          setSelectedGw(vehicleGateway[0]);
        }
      }
      onFetchGatewayStateReceived();
    }
  }, [fetchGatewayState]);

  return (
    <DashboardSubpageContainer>
      <AppLoader
        loading={
          (!!fetchListState && fetchListState.loading) ||
          (!!fetchGatewayState && fetchGatewayState.loading)
        }
      />

      {!vehicleGateway || vehicleGateway.length === 0 ? (
        <EmptyData
          description={"El vehículo no está vinculado a ningún gateway."}
        />
      ) : (
        <div
          className={
            "w-full h-fit gap-2 flex flex-col relative overflow-x-hidden overflow-y-auto pt-2"
          }
          ref={divRef}
        >
          <Typography.Text type={"secondary"}>
            Seleccionar variables para monitorear
          </Typography.Text>

          <Card>
            <Form layout={"vertical"}>
              <Form.Item label={"Gateway"}>
                <Radio.Group
                  onChange={(selection) => {
                    setSelectedGw(selection.target.value);
                    setSelectedDevices(undefined);
                  }}
                  value={selectedGw}
                >
                  {vehicleGateway?.map((it, index) => {
                    return (
                      <Radio key={`gw-${it.key}`} value={it}>
                        {it.key}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </Form.Item>
              <Form.Item label={"Variables"}>
                <Checkbox.Group
                  value={selectedDevices as any}
                  onChange={(values) => {
                    setSelectedDevices(values as any);
                  }}
                >
                  {!!selectedGw &&
                  gatewayDeviceList &&
                  gatewayDeviceList.length > 0 ? (
                    gatewayDeviceList?.map((it) => {
                      return (
                        <Checkbox key={`cb-${it.deviceId}`} value={it.Device}>
                          {it.Device.description}
                        </Checkbox>
                      );
                    })
                  ) : (
                    <Typography.Text type={"secondary"}>
                      No hay variables disponibles para este gateway.
                    </Typography.Text>
                  )}
                </Checkbox.Group>
              </Form.Item>
            </Form>
          </Card>

          <Divider />
          {selectedGw &&
          gatewayDeviceList &&
          selectedDevices &&
          selectedDevices?.length > 0 ? (
            <div className={"w-full overflow-x-hidden flex flex-col gap-2"}>
              <FullscreenToggle
                containerRef={divRef}
                className={"absolute right-2 top-0"}
              />
              {selectedDevices?.map((it, idx) => {
                return (
                  <DashboardChartRenderer
                    key={`renderer-${it.key}`}
                    gateway={selectedGw!!}
                    device={it}
                    chart={null!!}
                  />
                );
              })}
            </div>
          ) : (
            <EmptyData
              description={"Selecciona un gateway para visualizar los datos"}
              title={"En espera..."}
              animation={animationCharts}
            />
          )}
        </div>
      )}
    </DashboardSubpageContainer>
  );
};
