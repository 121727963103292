import { FC, useCallback, useEffect } from "react";
import { TabsProps } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faServer,
  faChartLine,
  faCloudDownload,
    faTimeline
} from "@fortawesome/free-solid-svg-icons";
import { Vehicle } from "../../../../types/Vehicle";
import { VehicleMainTab } from "../tabs/TabMain";
import { VehicleGatewayTab } from "../tabs/TabGateway";
import { useDashboardHeader } from "../../../hooks/Header";
import { DashboardTabWindow } from "../../../components/DashboardTabWindow";
import { VehicleLogsTab } from "../tabs/logs/TabLogs";
import {VehicleHistoryTab} from "../tabs/history/TabHistory";
import {VehicleDashboardTab} from "../tabs/dashboard/TabDashboard";

type Props = {
  vehicle: Vehicle;
  onUpdateEnded: () => void;
};

const items: TabsProps["items"] = [
  {
    key: "main",
    label: (
      <span>
        <FontAwesomeIcon icon={faHome} /> Principal
      </span>
    ),
  },
  {
    key: "gateway",
    label: (
      <span>
        <FontAwesomeIcon icon={faServer} /> Gateway
      </span>
    ),
  },
  {
    key: "logs",
    label: (
      <span>
        <FontAwesomeIcon icon={faCloudDownload} /> Reportes
      </span>
    ),
  },
  {
    key: "dashboard",
    label: (
        <span>
        <FontAwesomeIcon icon={faChartLine} /> Dashboard
      </span>
    ),
  },
  {
    key: "history",
    label: (
      <span>
        <FontAwesomeIcon icon={faTimeline} /> Histórico
      </span>
    ),
  },
];

export const VehicleDataComponent: FC<Props> = ({ vehicle, onUpdateEnded }) => {
  const { setTitle } = useDashboardHeader();

  useEffect(() => {
    setTitle("Vehículo");
  }, []);

  const onSelected = useCallback((key: string) => {
    switch (key) {
      case "gateway":
        return <VehicleGatewayTab vehicle={vehicle} />;
      case "logs":
        return <VehicleLogsTab vehicle={vehicle} />;
      case "history":
        return <VehicleHistoryTab vehicle={vehicle} />;
      case "dashboard":
        return <VehicleDashboardTab vehicle={vehicle} />;
      default:
        return (
          <VehicleMainTab onUpdateEnded={onUpdateEnded} vehicle={vehicle} />
        );
    }
  }, []);
  return (
    <DashboardTabWindow
      items={items}
      defaultSelectedKey={"main"}
      onSelected={onSelected}
    />
  );
};
