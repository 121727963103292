import { PermissionEntry, UpdateRolePermissionsData } from "../../types/Role";
import { RemotePermissionSource } from "../source/permission/Remote";

export class PermissionRepository {
  static async getRolePermissionsList() {
    return await RemotePermissionSource.getRolePermissionsList();
  }

  static async getRolePermissions(id: number) {
    return await RemotePermissionSource.getRolePermissions(id);
  }

  static async updateRolePermissions(data: UpdateRolePermissionsData) {
    return await RemotePermissionSource.updateRolePermissions(data);
  }

  static async getEntries() {
    return await RemotePermissionSource.getEntries();
  }

  static async createEntry(values: PermissionEntry) {
    return await RemotePermissionSource.createEntry({ data: values });
  }

  static async deleteEntry(id: number) {
    return await RemotePermissionSource.deleteEntry({ id });
  }
}
