import { FC, useCallback, useEffect, useState } from "react";
import { Typography, notification } from "antd";
import { NavLink } from "react-router-dom";
import {
  Gateway,
  GatewayInfo,
  UpdateGatewayFormData,
} from "../../../../types/Gateway";
import { useGatewayViewModel } from "../../../../viewmodel/Gateway";
import {
  UpdateBottomSheet,
  UpdateBottomSheetFormItemType,
} from "../../../components/UpdateBottomSheet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCar,
  faHashtag,
  faTag,
  faNetworkWired,
  faCircle,
  faTimesCircle,
  faHdd,
  faSatellite,
  faWifi,
  faTemperatureArrowUp,
  faBattery,
} from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import { DashboardSubpageContainer } from "../../../components/DashboardContainer";
import { AppLoader } from "../../../components/AppLoader";
import {
  DescriptionsItem,
  DescriptionsList,
} from "../../../components/DescriptionsList";
import { CustomNavigator } from "../../../components/CustomLink";
import { socketOff, socketOn } from "../../../../services/SocketIO";
import { fastFormatDate } from "../../../../utils/Dates";
import { Divider, Progress } from "antd/lib";
import { toNumber } from "lodash";
import { CircularProgress } from "../../../components/Progress";

type Props = {
  gateway: Gateway;
  onUpdate: () => void;
};

const kUPDATE_GATEWAY_ITEMS: UpdateBottomSheetFormItemType[] = [
  {
    name: "description",
    label: "Descripción",
    type: "textarea",
    renderProps: {
      placeholder: "Ingresar descripción",
    },
  },
];

const kUPDATE_GATEWAY_SCHEMA = yup.object({
  description: yup.string(),
  reason: yup.string().required("Detalle la descripción de esta actividad: "),
});

export const GatewayMainTab: FC<Props> = ({ gateway, onUpdate }) => {
  const {
    updateGateway,
    updateGatewayState,
    onUpdateGatewayStateReceived,
    assignVehicleGateway,
  } = useGatewayViewModel();
  const [activeEditableItem, setActiveEditableItem] = useState<
    string[] | undefined
  >();
  const onEditClicked = useCallback(
    (name: string) => {
      setActiveEditableItem([name]);
    },
    [setActiveEditableItem],
  );

  useEffect(() => {
    if (!!updateGatewayState && !updateGatewayState.loading) {
      if (updateGatewayState.hasError) {
        notification.error({
          message: updateGatewayState.error?.message,
        });
      } else {
        notification.success({
          message: "Información actualizada.",
        });
        onUpdate();
      }
      onUpdateGatewayStateReceived();
    }
  }, [updateGatewayState]);
  const updateGatewayNow = useCallback(
    async (data: UpdateGatewayFormData) => {
      return updateGateway(data);
    },
    [gateway],
  );

  const [gatewayInfo, setGatewayInfo] = useState<GatewayInfo | null>();

  const listener = useCallback((data: any) => {
    setGatewayInfo(data);
  }, []);

  useEffect(() => {
    const key = `data/${gateway.key}/gateway_info`;
    socketOn(key, listener);
    return () => {
      socketOff(key, listener);
    };
  }, []);

  return (
    <DashboardSubpageContainer>
      {gateway.Vehicle && (
        <div className={"flex my-4 flex-col gap-2 items-start"}>
          <Typography.Text type={"secondary"}>
            Este gateway está vinculado a el vehículo{" "}
            <NavLink to={`/dashboard/vehicles/${gateway.Vehicle.id}`}>
              {gateway.Vehicle.name}
            </NavLink>
          </Typography.Text>

          {/*<Button type={"link"} icon={<FontAwesomeIcon icon={faPlug}/>} onClick={() => {*/}
          {/*    void assignVehicleGateway({*/}
          {/*        gatewayId: gateway.id,*/}
          {/*        vehicleId: null,*/}
          {/*        reason: 'Desvinculacion del vehiculo'*/}
          {/*    })*/}
          {/*}*/}
          {/*}>Desvincular Gateway</Button>*/}
        </div>
      )}
      <UpdateBottomSheet<UpdateGatewayFormData>
        defaultValues={{
          gatewayId: gateway.id,
        }}
        validationSchema={kUPDATE_GATEWAY_SCHEMA}
        rowKey={"gateway"}
        title={"Actualizar Información"}
        items={kUPDATE_GATEWAY_ITEMS}
        onSubmit={updateGatewayNow}
        open={!!activeEditableItem}
        activeItems={activeEditableItem}
        onClose={() => setActiveEditableItem(undefined)}
      />
      <AppLoader loading={!!updateGatewayState && updateGatewayState.loading} />
      <DescriptionsList rowKey={"gateway"} onEditClicked={onEditClicked}>
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faTag} />}
          title={"Nombre"}
          label={gateway.key}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faHashtag} />}
          title={"ID"}
          label={gateway.id}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faTag} />}
          title={"Descripción"}
          label={gateway.description}
          editable
          itemKey={"description"}
        />
        {gateway.Vehicle && (
          <CustomNavigator to={`/dashboard/vehicles/${gateway.Vehicle.id}`}>
            <DescriptionsItem
              icon={<FontAwesomeIcon icon={faCar} />}
              title={"Vehiculo"}
              content={
                <Typography.Link>{gateway.Vehicle?.name}</Typography.Link>
              }
            />
          </CustomNavigator>
        )}
      </DescriptionsList>
      <Divider />
      {!!gatewayInfo ? (
        <Typography.Text type={"success"}>
          <FontAwesomeIcon icon={faCircle} /> Gateway en línea última
          actualización a las {fastFormatDate(gatewayInfo.date, "HH:mm")}
        </Typography.Text>
      ) : (
        <Typography.Text type={"danger"}>
          <FontAwesomeIcon icon={faTimesCircle} /> Gateway sin conexión
        </Typography.Text>
      )}
      <Divider />
      {!!gatewayInfo && (
        <DescriptionsList>
          <DescriptionsItem
            icon={<FontAwesomeIcon icon={faNetworkWired} />}
            title={"Dirección IP"}
            label={gatewayInfo.eth_ip}
          />
          <DescriptionsItem
            icon={<FontAwesomeIcon icon={faHdd} />}
            title={"Espacio total"}
            label={gatewayInfo.total_space}
          />
          <DescriptionsItem
            icon={<FontAwesomeIcon icon={faHdd} />}
            title={"Espacio libre"}
            label={gatewayInfo.free_space}
          />
          <DescriptionsItem
            icon={
              <CircularProgress
                percent={Math.round(
                  toNumber(gatewayInfo.memory_usage_percent || "0"),
                )}
              />
            }
            title={"Alm. en uso"}
            label={`${toNumber(
              gatewayInfo.memory_usage_percent || "0",
            ).toPrecision(4)}%`}
          />
          <DescriptionsItem
            icon={<FontAwesomeIcon icon={faSatellite} />}
            title={"Satélites conectados"}
            label={gatewayInfo.num_gps_sat}
          />
          <DescriptionsItem
            icon={<FontAwesomeIcon icon={faWifi} />}
            title={"Ping"}
            label={`${gatewayInfo.ping_acme} ms`}
          />
          <DescriptionsItem
            icon={<FontAwesomeIcon icon={faTemperatureArrowUp} />}
            title={"Temperatura"}
            label={`${gatewayInfo.temperature}°`}
          />
          <DescriptionsItem
            icon={<FontAwesomeIcon icon={faBattery} />}
            title={"Batería"}
            label={`${gatewayInfo.vbat}%`}
          />
        </DescriptionsList>
      )}
    </DashboardSubpageContainer>
  );
};
