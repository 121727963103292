import React, { FC } from "react";
import { ChartData1 } from "../../../types/charts/Chart";
import { ChartSchemaDefinition } from "../../../types/Chart";
import { BaseLinearChart } from "./BaseLinear";

type Props = {
  tooltipTitle: string;
  items: Array<ChartData1<number>>;
  schema: ChartSchemaDefinition;
  id: string;
};

export const LineChart: FC<Props> = (props) => {
  return <BaseLinearChart {...props} type={"line"} />;
};
