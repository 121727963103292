import {FC, Fragment, useCallback, useEffect, useState} from "react";
import { DashboardSubpageHeader } from "../../components/DashboardHeader";
import { useVehicleViewModel } from "../../../viewmodel/Vehicle";
import {useNavigate, useParams} from "react-router-dom";
import { AppLoader } from "../../components/AppLoader";
import { Button, notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faRefresh, faPeopleGroup, faTrash} from "@fortawesome/free-solid-svg-icons";
import { toNumber } from "lodash";
import { DashboardStateContainer } from "../../components/DashboardStateContainer";
import { VehicleDataComponent } from "./components/Data";
import { DashboardBreadCrumb } from "../../components/DashboardBreadcrumb";
import {DeleteBottomSheet} from "../../components/DeleteBottomSheet";

type Props = {};
export const DashboardVehiclePage: FC<Props> = () => {
  const { id } = useParams();
  const navigate = useNavigate()
  const {
    vehicle,
    fetchVehicleState,
    fetchVehicle,
    onFetchVehicleStateReceived,
      deleteVehicle,
      deleteState,
      onDeleteStateReceived
  } = useVehicleViewModel();

  const [deleteEvent, setDeleteEvent] = useState(false)
  const toggleDelete = useCallback(() => {
    setDeleteEvent(old => !old)
  }, [setDeleteEvent])

  const fetchVehicleData = useCallback(() => {
    void fetchVehicle(toNumber(id));
  }, [vehicle]);

  useEffect(() => {
    void fetchVehicleData();
  }, []);

  useEffect(() => {
    if (!!fetchVehicleState && !fetchVehicleState.loading) {
      if (fetchVehicleState.hasError) {
        notification.error({
          message: "Error al obtener el vehículo",
        });
      }
      onFetchVehicleStateReceived();
    }
  }, [fetchVehicleState]);

  useEffect(() => {
    if (!!deleteState && !deleteState.loading) {
      if (deleteState.hasError) {
        notification.error({
          message: deleteState.error?.message,
        });
      } else {
        notification.success({
          message: "Vehículo eliminado.",
        });
        navigate("/dashboard/vehicles")
      }
      onDeleteStateReceived();
    }
  }, [deleteState]);

  return (
    <Fragment>
      <AppLoader loading={(!!fetchVehicleState && fetchVehicleState?.loading) || (!!deleteState && deleteState?.loading)} />
      <DashboardStateContainer
        state={fetchVehicleState}
        className={"w-full h-fit overflow-hidden"}
      >
        <DashboardBreadCrumb
          items={[
            {
              pathname: "/dashboard/customers",
              title: "Clientes",
              icon: <FontAwesomeIcon icon={faPeopleGroup} />,
            },
            {
              pathname: `/dashboard/customers/${vehicle?.Customer.id}?tab=vehicles`,
              title: vehicle?.Customer.businessName,
            },
            {
              active: true,
              title: vehicle?.name,
            },
          ]}
        />
        <DashboardSubpageHeader
          title={"Vehículo"}
          subtitle={`${vehicle?.name} / ${
            vehicle?.Customer && "Cliente: " + vehicle.Customer.businessName
          } `}
          extra={
            <Button.Group>
              <Button
                type={"default"}
                icon={<FontAwesomeIcon icon={faRefresh} />}
                onClick={fetchVehicleData}
              >
                Actualizar
              </Button>
              <Button type={"primary"} icon={<FontAwesomeIcon icon={faTrash}/>} onClick={toggleDelete}>
                Eliminar
              </Button>
            </Button.Group>
          }
        />
          {vehicle ? <VehicleDataComponent onUpdateEnded={fetchVehicleData} vehicle={vehicle} /> : null}
        <DeleteBottomSheet rowKey={"deleteVehicle"} title={"Eliminar Vehículo"} id={toNumber(id!!)}
                           onSubmit={deleteVehicle} onClose={toggleDelete} open={deleteEvent}/>
      </DashboardStateContainer>
    </Fragment>
  );
};
