import { theme } from "antd";
import { ThemeConfig } from "antd/lib/config-provider";

export const LightTheme: ThemeConfig = {
  algorithm: theme.defaultAlgorithm,
  token: {
    colorPrimary: "#f8aa19",
    colorBorderSecondary: "rgba(28,44,84,0.15)",
    colorBorder: "rgba(28,44,84,0.15)",
    colorBgBase: "#FFFFFF",
    colorLink: "#f8aa19",
    linkDecoration: "underline",
    colorText: "#595959",
    colorTextSecondary: "#959595",
    fontFamily: "'Roboto Condensed', sans-serif",
  },
  components: {
    Layout: {
      headerBg: "#f5f8ff",
      triggerBg: "#f3f3f3",
      bodyBg: "#f5f8ff",
      colorBgContainer: "#fff",
      colorText: "rgba(0,0,0,0.6)",
    },
    Drawer: {
      colorIcon: "#212121",
      colorText: "#212121",
      colorIconHover: "#424242",
      colorSplit: "rgba(255,255,255,0.5)",
      colorBgElevated: "#fff",
    },
    Table: {
      colorBorder: "rgba(28,44,84,0.15)",
      colorBorderBg: "rgba(28,44,84,0.15)",
      colorBorderSecondary: "rgba(28,44,84,0.15)",
      colorBgTextHover: "#ffc107",
      colorText: "#424242",
      headerBg: "#1c2c54",
      headerColor: "#fafafa",
      rowHoverBg: "rgba(28,44,84,0.1)",
      headerFilterHoverBg: "#424242",
      colorIcon: "#fafafa",
      colorPrimaryTextHover: "#424242",
    },
    Input: {
      colorText: "#505050",
    },
    Alert: {
      colorInfoBg: "#ccf7ff",
      colorErrorBg: "#ffe0e0",
      colorErrorTextActive: "#640000",
      colorError: "#640000",
      colorErrorBorder: "#640000",
      colorWarningBg: "#f5eac5",
      colorInfoText: "#212121",
      colorTextBase: "#212121",
    },
    Card: {
      colorBgContainer: "#FFFFFF",
      colorBgElevated: "#FFFFFF",
    },
    Progress: {
      colorText: "#FAFAFA",
      colorTextBase: "#FAFAFA",
      circleTextColor: "#FAFAFA",
      colorInfoText: "#FAFAFA",
      colorPrimaryText: "#FAFAFA",
      colorTextLabel: "#FAFAFA",
      colorTextSecondary: "#FAFAFA",
    },
  },
};

export const PrimaryMenuTheme: ThemeConfig = {
  token: {
    colorBorderSecondary: "rgba(0, 0, 0, 0.0)",
    colorBorder: "rgba(0, 0, 0, 0.0)",
    fontFamily: "'Roboto Condensed', sans-serif",
  },
  components: {
    Menu: {
      colorBgContainer: "transparent",
      colorBgBase: "transparent",
      colorText: "rgba(0,0,0,0.4)",
    },
  },
};

export const SecondaryMenuTheme: ThemeConfig = {
  components: {
    Menu: {
      colorText: "rgba(0,0,0,0.5)",
      itemSelectedBg: "#1c2c54",
      itemSelectedColor: "#f8aa19",
      colorBgElevated: "#424242",
      colorBgBase: "transparent",
      colorBgContainer: "transparent",
    },
  },
};

export const DarkTheme: ThemeConfig = {
  algorithm: theme.darkAlgorithm,
  token: {
    colorPrimary: "#f8aa19",
    colorBorderSecondary: "#EFEFEF",
    colorBorder: "#727272",
    colorBgBase: "#424242",
    colorLink: "rgba(255,255,255,0.6)",
    fontFamily: "'Roboto Condensed', sans-serif",
  },
  components: {
    Layout: {
      headerBg: "#212121",
      triggerBg: "#f3f3f3",
      bodyBg: "#212121",
      colorBgContainer: "#424242",
      colorText: "rgba(255,255,255,0.6)",
    },
    Menu: {
      colorText: "#EFEFEF",
      itemHoverColor: "#FFF",
      itemSelectedBg: "#212121",
      itemSelectedColor: "#f8aa19",
      colorBgElevated: "#424242",
      colorBgBase: "transparent",
      colorTextBase: "#FFF",
      itemColor: "#BFBFBF",
    },
    Drawer: {
      colorIcon: "#BDBDBD",
      colorText: "#BDBDBD",
      colorIconHover: "#FFFFFF",
      colorSplit: "rgba(255,255,255,0.5)",
      colorBgElevated: "#424242",
    },
    Table: {
      colorBorder: "rgba(0,0,0,0)",
      colorBorderBg: "rgba(0,0,0,0)",
      colorBorderSecondary: "#727272",
      colorFillAlter: "#464646",
      colorBgSpotlight: "#424242",
      colorBgBase: "#424242",
      colorBgContainer: "#424242",
      colorText: "rgba(255,255,255,0.6)",
      colorTextBase: "rgba(255,255,255,0.6)",
    },
    Input: {
      colorText: "rgba(255,255,255,0.6)",
    },
    Alert: {
      colorInfoBg: "#282a33",
      colorText: "#EFEFEF",
      colorErrorBg: "#1f1111",
      colorWarningBg: "#262217",
      colorInfoText: "#212121",
      colorTextBase: "#212121",
    },
    Card: {
      colorBgContainer: "#424242",
      colorBgElevated: "#424242",
    },
    Breadcrumb: {
      colorText: "#FFF",
    },
    Button: {
      colorText: "#FFF",
      colorTextBase: "#FFF",
      colorPrimaryText: "#FFF",
    },
    FloatButton: {
      colorText: "#FFF",
    },
    Progress: {
      colorText: "#FAFAFA",
      colorTextBase: "#FAFAFA",
      circleTextColor: "#FAFAFA",
      colorInfoText: "#FAFAFA",
      colorPrimaryText: "#FAFAFA",
      colorTextLabel: "#FAFAFA",
      colorTextSecondary: "#FAFAFA",
    },
  },
};
export const LightThemeSmall: ThemeConfig = {
  ...LightTheme,
  algorithm: theme.compactAlgorithm,
};

export const DarkThemeSmall: ThemeConfig = {
  ...DarkTheme,
  algorithm: [theme.compactAlgorithm, theme.darkAlgorithm],
};
