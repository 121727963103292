import { DashboardSubpageContainer } from "../../components/DashboardContainer";
import { useDashboardViewModel } from "../../../viewmodel/Dashboard";
import { useEffect, useState } from "react";
import { notification } from "antd";
import { AppLoader } from "../../components/AppLoader";
import { EmptyData } from "../../components/Empty";
import { DashboardOverviewHeader } from "./components/Header";
import { DashboardOverviewDataGrid } from "./components/Grid";
import chartAnimation2 from "../../../assets/animations/animation_charts.json";
import { useDashboardHeader } from "../../hooks/Header";

export const DashboardOverviewPage = () => {
  const [syncDate, setSyncDate] = useState<Date | undefined>();
  const { setTitle } = useDashboardHeader();
  const {
    fetchOverview,
    fetchOverviewState,
    onFetchOverviewStateReceived,
    overviewData,
  } = useDashboardViewModel();

  useEffect(() => {
    void fetchOverview();
    setTitle("Dashboard");
  }, []);

  useEffect(() => {
    if (!!fetchOverviewState && !fetchOverviewState.loading) {
      if (fetchOverviewState.hasError) {
        notification.error({
          message: "Error",
          description:
            "Ocurrió un error al obtener los datos de la vista general",
        });
        setSyncDate(undefined);
      } else {
        setSyncDate(new Date());
      }
      onFetchOverviewStateReceived();
    }
  }, [fetchOverviewState]);

  return (
    <DashboardSubpageContainer
      className={"w-full h-fit overflow-x-hidden overflow-y-visible"}
    >
      <AppLoader loading={!!fetchOverviewState && fetchOverviewState.loading} />
      <DashboardOverviewHeader
        syncDate={syncDate}
        onRefreshClicked={fetchOverview}
      />
      {overviewData ? (
        <DashboardOverviewDataGrid data={overviewData} />
      ) : (
        <EmptyData
          animation={chartAnimation2}
          title={"..."}
          description={"No hay datos para mostrar"}
        />
      )}
    </DashboardSubpageContainer>
  );
};
