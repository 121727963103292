import { FC } from "react";
import { Divider, Typography } from "antd";
import logo from "../../assets/AICON.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMapMarker,
} from "@fortawesome/free-solid-svg-icons";
type Props = {};
export const DashboardFooter: FC<Props> = ({}) => {
  return (
    <div
      className={
        "w-full h-fit px-12 pt-6 pb-4 flex flex-col justify-center items-center"
      }
    >
      <Divider style={{ paddingBottom: 0, marginBottom: 0 }} />
      <div
        className={
          "flex flex-row gap-12 justify-center w-full flex-wraṕ overflow-x-hidden py-12"
        }
      >
        <img
          className={"w-18 md:w-24 object-contain"}
          src={logo}
          alt={"Logo"}
        />
        <div className={"flex flex-col gap-3"}>
          <span className={"text-primary font-normal text-2xl uppercase"}>
            Contacto
          </span>
          <div className={"flex flex-col gap-1"}>
            <Typography.Text
              type={"secondary"}
              className={"uppercase text-lg font-thin"}
            >
              <FontAwesomeIcon icon={faPhone} /> +511 282 3540
            </Typography.Text>
            <Typography.Text
              type={"secondary"}
              className={"uppercase text-lg font-thin"}
            >
              <FontAwesomeIcon icon={faEnvelope} /> acmecia@acmecia.com
            </Typography.Text>
            <Typography.Text
              type={"secondary"}
              className={"uppercase text-lg font-thin"}
            >
              <FontAwesomeIcon icon={faMapMarker} /> Av. Andrés Tinoco 133 Urb.
              Prolongación Benavides, Santiago de Surco. Lima – Perú.
            </Typography.Text>
          </div>
        </div>
      </div>
      <div className={"w-fit"}>
        <Divider
          style={{
            padding: 0,
            margin: 0,
            marginBottom: 12,
          }}
        />
        <Typography.Text
          style={{ marginLeft: 20, marginRight: 20 }}
          type={"secondary"}
          className={"uppercase text-lg font-thin"}
        >
          ACME & CIA 2023 Todos los derechos reservados
        </Typography.Text>
      </div>
    </div>
  );
};
