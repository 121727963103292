import {FC, Key, MouseEventHandler, PropsWithChildren, ReactNode, useCallback} from "react";
import { Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import {EditOutlined} from "@ant-design/icons"
type Item = {
  icon: ReactNode;
  content?: ReactNode;
  title?: string;
  label?: string | number;
  editable?: boolean;
  key?: Key;
};

type Props = {
  items?: Array<Item>;
  rowKey?: string;
  onEditClicked?: (key: string) => void;
};

export const DescriptionsList: FC<PropsWithChildren<Props>> = ({
  items,
  children,
  rowKey,
  onEditClicked,
}) => {
  const onItemClicked: MouseEventHandler<HTMLDivElement> = useCallback((e)=>{
    e.stopPropagation()
    const target = e.target as any
    onEditClicked && !!target.dataset["key"] && onEditClicked(target.dataset["key"]!!)
  }, [onEditClicked])
  return (
    <div className={"select-none grid grid-cols-1 lg:grid-cols-2 gap-3 lg:gap-y-8"} onClick={onItemClicked} id={"descriptions-container"}>
      {items?.map((item, index) => (
        <DescriptionsItem {...item} key={`${index}-${rowKey}`}  />
      ))}
      {children}
    </div>
  );
};

type ItemProps = Item & {
  itemKey?: string
};

const onItemClick : MouseEventHandler<HTMLDivElement> = (e) => {
  const parent = e.currentTarget.parentElement?.parentElement
  if(!!parent && parent.id === 'descriptions-container' && !!parent.onclick) {
    parent.onclick(e as any)
  }
}
export const DescriptionsItem: FC<ItemProps> = ({
  content,
  icon,
  label,
  title,
  editable,
  itemKey,
}) => {
  return (
    <div
      className={
        "flex flex-row gap-3 items-center flex-shrink min-w-[50%] md:min-w-[40%] lg:min-w-[30%]"
      }
    >
      <div
        className={
          "flex p-2 w-12 h-12 justify-center items-center border border-solid border-black dark:border-neutral-200 border-opacity-20 rounded aspect-square bg-black dark:bg-neutral-700 bg-opacity-5 text-black dark:text-white  text-opacity-50"
        }
      >
        {icon}
      </div>

      <div className={"flex flex-col gap-0"}>
        <Typography.Text
          style={{ padding: 0, margin: 0 }}
          type={"secondary"}
          className={"p-0 m-0 leading-4"}
        >
          {title}
        </Typography.Text>
        {label && (
          <Typography.Text
            style={{ padding: 0, margin: 0 }}
            className={"p-0 m-0 leading-4"}
          >
            {label}
          </Typography.Text>
        )}
        {content}
      </div>
      {editable && (
        <div
          className={
            "self-start text-primary cursor-pointer  hover:bg-primary hover:text-white rounded w-6 h-6 z-50 relative"
          }
          data-key={itemKey}
          onClick={onItemClick}
        >
          <EditOutlined className={"flex justify-center absolute pointer-events-none w-full h-full"}/>
        </div>
      )}
    </div>
  );
};
