import { FC, useEffect } from "react";
import { usePermissionsViewModel } from "../../../../viewmodel/Permissions";
import { toast } from "react-toastify";
import { DashboardSubpageContainer } from "../../../components/DashboardContainer";
import { DashboardSubpageHeader } from "../../../components/DashboardHeader";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh, faAdd } from "@fortawesome/free-solid-svg-icons";
import { RolePermissionList } from "./components/List";
import { AddRolePermissionModal } from "./components/Modal";
import { AppLoader } from "../../../components/AppLoader";
import { DashboardBreadCrumb } from "../../../components/DashboardBreadcrumb";
import { useDashboardHeader } from "../../../hooks/Header";

export const DashboardRolePermissionPage: FC = () => {
  const {
    fetchState,
    fetchRolePermissions,
    rolePermission,
    onFetchStateReceived,
    createEvent,
    requestCreate,
    onCreateEventCompleted,
    entries,
    fetchEntries,
    fetchEntryListState,
    onFetchEntriesStateReceived,
    onUpdateStateReceived,
    updateState,
    updatePermissions,
  } = usePermissionsViewModel();
  const { setTitle } = useDashboardHeader();

  useEffect(() => {
    void fetchEntries();
    void fetchRolePermissions();
    setTitle("Permisos");
  }, []);

  useEffect(() => {
    if (!!fetchState && !fetchState?.loading) {
      if (fetchState?.hasError) {
        toast.error(fetchState.error!!.message);
        console.log(fetchState.error);
      } else {
        console.log(rolePermission);
      }
      onFetchStateReceived();
    }
  }, [fetchState]);

  useEffect(() => {
    if (!!fetchEntryListState && !fetchEntryListState?.loading) {
      if (fetchEntryListState?.hasError) {
        toast.error(fetchEntryListState.error!!.message);
      } else {
        console.log(entries);
      }
      onFetchEntriesStateReceived();
    }
  }, [fetchEntryListState]);

  useEffect(() => {
    if (!!updateState && !updateState?.loading) {
      if (updateState?.hasError) {
        toast.error(updateState.error!!.message);
        console.log(updateState.error);
      } else {
        void fetchRolePermissions();
        onCreateEventCompleted();
      }
      onUpdateStateReceived();
    }
  }, [updateState]);

  return (
    <DashboardSubpageContainer className={"w-full h-fit overflow-hidden"}>
      <AppLoader
        loading={
          (!!fetchState && fetchState.loading) ||
          (!!updateState && updateState.loading) ||
          (!!fetchEntryListState && fetchEntryListState.loading)
        }
      />
      <DashboardBreadCrumb
        items={[
          {
            pathname: "/dashboard/users_roles/permissions",
            title: "Permisos",
          },
          {
            active: true,
            title: "...",
          },
        ]}
      />
      <DashboardSubpageHeader
        title={`Permisos para ${rolePermission?.label}`}
        extra={
          <Button.Group>
            <Button
              type={"default"}
              icon={<FontAwesomeIcon icon={faRefresh} />}
              onClick={fetchRolePermissions}
            >
              Actualizar
            </Button>
            <Button
              type={"primary"}
              icon={<FontAwesomeIcon icon={faAdd} />}
              onClick={requestCreate}
            >
              Nuevo
            </Button>
          </Button.Group>
        }
      />
      {createEvent ? (
        <AddRolePermissionModal
          onFinish={updatePermissions}
          tables={entries || []}
          role={rolePermission!!}
          onClose={onCreateEventCompleted}
        />
      ) : null}
      <RolePermissionList
        entries={entries}
        onUpdate={updatePermissions}
        onAddClick={requestCreate}
        data={rolePermission?.permissions}
      />
    </DashboardSubpageContainer>
  );
};
