import { FC, ReactNode } from "react";
import { Typography } from "antd";
import { useBreakpoints } from "../hooks/Breakpoint";
import { isSm, isXs, screenIsAnyOf } from "../../utils/tailwind.screens";

type Props = {
  title?: string;
  subtitle?: string;
  extra?: ReactNode;
};
export const DashboardSubpageHeader: FC<Props> = ({
  title,
  extra,
  subtitle,
}) => {
  const { breakpoint } = useBreakpoints();
  const isMobile = screenIsAnyOf(breakpoint, isXs, isSm);
  return (
    <div
      className={`w-full p-2 md:p-4 lg:my-2 flex flex-col sm:flex-row flex-wrap items-end relative`}
    >
      <div className={"flex flex-col"}>
        {title && (
          <Typography.Title
            style={{
              padding: 0,
              margin: 0,
            }}
            level={isMobile ? 5 : 4}
          >
            {title}
          </Typography.Title>
        )}
        {subtitle ? (
          <Typography.Text
            style={{
              padding: 0,
              margin: 0,
            }}
            type={"secondary"}
          >
            {subtitle}
          </Typography.Text>
        ) : null}
      </div>
      <div
        className={"h-fit w-fit flex place-items-end place-content-end ml-auto"}
      >
        {extra}
      </div>
    </div>
  );
};
