import { FC, useEffect, useState } from "react";
import { DashboardSubpageContainer } from "../../components/DashboardContainer";
import { DashboardSubpageHeader } from "../../components/DashboardHeader";
import { CustomerUserCreateForm } from "./components/Form";
import { useRoleViewModel } from "../../../viewmodel/Role";
import { toast } from "react-toastify";
import { AppLoader } from "../../components/AppLoader";
import { useCustomerViewModel } from "../../../viewmodel/Customer";
import { useCustomerUserViewModel } from "../../../viewmodel/CustomerUser";
import { useSearchParams } from "react-router-dom";
import { Customer } from "../../../types/Customer";
import { testAsNumber } from "../../../utils/Number";
import { notification } from "antd";
import { DashboardBreadCrumb } from "../../components/DashboardBreadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";

type Props = {};
export const DashboardCustomerUserCreatePage: FC<Props> = ({}) => {
  const [searchParams] = useSearchParams();
  const [desiredCustomer, setDesiredCustomer] = useState<Customer | null>(null);

  const {
    fetchList: fetchRoleList,
    fetchListState: fetchRoleListState,
    onFetchListStateReceived: onFetchRoleListStateReceived,
    roleList,
  } = useRoleViewModel();

  const {
    fetchList: fetchCustomerList,
    fetchListState: fetchCustomerListState,
    onFetchListStateReceived: onFetchCustomerListStateReceived,
    customerList,
  } = useCustomerViewModel();

  const { createUser, createState, onCreateStateReceived } =
    useCustomerUserViewModel();
  useEffect(() => {
    if (!!fetchRoleListState && !fetchRoleListState.loading) {
      if (fetchRoleListState.hasError) {
        toast.error(fetchRoleListState.error?.message);
      }
      onFetchRoleListStateReceived();
    }
  }, [fetchRoleListState]);

  useEffect(() => {
    if (!!fetchCustomerListState && !fetchCustomerListState.loading) {
      onFetchCustomerListStateReceived();
      if (fetchCustomerListState.hasError) {
        toast.error(fetchCustomerListState.error?.message);
      } else {
        testAsNumber(searchParams.get("customerId"))
          .then((id) => {
            const desiredC = customerList!!.filter((it) => it.id === id);
            if (!!desiredC && desiredC.length >= 1) {
              setDesiredCustomer(desiredC[0]);
            } else {
              notification.error({ message: "El id de cliente no es válido" });
            }
          })
          .catch(() => {
            notification.error({ message: "El id de cliente no es válido." });
          });
      }
    }
  }, [fetchCustomerListState]);

  useEffect(() => {
    if (!!createState && !createState.loading) {
      if (createState.hasError) {
        toast.error(createState.error?.message);
      } else {
        toast.success("Usuario registrado correctamente.");
      }
      onCreateStateReceived();
    }
  }, [createState]);

  useEffect(() => {
    void fetchRoleList();
    void fetchCustomerList();
  }, []);

  return (
    <DashboardSubpageContainer className={"w-full h-fit overflow-x-hidden"}>
      <AppLoader
        loading={
          (!!fetchRoleListState && fetchRoleListState.loading) ||
          (!!createState && createState.loading)
        }
      />
      {desiredCustomer ? (
        <DashboardBreadCrumb
          items={[
            {
              pathname: "/dashboard/customers",
              title: "Clientes",
              icon: <FontAwesomeIcon icon={faPeopleGroup} />,
            },
            {
              pathname: `/dashboard/customers/${desiredCustomer?.id}`,
              title: desiredCustomer.businessName,
            },
            {
              pathname: `/dashboard/customers/${desiredCustomer?.id}?tab=users`,
              title: "Usuarios",
            },
            {
              active: true,
              title: "Nuevo",
            },
          ]}
        />
      ) : (
        <DashboardBreadCrumb
          items={[
            {
              pathname: "/dashboard/customers",
              title: "Usuarios de Clientes",
              icon: <FontAwesomeIcon icon={faPeopleGroup} />,
            },
            {
              active: true,
              title: "Nuevo",
            },
          ]}
        />
      )}
      <DashboardSubpageHeader
        title={"Nuevo Usuario de Cliente"}
        subtitle={
          desiredCustomer ? `Para ${desiredCustomer.businessName}` : undefined
        }
      />

      <CustomerUserCreateForm
        customerList={customerList}
        userRoleList={roleList}
        onFinish={createUser}
        initialValues={{
          customerId: desiredCustomer?.id,
        }}
      />
    </DashboardSubpageContainer>
  );
};
