import React, { FC, Fragment, useCallback, useEffect, useState } from "react";
import { Field, FieldProps } from "formik";
import { Input, AutoComplete } from "formik-antd";
import { Checkbox as AntdCheckbox, Typography } from "antd";
import { CustomErrorMessage } from "./FormikCustomErrorMessage";

export const FormikActivityReasonField: FC<any> = () => {
  return (
    <Field name={"reason"}>{(props: any) => <CustomField {...props} />}</Field>
  );
};
const CustomField: FC<any> = ({
  field: { value, onChange, name },
}: FieldProps<string>) => {
  const fetchSavedValue = useCallback(() => {
    const global_reason = sessionStorage.getItem("global_reason");
    onChange({
      target: {
        name,
        value: global_reason,
      },
    });
  }, [onChange]);

  useEffect(() => {
    fetchSavedValue();
  }, []);

  useEffect(() => {
    const old = sessionStorage.getItem("global_reason");
    if (!!value) {
      if (!!old) {
        if (
          value.length === old.length + 1 ||
          value.length === old.length - 1 ||
          value.length === old.length
        ) {
          sessionStorage.setItem("global_reason", value ? value : "");
        } else {
          fetchSavedValue();
        }
      } else {
        sessionStorage.setItem("global_reason", value);
      }
    } else {
      if (!old || old.length === 1) {
        sessionStorage.setItem("global_reason", "");
      } else {
        fetchSavedValue();
      }
    }
  }, [value]);

  return (
    <Fragment>
      <Input.TextArea
        name={"reason"}
        placeholder={"Descripción de esta actividad para seguimiento"}
      />
      <CustomErrorMessage name={"reason"} />
      <Typography.Text type={"secondary"} className={"block"}>
        Utilizaremos esta información para realizar un seguimiento constante del
        motivo de los cambios realizados.
      </Typography.Text>
    </Fragment>
  );
};
