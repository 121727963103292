import { FC } from "react";
import { useBreakpoints } from "../../../../../hooks/Breakpoint";
import {
  isSm,
  isXs,
  screenIsAnyOf,
} from "../../../../../../utils/tailwind.screens";
import { UserListTable } from "./Table";
import { UserListGrid } from "./Grid";
import { EmptyData } from "../../../../../components/Empty";
import { CustomerUser } from "../../../../../../types/CustomerUser";

export type UserListDataProps = {
  data?: CustomerUser[];
  onItemClicked: (item: CustomerUser) => void;
};
export const UserListData: FC<UserListDataProps> = (props) => {
  const { breakpoint } = useBreakpoints();
  const isMobile = screenIsAnyOf(breakpoint, isXs, isSm);

  return props.data && props.data.length > 0 ? (
    isMobile ? (
      <UserListGrid {...props} />
    ) : (
      <UserListTable {...props} />
    )
  ) : (
    <EmptyData description={"No hay usuarios registrados."} />
  );
};
