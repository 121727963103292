import React, { Key, ReactNode, useCallback, useMemo } from "react";
import {
  Drawer,
  DatePickerProps,
  InputNumberProps,
  InputProps,
  Button,
  Typography,
  SwitchProps,
  DrawerProps,
} from "antd";
import { Formik, FormikHelpers, FormikProps, FormikValues } from "formik";
import {
  Input,
  InputNumber,
  Form,
  SubmitButton,
  ResetButton,
  Switch,
} from "formik-antd";
import { FormikAntDatePicker } from "./FormikAntDatePicker";
import { Schema } from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { TextAreaProps } from "antd/es/input";
import { CustomErrorMessage } from "./FormikCustomErrorMessage";
import { FormikActivityReasonField } from "./FormikActivityReasonField";
import { FormikChartSchemaEditor } from "../dashboard/chart_update/components/FormikChartSchemaEditor";

type BaseItem<T> = {
  type: "string" | "number" | "date" | "switch" | "textarea" | "graphic";
  name: string;
  label: string;
  renderProps?: T;
};

export type UpdateBottomSheetFormItemType =
  | (BaseItem<InputProps> & {
      type: "string";
    })
  | (BaseItem<InputNumberProps> & {
      type: "number";
    })
  | (BaseItem<DatePickerProps> & {
      type: "date";
    })
  | (BaseItem<SwitchProps> & {
      type: "switch";
    })
  | (BaseItem<TextAreaProps> & {
      type: "textarea";
    })
  | (BaseItem<any> & {
      type: "graphic";
    });

type Props<T> = {
  defaultValues: Partial<T>;
  rowKey: Key;
  title: string;
  validationSchema?: Schema;
  items: Array<UpdateBottomSheetFormItemType>;
  formikProps?: FormikProps<any>;
  onSubmit: (value: T) => Promise<boolean | undefined>;
  open: boolean;
  onClose: () => void;
  activeItems?: Array<string>;
  placement?: DrawerProps["placement"];
  size?: DrawerProps["size"];
};

const getFormItem: (
  item: UpdateBottomSheetFormItemType,
  key: Key
) => ReactNode = ({ type, name, label, renderProps }, key) => {
  let El: any;
  switch (type) {
    case "string":
      El = Input;
      break;
    case "number":
      El = InputNumber;
      break;
    case "date":
      El = FormikAntDatePicker;
      break;
    case "switch":
      El = Switch;
      break;
    case "textarea":
      El = Input.TextArea;
      break;
    case "graphic":
      El = FormikChartSchemaEditor;
      break;
    default:
      El = Input;
      break;
  }
  return (
    <div
      key={key}
      className={
        "w-full flex flex-col items-start justify-start justify-items-start gap-1 py-4"
      }
    >
      {label && <Typography.Text>{label}</Typography.Text>}
      <El name={name} {...renderProps} />
      {El !== FormikChartSchemaEditor && <CustomErrorMessage name={name} />}
    </div>
  );
};
export function UpdateBottomSheet<T>({
  title,
  rowKey,
  validationSchema,
  items,
  activeItems,
  formikProps,
  onSubmit,
  open,
  onClose,
  defaultValues,
  placement = "right",
  size = "default",
}: Props<T>) {
  const onSubmitForm = useCallback(
    async (values: FormikValues, helpers: FormikHelpers<FormikValues>) => {
      const it = await onSubmit(values as T);
      helpers.setSubmitting(false);
      if (it) onClose();
    },
    []
  );

  const realItems = useMemo(() => {
    if (!!activeItems) {
      return items.filter((it) => activeItems.includes(it.name));
    } else {
      return items;
    }
  }, [items, activeItems]);

  const realDefaultValues = useMemo(() => {
    const global_reason = sessionStorage.getItem("global_reason");
    return { ...defaultValues, reason: global_reason };
  }, [defaultValues]);

  return (
    <Drawer
      size={size}
      destroyOnClose
      title={title}
      placement={placement}
      open={open}
      onClose={onClose}
      bodyStyle={{
        display: "flex",
      }}
    >
      <Formik
        {...formikProps}
        validateOnChange={true}
        validateOnBlur
        enableReinitialize
        initialValues={realDefaultValues}
        onSubmit={onSubmitForm}
        validationSchema={validationSchema}
      >
        <Form layout={"vertical"} className={"mx-auto"}>
          {realItems.map((it, i) => {
            return getFormItem(it, `${i}-item-${rowKey}`);
          })}
          <FormikActivityReasonField />
          <Button.Group className={"my-4"}>
            <SubmitButton icon={<FontAwesomeIcon icon={faPaperPlane} />}>
              Guardar
            </SubmitButton>
            <ResetButton icon={<FontAwesomeIcon icon={faRefresh} />}>
              Reiniciar
            </ResetButton>
          </Button.Group>
        </Form>
      </Formik>
    </Drawer>
  );
}
