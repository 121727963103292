export type kALLOWED_CHART_KEYS = "line" | "bar" | "hplot" | "area" | "gauge";
export const kALLOWED_CHART_TYPES: Array<{
  key: kALLOWED_CHART_KEYS;
  title: string;
}> = [
  {
    key: "line",
    title: "Linear",
  },
  {
    key: "bar",
    title: "Barras",
  },
  {
    key: "hplot",
    title: "Gráfico de dispersión",
  },
  {
    key: "area",
    title: "Gráfico lineal con áreas",
  },
  {
    key: "gauge",
    title: "Gauge"
  }
];

export interface ChartData {
  title: string;
  name: string;
}

export interface ChartData1<T = string> extends ChartData {
  value: T
  date: Date | string;
}

export interface ChartData2 extends ChartData {
  start: Date;
  end: Date;
  repeats: number;
}
