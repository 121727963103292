import { FC, useCallback, useMemo } from "react";
import { ConfigProvider, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { appRouteAsMenuItem } from "../../../utils/Layout";
import { zAPP_ROUTES } from "../../../utils/Routes";
import { PrimaryMenuTheme } from "../../../utils/Theme";

export const DashboardSiderMenu: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const generatePathSegments = useCallback((path: string) => {
    const segments = path.split("/");
    const pathSegments = [];
    let currentSegment = "";
    for (let i = 0; i < segments.length; i++) {
      const segment = segments[i];
      if (segment) {
        currentSegment += "/" + segment;
        pathSegments.push(currentSegment);
      }
    }
    return pathSegments;
  }, []);
  const pathSegments = useMemo(() => {
    return generatePathSegments(pathname);
  }, [pathname]);
  return (
    <ConfigProvider theme={PrimaryMenuTheme}>
      <Menu
        theme={"light"}
        onClick={(info) => {
          if (info.key !== pathname) navigate(info.key);
        }}
        selectedKeys={pathSegments}
        mode="inline"
        items={zAPP_ROUTES
          .filter((it) => it.path === "/dashboard")[0]
          .children!!.filter((it) => !it.info?.ignore)
          .map((it) => appRouteAsMenuItem(it)!!)}
      />
    </ConfigProvider>
  );
};
