import { useState } from "react";
import { AppState, TaskState } from "../data/domain/State";
import { GatewayLocation } from "../types/DeviceData";
import { LocationRepository } from "../data/repository/Location";

export function useLocationViewModel() {
  const [fetchAddressState, setFetchAddressState] =
    useState<AppState<boolean> | null>(null);
  const [address, setAddress] = useState<any | null>(null);
  async function fetchAddress(location: GatewayLocation) {
    if (fetchAddressState?.loading) return;
    setFetchAddressState(TaskState.loading());
    try {
      const res = await LocationRepository.getAddress(location);
      setAddress(res);
      setFetchAddressState(TaskState.success(true));
    } catch (e: any) {
      setFetchAddressState(TaskState.error(e));
    }
  }
  function onFetchAddressStateReceived() {
    setFetchAddressState(null);
  }

  return {
    onFetchAddressStateReceived,
    fetchAddressState,
    fetchAddress,
    address,
  };
}
