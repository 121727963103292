import { Gateway } from "../../../../../../types/Gateway";
import { FC, useCallback, useEffect, useState } from "react";
import { ChartData } from "../../../../../../types/charts/Chart";
import { socketOff, socketOn } from "../../../../../../services/SocketIO";
import { Card, Typography } from "antd";
import { Chart } from "../../../../../../types/Chart";
import { Device } from "../../../../../../types/Device";
import { ChartDataRender } from "../../../../../components/charts/Render";

type PProps = {
  gateway: Gateway;
  chart: Chart;
  device: Device;
};
export const DashboardChartRenderer: FC<PProps> = ({
  chart,
  gateway,
  device,
}) => {
  const [data, setData] = useState<Array<ChartData>>([]);
  const [date, setDate] = useState<string | undefined>();
  const listener = useCallback(
    (nData: any) => {
      console.log(nData);
      if (!nData) return;
      if (nData.length > 0) {
        setData((old) => [...old, ...(nData as any)]);
        if (!!nData[0].date) {
          setDate(nData[0].date);
        } else {
          if (nData[0].length) {
            setDate(nData[0][0].date);
          }
        }
      }
    },
    [setData, setDate]
  );
  useEffect(() => {
    const key = `data/${gateway.key}/${device.key}`;
    socketOn(key, listener);
    return () => {
      socketOff(key, listener);
    };
  });

  return (
    <Card className={"w-full"}>
      <div className={"flex flex-col items-center"}>
        {date && (
          <Typography.Text
            type={"secondary"}
            keyboard
            style={{ padding: 0, margin: 0 }}
          >
            Actualizado el {date}
          </Typography.Text>
        )}
        <Typography.Paragraph style={{ padding: 0, margin: 0 }}>
          <Typography.Text type={"secondary"}>{device.key}</Typography.Text>{" "}
          {device.description && (
            <Typography.Text strong type={"secondary"}>
              {" "}
              / {device.description}
            </Typography.Text>
          )}
        </Typography.Paragraph>

        <ChartDataRender
          id={device.key}
          data={data}
          title={device.description}
          schema={chart.schema}
        />
      </div>
    </Card>
  );
};
