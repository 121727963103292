import {FC, Fragment, useCallback, useEffect, useState} from "react";
import {DashboardSubpageHeader} from "../../components/DashboardHeader";
import {useNavigate, useParams} from "react-router-dom";
import {Button, notification} from "antd";
import {AppLoader} from "../../components/AppLoader";
import {DashboardStateContainer} from "../../components/DashboardStateContainer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faRefresh, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useCustomerViewModel} from "../../../viewmodel/Customer";
import {CustomerPageTabs} from "./tabs";
import {DashboardBreadCrumb} from "../../components/DashboardBreadcrumb";
import {useDashboardHeader} from "../../hooks/Header";
import {DeleteBottomSheet} from "../../components/DeleteBottomSheet";
import {toNumber} from "lodash";

export const DashboardCustomerPage: FC = () => {
    const {id} = useParams();
    const {
        customer,
        fetchCustomerState,
        fetchCustomer,
        onFetchCustomerStateReceived,
        deleteCustomer,
        deleteState,
        onDeleteStateReceived
    } = useCustomerViewModel();
    const navigate = useNavigate();

    const fetchCustomerData = () => {
        void fetchCustomer(Number(id));
    };
    const {setTitle} = useDashboardHeader();

    const [deleteEvent, setDeleteEvent] = useState(false)
    const toggleDelete = useCallback(() => {
        setDeleteEvent(old => !old)
    }, [setDeleteEvent])

    useEffect(() => {
        fetchCustomerData();
        setTitle("Cliente")
    }, []);

    useEffect(() => {
        if (!!fetchCustomerState && !fetchCustomerState.loading) {
            if (fetchCustomerState.hasError) {
                notification.error({
                    message: "Error al obtener el cliente.",
                });
            }
            onFetchCustomerStateReceived();
        }
    }, [fetchCustomerState]);

    useEffect(() => {
        if (!!deleteState && !deleteState.loading) {
            if (deleteState.hasError) {
                notification.error({
                    message: deleteState.error?.message,
                });
            } else {
                notification.success({
                    message: "Cliente eliminado.",
                });
                navigate("/dashboard/customers")
            }
            onDeleteStateReceived();
        }
    }, [deleteState]);

    return (
        <Fragment>
            <AppLoader
                loading={(!!fetchCustomerState && fetchCustomerState?.loading) || (!!deleteState && deleteState?.loading)}/>
            <DashboardStateContainer
                state={fetchCustomerState}
                className={"w-full h-fit overflow-hidden"}
            >
                <DashboardBreadCrumb
                    items={[
                        {
                            pathname: "/dashboard/customers",
                            title: "Clientes",
                        },
                        {
                            title: customer?.businessName,
                            active: true,
                        },
                    ]}
                />
                <DashboardSubpageHeader
                    title={customer?.businessName}
                    extra={
                        <Button.Group>
                            <Button
                                type={"default"}
                                icon={<FontAwesomeIcon icon={faRefresh}/>}
                                onClick={fetchCustomerData}
                            >
                                Actualizar
                            </Button>
                            <Button type={"primary"} icon={<FontAwesomeIcon icon={faTrash}/>} onClick={toggleDelete}>
                                Eliminar
                            </Button>
                        </Button.Group>
                    }
                />
                {customer ? <CustomerPageTabs onUpdateEnded={fetchCustomerData} customer={customer}/> : null}
                <DeleteBottomSheet rowKey={"deleteUser"} title={"Eliminar cliente"} id={toNumber(id!!)}
                                   onSubmit={deleteCustomer} onClose={toggleDelete} open={deleteEvent}/>
            </DashboardStateContainer>
        </Fragment>
    );
};
