import React, { FC, Fragment } from "react";
import { Field, FieldArray, FieldProps } from "formik";
import { ChartSchemaDefinition } from "../../../../types/Chart";
import { Button, Card, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPalette,
  faClose,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FormItem, InputNumber, Input } from "formik-antd";
import { EmptyData } from "../../../components/Empty";
import { ColorPickerField } from "./ColorPickerField";

export const GaugeField: FC<FieldProps<ChartSchemaDefinition>> = ({
  field: { name, value },
}) => {
  return (
    <Fragment>
      <FieldArray name={`${name}.gauge`}>
        {(helpers) => (
          <div className={"gap-2 flex flex-col items-start content-start my-2"}>
            <Typography.Text type={"secondary"}>Gauge</Typography.Text>

            {!!value && !!value.gauge && value.gauge.length > 0 ? (
              value.gauge.map((_, index) => (
                <Card
                  key={index}
                  className={
                    "flex flex-col justify-start gap-2 items-start content-start"
                  }
                >
                  <Button
                    type={"default"}
                    icon={<FontAwesomeIcon icon={faClose} />}
                    onClick={() => helpers.remove(index)}
                    className={"mb-3"}
                    size={"small"}
                  >
                    Eliminar
                  </Button>
                  <FormItem
                    name={`${name}.gauge.${index}.property`}
                    label={"Propiedad"}
                  >
                    <Input name={`${name}.gauge.${index}.property`} />
                  </FormItem>
                  <FormItem
                    name={`${name}.gauge.${index}.title`}
                    label={"Título"}
                  >
                    <Input name={`${name}.gauge.${index}.title`} />
                  </FormItem>
                  <FormItem
                    name={`${name}.gauge.${index}.suffix`}
                    label={"Sufijo"}
                  >
                    <Input name={`${name}.gauge.${index}.suffix`} />
                  </FormItem>
                  <FormItem
                    name={`${name}.gauge.${index}.minLimit`}
                    label={"Valor mínimo"}
                  >
                    <InputNumber name={`${name}.gauge.${index}.minLimit`} />
                  </FormItem>
                  <FormItem
                    name={`${name}.gauge.${index}.maxLimit`}
                    label={"Valor máximo"}
                  >
                    <InputNumber name={`${name}.gauge.${index}.maxLimit`} />
                  </FormItem>
                  <FormItem
                    name={`${name}.gauge.${index}.startAngle`}
                    label={"Ángulo Inicial"}
                  >
                    <InputNumber name={`${name}.gauge.${index}.startAngle`} />
                  </FormItem>
                  <FormItem
                    name={`${name}.gauge.${index}.endAngle`}
                    label={"Ángulo Final"}
                  >
                    <InputNumber name={`${name}.gauge.${index}.endAngle`} />
                  </FormItem>
                  <FieldArray name={`${name}.gauge.${index}.colors`}>
                    {(colorsHelpers) => (
                      <div
                        className={
                          "gap-2 flex flex-col items-start content-start my-2"
                        }
                      >
                        <Typography.Text type={"secondary"}>
                          Colores
                        </Typography.Text>

                        {!!value.gauge &&
                        !!value.gauge[index].colors &&
                        value.gauge!![index].colors!!.length > 0 ? (
                          value.gauge!![index].colors!!.map((_, index2) => (
                            <div
                              key={`g-${index2}`}
                              className={
                                "flex flex-row justify-start gap-2 items-center content-center"
                              }
                            >
                              <Button
                                type={"primary"}
                                shape={"circle"}
                                size={"small"}
                                icon={<FontAwesomeIcon icon={faMinus} />}
                                onClick={() => colorsHelpers.remove(index2)}
                                className={"mr-3"}
                              />
                              <Field
                                name={`${name}.gauge.${index}.colors.${index2}`}
                              >
                                {(props: any) => (
                                  <ColorPickerField {...props} />
                                )}
                              </Field>
                            </div>
                          ))
                        ) : (
                          <div className={"justify-self-center self-center"}>
                            <EmptyData
                              description={"No hay rangos establecidos."}
                            />
                          </div>
                        )}
                        <Button
                          type={"primary"}
                          icon={<FontAwesomeIcon icon={faPalette} />}
                          onClick={() => colorsHelpers.push({})}
                          className={"mr-3"}
                        >
                          Agregar Color
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                </Card>
              ))
            ) : (
              <div className={"justify-self-center self-center"}>
                <EmptyData description={"No hay colores establecidos."} />
              </div>
            )}
            <Button
              type={"primary"}
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => helpers.push({})}
              className={"mr-3"}
            >
              Agregar Gauge
            </Button>
          </div>
        )}
      </FieldArray>
    </Fragment>
  );
};
