import { PieConfig } from "@ant-design/charts";
import { Pie } from "@ant-design/plots";
import { ChartData1 } from "../../../types/charts/Chart";
import { ChartSchemaDefinition } from "../../../types/Chart";
import { useMemo } from "react";
import {
  generateChartColors,
  generateChartTitles,
  wordToColor,
} from "../../../utils/Charts";
import { CustomChartTooltip } from "./Tooltip";

type Props = {
  tooltipTitle: string;
  items: Array<ChartData1>;
  schema?: ChartSchemaDefinition;
  id: string;
};
export function PieChart({ tooltipTitle, items, schema }: Props) {
  const colors = useMemo(
    () => (schema ? generateChartColors(schema) : null),
    [schema]
  );
  const titles = useMemo(
    () => (schema ? generateChartTitles(schema) : null),
    [schema]
  );

  const config: PieConfig = {
    data: items,
    angleField: "value",
    colorField: "title",
    color: (datum, defaultColor) =>
      !!colors ? colors[datum.name] : wordToColor(datum.title),
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
    legend: {
      itemName: {
        formatter: (it) => (titles ? titles[it] : it),
      },
    },
    tooltip: {
      customContent: (_, items: any[]) => (
        <CustomChartTooltip
          title={tooltipTitle}
          items={items}
          titles={titles}
        />
      ),
      title: tooltipTitle || "Sin título",
    },
  };

  return <Pie {...config} />;
}
