import React, { FC } from "react";
import { Field, FieldArray, FieldProps } from "formik";
import { ChartSchemaDefinition } from "../../../../types/Chart";
import { Button, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FormItem, Input } from "formik-antd";
import { EmptyData } from "../../../components/Empty";
import { ColorPickerField } from "./ColorPickerField";

export const ValuesField: FC<FieldProps<ChartSchemaDefinition>> = ({
  field: { name, value },
}) => {
  return (
    <FieldArray name={`${name}.properties`}>
      {(helpers) => (
        <div className={"gap-2 flex flex-col items-start content-start my-2"}>
          <Typography.Text type={"secondary"}>
            El gráfico de dispersión necesita valores conocidos, defina los
            valores que se esperan recolectar. Un título para mostrar y el color
            distintivo de cada valor.
          </Typography.Text>
          <FormItem name={`${name}.property`} label={"Propiedad"}>
            <Input name={`${name}.property`} />
          </FormItem>
          {!!value && !!value.properties && value.properties.length > 0 ? (
            value.properties.map((_, index) => (
              <div
                key={index}
                className={
                  "flex flex-row justify-start gap-2 items-start content-start"
                }
              >
                <Button
                  type={"default"}
                  shape={"circle"}
                  icon={<FontAwesomeIcon icon={faMinus} />}
                  onClick={() => helpers.remove(index)}
                  className={"mr-3"}
                />
                <FormItem
                  tooltip={"Valor conocido"}
                  name={`${name}.properties.${index}.name`}
                >
                  <Input
                    name={`${name}.properties.${index}.name`}
                    placeholder={"Nombre"}
                  />
                </FormItem>
                <FormItem
                  tooltip={
                    "Este es el títulp para el valor, será igual al nombre si se deja en blanco."
                  }
                  name={`${name}.properties.${index}.title`}
                >
                  <Input
                    name={`${name}.properties.${index}.title`}
                    placeholder={"(Opcional) Título"}
                  />
                </FormItem>
                <FormItem name={`${name}.properties.${index}.color`}>
                  <Field name={`${name}.properties.${index}.color`}>
                    {(props: any) => <ColorPickerField {...props} />}
                  </Field>
                </FormItem>
              </div>
            ))
          ) : (
            <div className={"justify-self-center self-center"}>
              <EmptyData description={"No hay valores conocidos."} />
            </div>
          )}
          <Button
            type={"primary"}
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => helpers.push({})}
            className={"mr-3"}
          >
            Agregar
          </Button>
        </div>
      )}
    </FieldArray>
  );
};
