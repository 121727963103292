import { FC } from "react";
import { VehicleDeviceData } from "../../../../../types/DeviceData";
import { isEmpty } from "lodash";
import { EmptyData } from "../../../../components/Empty";
import { Card, Typography } from "antd";
import { ChartDataRender } from "../../../../components/charts/Render";
type Props = {
  deviceData: VehicleDeviceData[] | null;
};

export const DataViewer: FC<Props> = ({ deviceData }) => {
  return !deviceData || isEmpty(deviceData) ? (
    <EmptyData
      description={
        "No se encontraron datos para mostrar. Seleccione otro rango de fecha. "
      }
    ></EmptyData>
  ) : (
    <ChartsItemsRenderer deviceData={deviceData} />
  );
};

const ChartsItemsRenderer: FC<Required<Props>> = ({ deviceData }) => {
  return (
    <div className={"w-full flex flex-col gap-2"}>
      {deviceData!!.map((it, i) => (
        <Card key={`log-${it.device.key}`}>
          <Typography.Paragraph>
            {it.device.description && (
              <Typography.Text>{it.device.description}</Typography.Text>
            )}
            <br />
            <Typography.Text type={"secondary"}>
              {it.device.key}
            </Typography.Text>
          </Typography.Paragraph>
          <ChartDataRender
            id={it.device.key}
            data={it.data}
            title={it.device.description}
            schema={null!}
          />
        </Card>
      ))}
    </div>
  );
};
