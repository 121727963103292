import React, { FC } from "react";
import { ChartData1 } from "../../../types/charts/Chart";
import { BaseLinearChart } from "./BaseLinear";
import { ChartSchemaDefinition } from "../../../types/Chart";

type Props = {
  tooltipTitle: string;
  items: Array<ChartData1<number>>;
  id: string;
  schema: ChartSchemaDefinition;
};

export const AreaChart: FC<Props> = (props) => {
  return <BaseLinearChart {...props} type={"area"} />;
};
