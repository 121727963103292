import { FC, useEffect } from "react";
import { DashboardSubpageContainer } from "../../components/DashboardContainer";
import { DashboardSubpageHeader } from "../../components/DashboardHeader";
import { DeviceCreateForm } from "./components/Form";
import { useDeviceViewModel } from "../../../viewmodel/Device";
import { AppLoader } from "../../components/AppLoader";
import { notification } from "antd";
import { DashboardBreadCrumb } from "../../components/DashboardBreadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faProjectDiagram } from "@fortawesome/free-solid-svg-icons";

type Props = {};
export const DashboardDeviceCreatePage: FC<Props> = ({}) => {
  const { createDevice, createDeviceState, onCreateDeviceStateReceived } =
    useDeviceViewModel();

  useEffect(() => {
    if (!!createDeviceState && !createDeviceState.loading) {
      if (createDeviceState.hasError) {
        notification.error({ message: createDeviceState.error?.message });
      } else {
        notification.success({ message: "Variable creado correctamente." });
      }
      onCreateDeviceStateReceived();
    }
  }, [createDeviceState]);

  return (
    <DashboardSubpageContainer className={"w-full h-fit overflow-x-hidden"}>
      <AppLoader loading={!!createDeviceState && createDeviceState.loading} />
      <DashboardBreadCrumb
        items={[
          {
            pathname: "/dashboard/devices",
            title: "Variables",
            icon: <FontAwesomeIcon icon={faProjectDiagram} />,
          },
          {
            active: true,
            title: "Nuevo",
          },
        ]}
      />
      <DashboardSubpageHeader title={"Nueva Variable"} />
      <DeviceCreateForm onFinish={createDevice} />
    </DashboardSubpageContainer>
  );
};
