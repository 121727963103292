import { Drawer, Layout } from "antd";
import { FC, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { DashboardLayoutSiderContent } from "./components/Sider";
import { DashboardLayoutHeader } from "./components/Header";
import { useBreakpoints } from "../hooks/Breakpoint";
import { isSm, screenIsAnyOf, isXs } from "../../utils/tailwind.screens";
import { socketConnect, socketDisconnect } from "../../services/SocketIO";
import { DashboardFooter } from "../components/DashboardFooter";

const { Content, Sider } = Layout;
export const DashboardLayout: FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { breakpoint } = useBreakpoints();
  const isMobile = screenIsAnyOf(breakpoint, isXs, isSm);

  useEffect(() => {
    socketConnect();
    return () => {
      socketDisconnect();
    };
  }, []);

  return (
    <Layout
      className={"w-screen h-screen overflow-x-hidden overflow-y-auto bg-white"}
    >
      {isMobile ? (
        <Drawer
          bodyStyle={{
            padding: 0,
            margin: 0,
          }}
          placement="left"
          onClose={() => {
            setCollapsed(false);
          }}
          open={collapsed}
        >
          <DashboardLayoutSiderContent
            isMobile={isMobile}
            collapsed={collapsed}
          />
        </Drawer>
      ) : (
        <Sider
          theme={"light"}
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          width={300}
        >
          <DashboardLayoutSiderContent
            isMobile={isMobile}
            collapsed={collapsed}
          />
        </Sider>
      )}
      <Layout className={"h-screen w-full overflow-x-hidden overflow-y-auto"}>
        <DashboardLayoutHeader
          isMediumScreen={!isMobile}
          openDrawer={() => setCollapsed(true)}
        />
        <Content className={"w-full h-fit"}>
          <Outlet />
          <DashboardFooter />
        </Content>
      </Layout>
    </Layout>
  );
};
