import { useState } from "react";
import { AppState, TaskState } from "../data/domain/State";
import { CustomerUserRepository } from "../data/repository/CustomerUser";
import { CustomerUser, NewCustomerUserFormData } from "../types/CustomerUser";
import { UpdateCustomerFormData } from "../types/Customer";
import {DeleteFormData} from "../types/App";

export function useCustomerUserViewModel(
  customerId: number | undefined = undefined,
) {
  const [createState, setCreateState] = useState<AppState<boolean> | null>(
    null,
  );
  const [customerUser, setCustomerUser] = useState<CustomerUser | null>(null);
  const [fetchUserState, setFetchUserState] =
    useState<AppState<boolean> | null>(null);
  const [updateState, setUpdateState] = useState<AppState<boolean> | null>();

  const [fetchListState, setFetchListState] =
    useState<AppState<boolean> | null>(null);
  const [userList, setUserList] = useState<Array<CustomerUser> | undefined>();
  const [deleteState, setDeleteState] = useState<AppState<boolean> | null>()

  async function fetchList() {
    if (fetchListState?.loading) return;
    if (!customerId) return;
    setFetchListState(TaskState.loading());
    try {
      const users = await CustomerUserRepository.getUserList(customerId);
      if (users!.ok) {
        setUserList(users!.data!!);
        setFetchListState(TaskState.success(true));
      } else {
        setFetchListState(TaskState.error(new Error(users!.message)));
      }
    } catch (e: any) {
      setFetchListState(TaskState.error(e));
    }
  }

  function onFetchListStateReceived() {
    setFetchListState(null);
  }

  async function fetchUser(id: number) {
    if (fetchUserState?.loading) return;
    setFetchUserState(TaskState.loading());
    try {
      const result = await CustomerUserRepository.getUser(id);
      if (result!!.ok) {
        setCustomerUser(result!!.data!!);
        setFetchUserState(TaskState.success(true));
      } else {
        setFetchUserState(TaskState.error(new Error(result!!.message)));
      }
    } catch (e: any) {
      setFetchUserState(TaskState.error(e));
    }
  }

  function onFetchUserStateReceived() {
    setFetchUserState(null);
  }

  async function createUser(data: NewCustomerUserFormData) {
    if (createState?.loading) return;
    setCreateState(TaskState.loading());
    try {
      const res = await CustomerUserRepository.createUser(data);
      if (res!!.ok) {
        setCreateState(TaskState.success(true));
        return true;
      } else {
        setCreateState(TaskState.error(new Error(res!!.message)));
      }
    } catch (e: any) {
      setCreateState(TaskState.error(e));
    }
  }

  function onCreateStateReceived() {
    setCreateState(null);
  }

  async function updateUser(data: UpdateCustomerFormData) {
    if (updateState?.loading) return;
    setUpdateState(TaskState.loading());
    if (!customerUser) {
      setUpdateState(TaskState.error(new Error("Usuario no encontrado.")));
      return;
    }
    try {
      const result = await CustomerUserRepository.updateUser(
        customerUser.id,
        data,
      );
      if (result.ok) {
        setUpdateState(TaskState.success(true));
        return true;
      } else {
        setUpdateState(TaskState.error(new Error(result.message)));
      }
    } catch (e: any) {
      setUpdateState(TaskState.error(e));
    }
  }

  function onUpdateUserStateReceived() {
    setUpdateState(null);
  }

  async function deleteCustomerUser(data: DeleteFormData) {
    if(deleteState?.loading) return
    setDeleteState(TaskState.loading())
    try {
      await CustomerUserRepository.deleteCustomerUser(data)
      setDeleteState(TaskState.success(true))
      return true
    } catch (e: any) {
      setDeleteState(TaskState.error(e))
    }
  }

  function onDeleteStateReceived() {
    setDeleteState(null)
  }

  return {
    createState,
    createUser,
    onCreateStateReceived,
    customerUser,
    fetchUser,
    fetchUserState,
    onFetchUserStateReceived,
    updateUser,
    updateState,
    onUpdateUserStateReceived,
    fetchListState,
    fetchList,
    onFetchListStateReceived,
    userList,
    deleteState,
    deleteCustomerUser,
    onDeleteStateReceived
  };
}
