import { RemoteSourceResponse } from "../../../types/Source";
import { apiService } from "../../../services/RemoteClient";
import { BaseRemoteSource } from "../base/Remote";
import {
  NewGatewayDevice,
  GatewayDevice,
  GatewayDeviceSelection,
} from "../../../types/GatewayDevice";
import { DeleteData } from "../../../types/App";

export class RemoteGatewayDeviceSource extends BaseRemoteSource {
  static async getGatewayDevices(
    id: number,
    selection?: GatewayDeviceSelection
  ): Promise<RemoteSourceResponse<Array<GatewayDevice>>> {
    try {
      const response = await apiService.postWithAuth("/gateway_device/get", {
        id,
        selection,
      });
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse<Array<GatewayDevice>>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async createGatewayDevice(
    data: NewGatewayDevice
  ): Promise<RemoteSourceResponse<GatewayDevice>> {
    try {
      const response = await apiService.postWithAuth(
        "/gateway_device/create",
        data
      );
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse<GatewayDevice>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async deleteGatewayDevice(data: DeleteData) {
    try {
      const response = await apiService.deleteWithAuth(
        "/gateway_device/delete",
        {
          data,
        }
      );
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse;
    } catch (e) {
      throw this.parseError(e);
    }
  }
}
