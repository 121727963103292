import { FC, useCallback, useEffect } from "react";
import { Button, Typography } from "antd";
import { faPlus, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { UserListData } from "./components/Data";
import { useCustomerUserViewModel } from "../../../../../viewmodel/CustomerUser";
import { Customer } from "../../../../../types/Customer";
import { CustomerUser } from "../../../../../types/CustomerUser";
import { AppLoader } from "../../../../components/AppLoader";
import { DashboardStateContainer } from "../../../../components/DashboardStateContainer";
import { EmptyData } from "../../../../components/Empty";

type Props = {
  customer: Customer;
};

export const CustomerUserListTab: FC<Props> = ({ customer }) => {
  const { fetchList, fetchListState, userList, onFetchListStateReceived } =
    useCustomerUserViewModel(customer.id);
  const navigate = useNavigate();

  const onNewUserClick = useCallback(() => {
    navigate("/dashboard/customers/users/create?customerId=" + customer.id);
  }, [navigate]);

  const onDataItemClick = useCallback(
    (item: CustomerUser) => {
      navigate("/dashboard/customers/users/" + item.id);
    },
    [navigate, userList],
  );

  useEffect(() => {
    void fetchList();
  }, []);

  useEffect(() => {
    if (!!fetchListState && !fetchListState?.loading) {
      if (fetchListState?.hasError) {
        toast.error(fetchListState?.error?.message);
      } else {
        console.log(userList);
      }
      onFetchListStateReceived();
    }
  }, [fetchListState]);

  return (
    <DashboardStateContainer>
      <AppLoader loading={!!fetchListState && fetchListState.loading} />
      <Typography.Text type={"secondary"}>
        Usuarios disponibles para este cliente.
      </Typography.Text>
      <Button.Group className={"block my-2"}>
        <Button
          type={"default"}
          icon={<FontAwesomeIcon icon={faRefresh} />}
          onClick={fetchList}
        >
          Actualizar
        </Button>
        <Button
          type={"default"}
          icon={<FontAwesomeIcon icon={faPlus} />}
          onClick={onNewUserClick}
        >
          Nuevo
        </Button>
      </Button.Group>
      <div className={"overflow-y-auto"}>
        {!!userList && userList.length > 0 ? (
          <UserListData onItemClicked={onDataItemClick} data={userList} />
        ) : (
          <EmptyData description={"Este cliente no tiene usuarios"} />
        )}
      </div>
    </DashboardStateContainer>
  );
};
