import { BarConfig } from "@ant-design/charts";
import { Bar } from "@ant-design/plots";
import { ChartData1 } from "../../../types/charts/Chart";

type Props<T> = {
  tooltipTitle: string;
  items: Array<ChartData1>;
  keyColors?: BarConfig["color"];
  id: string
};
export function BarChart<T>({ tooltipTitle, items }: Props<T>) {
  const config: BarConfig = {
    data: items,
    xField: "value",
    yField: "title",
    seriesField: "title",
  };

  return <Bar {...config} />;
}
