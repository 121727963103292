import {FC, useEffect, useState} from "react";
import {Avatar, Typography, Checkbox} from "antd"
import AcmeIcon from "../../../../assets/AICON.png";
import * as yup from "yup";
import {handleInputEvent, useFormValidation} from "../../../hooks/Validation";
import {LoginData} from "../../../../types/User";
import useCookie from "react-use-cookie";
import {Link as RouterLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAt, faPaperPlane} from "@fortawesome/free-solid-svg-icons"
import {Formik} from "formik"
import {Form, Input, FormItem, SubmitButton} from "formik-antd"

type Props = {
    onSubmit: (values: LoginData) => Promise<boolean | undefined>;
};

const kAUTH_FORM_VALIDATOR = yup.object({
    email: yup
        .string()
        .required("Ingresa un email")
        .email("Ingresa un email válido"),
    password: yup.string().required("Ingresa una contraseña"),
})

export const AuthPageForm: FC<Props> = ({onSubmit}) => {
    const [rememberMeCookie, setRememberMeCookie] = useCookie(
        "rememberMe",
        "false"
    );
    const [rememberMe, setRememberMe] = useState<boolean>(
        rememberMeCookie === "true"
    );

    useEffect(() => {
        setRememberMe(rememberMeCookie === "true");
    }, [rememberMeCookie]);

    return (
        <div
            className={"flex flex-col justify-center items-center content-center w-full h-full p-4"}
        >
            <div className={"w-full h-full sm:max-w-md flex flex-col justify-center items-center content-center"}
            >
                <Avatar size={64} icon={<img src={AcmeIcon} alt={"ACME"}/>} style={{background: "transparent"}}/>
                <Typography.Title level={3}>
                    Iniciar Sesión
                </Typography.Title>
              <Formik<Partial<LoginData>> validateOnChange initialValues={{}}
                                                           validationSchema={kAUTH_FORM_VALIDATOR}
                                                           onSubmit={(values, formikHelpers)=>{
                                                               onSubmit(values as LoginData).then(it=>{
                                                                   if(it) formikHelpers.resetForm()
                                                                   formikHelpers.setSubmitting(false)
                                                               })
                                                           }
                                                           }>
                  <Form
                      layout={"vertical"}
                      className={"w-full"}
                  >
                      <FormItem label={"Correo Electrónico"} name={"email"}>
                          <Input name={"email"} allowClear prefix={<FontAwesomeIcon icon={faAt}/>}
                                 placeholder={"Correo Electrónico"}/>
                      </FormItem>
                      <FormItem label={"Contraseña"} name={"password"}>
                          <Input.Password allowClear name={"password"} placeholder={"Contraseña"}/>
                      </FormItem>
                          <Checkbox checked={rememberMe} onChange={(e) => {
                              setRememberMeCookie(String(e.target.checked));
                          }}>
                              Mantener sesión iniciada
                          </Checkbox>

                      <SubmitButton
                          type="primary"
                          className={"w-full"}
                          icon={<FontAwesomeIcon icon={faPaperPlane} />}
                      >
                          Iniciar Sesión
                      </SubmitButton>
                  </Form>
              </Formik>

                <RouterLink to={"/recover-password"} className={"no-underline mt-4"}>
                    <Typography.Text className="text-primary" underline={false}>
                        ¿Olvidaste tu contraseña? Click aquí
                    </Typography.Text>
                </RouterLink>
                <Typography.Text type={"secondary"}
                                 className={"mt-4"}
                >
                    ACME & CIA 2023
                </Typography.Text>
            </div>

        </div>
    );
};
