import { FC, useCallback, useEffect } from "react";
import { DashboardSubpageContainer } from "../../components/DashboardContainer";
import { DashboardSubpageHeader } from "../../components/DashboardHeader";
import { Button } from "antd";
import { faPlus, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDeviceViewModel } from "../../../viewmodel/Device";
import { DeviceListData } from "./components/Data";
import { Device } from "../../../types/Device";
import { DashboardBreadCrumb } from "../../components/DashboardBreadcrumb";
import { useDashboardHeader } from "../../hooks/Header";
import { AppLoader } from "../../components/AppLoader";

export const DashboardDeviceListPage: FC = () => {
  const { fetchList, deviceList, fetchListState, onFetchListStateReceived } =
    useDeviceViewModel();
  const { setTitle } = useDashboardHeader();
  const navigate = useNavigate();
  const onNewDeviceClick = useCallback(() => {
    navigate("/dashboard/devices/create");
  }, [navigate]);
  const onItemClicked = useCallback(
    (record: Device) => {
      navigate(`/dashboard/devices/${record.id}`);
    },
    [deviceList, navigate]
  );

  useEffect(() => {
    setTitle("Variables");
    fetchDevices();
  }, []);

  useEffect(() => {
    if (!!fetchListState && !fetchListState?.loading) {
      if (fetchListState?.hasError) {
        toast.error(fetchListState?.error?.message);
      }
      onFetchListStateReceived();
    }
  }, [fetchListState]);

  const fetchDevices = useCallback(() => {
    void fetchList();
  }, [fetchList]);

  return (
    <DashboardSubpageContainer className={"w-full h-fit overflow-x-hidden"}>
      <AppLoader loading={!!fetchListState && fetchListState.loading} />
      <DashboardBreadCrumb
        items={[
          {
            active: true,
            title: "Variables",
          },
        ]}
      />
      <DashboardSubpageHeader
        title={"Variables"}
        extra={
          <Button.Group>
            <Button
              type={"primary"}
              icon={<FontAwesomeIcon icon={faRefresh} />}
              onClick={fetchDevices}
              size={"small"}
            >
              Actualizar
            </Button>
            <Button
              type={"dashed"}
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={onNewDeviceClick}
              size={"small"}
            >
              Nuevo
            </Button>
          </Button.Group>
        }
      />
      <DeviceListData data={deviceList} onItemClicked={onItemClicked} />
    </DashboardSubpageContainer>
  );
};
