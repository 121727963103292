import { ProDescriptions } from "@ant-design/pro-components";
import {FC, useCallback, useEffect, useState} from "react";
import {notification, Typography} from "antd";
import {Customer, UpdateCustomerFormData} from "../../../../../types/Customer";
import dayjs from "dayjs";
import {DescriptionsItem, DescriptionsList} from "../../../../components/DescriptionsList";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faAt,
  faCalendar,
  faHashtag,
  faIdCard,
  faTag,
  faTextSlash,
  faToggleOn,
  faLocation,
  faCode,
  faGlobe, faBuilding
} from "@fortawesome/free-solid-svg-icons";
import {fastFormatDate} from "../../../../../utils/Dates";
import {DashboardStateContainer} from "../../../../components/DashboardStateContainer";
import {UpdateBottomSheet, UpdateBottomSheetFormItemType} from "../../../../components/UpdateBottomSheet";
import {NewUserFormData} from "../../../../../types/User";
import {AppLoader} from "../../../../components/AppLoader";
import {useCustomerViewModel} from "../../../../../viewmodel/Customer";
import * as yup from "yup";

type Props = {
  customer: Customer;
  onUpdateEnded: ()=>void
};

const kUPDATE_CUSTOMER_ITEMS : UpdateBottomSheetFormItemType[] = [{
  name: "address",
  label: "Dirección",
  type: "string",
  renderProps: {
    placeholder: "Ingresar Dirección",
    prefix: <FontAwesomeIcon icon={faLocation} />
  }
}, {
  name: "subdomain",
  label: "Subdominio",
  type: "string",
  renderProps: {
    placeholder: "Ingresar subdominio",
    prefix: <FontAwesomeIcon icon={faGlobe} />
  }
}, {
  name: "postalCode",
  label: "Código postal",
  type: "string",
  renderProps: {
    placeholder: "Ingresar código postal",
    prefix: <FontAwesomeIcon icon={faCode} />
  }
}, {
  name: "enabled",
  label: "Cliente habilitado",
  type: "switch",
}]

const kUPDATE_CUSTOMER_SCHEMA = yup.object({
  address: yup.string(),
  subdomain: yup
      .string(),
  postalCode: yup.string(),
  enabled: yup.boolean(),
  reason: yup.string().required("Detalle la descripción de esta actividad: ")
})
export const CustomerMainTab: FC<Props> = ({ customer, onUpdateEnded }) => {
  const {updateCustomer, updateCustomerState, onUpdateCustomerStateReceived} = useCustomerViewModel()
  const [activeEditableItem, setActiveEditableItem] = useState<string[] | undefined>()
  const [initialValues, setInitialValues] = useState<Partial<NewUserFormData>>({})
  const onEditClicked = useCallback((name: string) => {
    if(name === "enabled") {
      setInitialValues({
        enabled: customer?.enabled
      })
    } else {
      setInitialValues({})
    }
    setActiveEditableItem([name])
  }, [setActiveEditableItem, setInitialValues, customer]);
  useEffect(() => {
    if (!!updateCustomerState && !updateCustomerState.loading) {
      if (updateCustomerState.hasError) {
        notification.error({
          message: updateCustomerState.error?.message,
        });
      } else {
        notification.success({
          message: "Información actualizada."
        })
        onUpdateEnded()
      }
      onUpdateCustomerStateReceived();
    }
  }, [updateCustomerState]);
  const updateCustomerNow = useCallback(async (data: UpdateCustomerFormData)=>{
    return updateCustomer(customer.id, data)
  }, [customer])
  return (
    <DashboardStateContainer>
      <UpdateBottomSheet<UpdateCustomerFormData> defaultValues={initialValues} validationSchema={kUPDATE_CUSTOMER_SCHEMA} rowKey={"customer"} title={"Actualizar Información"} items={kUPDATE_CUSTOMER_ITEMS} onSubmit={updateCustomerNow} open={!!activeEditableItem} activeItems={activeEditableItem} onClose={()=>setActiveEditableItem(undefined)} />
      <AppLoader loading={(!!updateCustomerState && updateCustomerState.loading)} />
      <DescriptionsList rowKey={"customer"} onEditClicked={onEditClicked}>
        <DescriptionsItem
            icon={<FontAwesomeIcon icon={faIdCard} />}
            title={"ID"}
            label={customer?.id}
        />
        <DescriptionsItem
            icon={<FontAwesomeIcon icon={faBuilding} />}
            title={"Razón Social"}
            label={customer?.businessName}
        />
        <DescriptionsItem
            icon={<FontAwesomeIcon icon={faTag} />}
            title={"RUC"}
            label={customer?.ruc}
        />
        <DescriptionsItem
            icon={<FontAwesomeIcon icon={faGlobe} />}
            title={"Subdominio"}
            label={customer?.subdomain}
            editable={true}
            itemKey={"subdomain"}
        />
        <DescriptionsItem
            icon={<FontAwesomeIcon icon={faLocation} />}
            title={"Dirección"}
            label={customer?.address}
            editable={true}
            itemKey={"address"}
        />
        <DescriptionsItem
            icon={<FontAwesomeIcon icon={faCode} />}
            title={"Código Postal"}
            label={customer?.postalCode}
            editable={true}
            itemKey={"postalCode"}
        />
        <DescriptionsItem
            icon={<FontAwesomeIcon icon={faCalendar} />}
            title={"Cliente desde"}
            label={fastFormatDate(customer?.createdAt!!, "dd MMM yyyy")}
        />
        <DescriptionsItem
            icon={<FontAwesomeIcon icon={faToggleOn} />}
            title={"Estado"}
            label={customer?.enabled ? "Cliente habilitado" : "Cliente deshabilitado"}
            editable={true}
            itemKey={"enabled"}
        />
      </DescriptionsList>
    </DashboardStateContainer>
  );
};
