import { Component, createRef, MutableRefObject } from "react";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { DeviceDataPreRender } from "../../../components/charts/Render";
import { generateChartMockingData } from "../../../../utils/Charts";
import { ChartSchemaDefinition } from "../../../../types/Chart";

export class GraphicPrerenderer extends Component<
  { schema: ChartSchemaDefinition; showButton?: boolean },
  any
> {
  tickRef: MutableRefObject<boolean | null>;
  constructor(props: { schema: ChartSchemaDefinition; showButton: boolean }) {
    super(props);
    this.state = {};
    this.tickRef = createRef() as MutableRefObject<boolean | null>;
    this.tick = this.tick.bind(this);
  }

  tick() {
    this.tickRef.current = true;
    this.setState({});
  }

  shouldComponentUpdate(
    _: Readonly<{
      schema: ChartSchemaDefinition;
    }>,
    _1: Readonly<any>,
    _2: any
  ): boolean {
    const currentUpdate = !!this.tickRef.current;
    this.tickRef.current = null;
    return currentUpdate;
  }

  render() {
    return (
      <div className={"flex flex-col gap2 w-full items-center"}>
        {this.props.showButton && (
          <Button
            onClick={this.tick}
            type={"primary"}
            icon={<FontAwesomeIcon icon={faEye} />}
          >
            Previsualizar
          </Button>
        )}
        <DeviceDataPreRender
          schema={this.props.schema}
          data={generateChartMockingData(this.props.schema)}
          title={"Gráfico de prueba"}
          id={Math.random().toString()}
        />
      </div>
    );
  }
}
