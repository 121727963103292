import { FC } from "react";
import { useBreakpoints } from "../../../hooks/Breakpoint";
import { isSm, isXs, screenIsAnyOf } from "../../../../utils/tailwind.screens";
import { EmptyData } from "../../../components/Empty";
import { Chart } from "../../../../types/Chart";
import { ChartListGrid } from "./Grid";
import { ChartListTable } from "./Table";

export type ChartListDataProps = {
  data?: Chart[] | null;
  onItemClicked: (item: Chart) => void;
};
export const ChartListData: FC<ChartListDataProps> = (props) => {
  const { breakpoint } = useBreakpoints();
  const isMobile = screenIsAnyOf(breakpoint, isXs, isSm);
  return props.data && props.data.length > 0 ? (
    isMobile ? (
      <ChartListGrid {...props} />
    ) : (
      <ChartListTable {...props} />
    )
  ) : (
    <EmptyData
      title={"No hay charts"}
      description={"No hay charts registrados..."}
    />
  );
};
