import { FC, useEffect } from "react";
import { DashboardSubpageContainer } from "../../components/DashboardContainer";
import { DashboardSubpageHeader } from "../../components/DashboardHeader";
import { ChartCreateForm } from "./components/Form";
import { AppLoader } from "../../components/AppLoader";
import { notification } from "antd";
import { DashboardBreadCrumb } from "../../components/DashboardBreadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { useChartViewModel } from "../../../viewmodel/Chart";

type Props = {};
export const DashboardChartCreatePage: FC<Props> = ({}) => {
  const { createChart, createChartState, onCreateChartStateReceived } =
    useChartViewModel();

  useEffect(() => {
    if (!!createChartState && !createChartState.loading) {
      if (createChartState.hasError) {
        notification.error({ message: createChartState.error?.message });
      } else {
        notification.success({ message: "Chart creado correctamente." });
      }
      onCreateChartStateReceived();
    }
  }, [createChartState]);

  return (
    <DashboardSubpageContainer className={"w-full h-fit overflow-x-hidden"}>
      <AppLoader loading={!!createChartState && createChartState.loading} />
      <DashboardBreadCrumb
        items={[
          {
            pathname: "/dashboard/charts",
            title: "Charts",
            icon: <FontAwesomeIcon icon={faChartSimple} />,
          },
          {
            active: true,
            title: "Nuevo",
          },
        ]}
      />
      <DashboardSubpageHeader title={"Nuevo chart"} />
      <ChartCreateForm onFinish={createChart} />
    </DashboardSubpageContainer>
  );
};
