import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import { ConfigProvider, App as AntApp } from "antd";
import { zAPP_ROUTES } from "./utils/Routes";
import dayjs from "dayjs";
import esES from "antd/locale/es_ES";
import "dayjs/locale/es";
import { useBreakpoints } from "./ui/hooks/Breakpoint";
import { isSm, isXs, screenIsAnyOf } from "./utils/tailwind.screens";
import { useDayNightTheme } from "./ui/hooks/SwitchTheme";
import { HappyProvider } from "@ant-design/happy-work-theme";
import {
  DarkTheme,
  DarkThemeSmall,
  LightTheme,
  LightThemeSmall,
} from "./utils/Theme";

dayjs.locale("es");

function App() {
  const { breakpoint } = useBreakpoints();
  const isMobile = screenIsAnyOf(breakpoint, isXs, isSm);
  const { darkMode } = useDayNightTheme();
  return (
    <AntApp>
      <ConfigProvider
        locale={esES}
        componentSize={isMobile ? "small" : "middle"}
        theme={
          isMobile
            ? darkMode
              ? DarkThemeSmall
              : LightThemeSmall
            : darkMode
            ? DarkTheme
            : LightTheme
        }
      >
        <HappyProvider>
          <div
            className={`w-screen h-screen overflow-hidden ${
              darkMode ? "dark" : ""
            }`}
          >
            <ToastContainer />
            <RouterProvider
              router={createBrowserRouter(zAPP_ROUTES as RouteObject[])}
            />
          </div>
        </HappyProvider>
      </ConfigProvider>
    </AntApp>
  );
}

export default App;
