import { FC } from "react";
import { Button } from "antd";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faPaperPlane,
  faICursor,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import { NewDeviceFormData } from "../../../../types/Device";
import { FormItem, Form, Input, SubmitButton, ResetButton } from "formik-antd";
import { Formik } from "formik";
import { FormikActivityReasonField } from "../../../components/FormikActivityReasonField";

type Props = {
  onFinish: (data: NewDeviceFormData) => Promise<boolean | undefined>;
};

const kDEVICE_CREATE_VALIDATOR = yup.object({
  key: yup
    .string()
    .min(
      3,
      "Ingrese un identificador válido. Sin espacios. Ejemplo 'gps' 'sensor_nombre'"
    )
    .matches(/\S./, "Sin espacios. Ejemplo 'gps' 'sensor_nombre'"),
  reason: yup
    .string()
    .required("Especifique el motivo porque registra el dispositivo.")
    .min(16, "Debe contener al menos 16 caracteres."),
});

export const DeviceCreateForm: FC<Props> = ({ onFinish }) => {
  return (
    <Formik
      validateOnBlur={true}
      validateOnChange={false}
      validationSchema={kDEVICE_CREATE_VALIDATOR}
      initialValues={{} as NewDeviceFormData}
      onSubmit={(values, props) => {
        onFinish(values).then((it) => {
          if (it) props.resetForm();
          props.setSubmitting(false);
        });
      }}
    >
      <Form autoComplete={"off"}>
        <FormItem label={"Identificador"} name={"key"} required>
          <Input
            allowClear
            prefix={<FontAwesomeIcon icon={faKey} />}
            name={"key"}
            placeholder={"Identificador"}
          />
        </FormItem>
        <FormItem
          tooltip={
            "Escriba un nombre opcional para esta variable. Este nombre se mostrará en las gráficas."
          }
          label={"Nombre(Opcional)"}
          name={"description"}
          required
        >
          <Input
            autoComplete={"off"}
            allowClear
            prefix={<FontAwesomeIcon icon={faICursor} />}
            name={"description"}
            placeholder={"Nombre"}
          />
        </FormItem>

        <FormikActivityReasonField />
        <Button.Group>
          <SubmitButton
            icon={<FontAwesomeIcon icon={faPaperPlane} />}
            type={"default"}
          >
            Registrar
          </SubmitButton>
          <ResetButton
            type={"primary"}
            ghost
            icon={<FontAwesomeIcon icon={faTrash} />}
          >
            Limpiar
          </ResetButton>
        </Button.Group>
      </Form>
    </Formik>
  );
};
