import {FC, useCallback} from "react";
import {SubmitButton, Form, Input, FormItem} from "formik-antd";
import * as yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faKey, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {NewGatewayFormData} from "../../../../types/Gateway";
import {Formik, FormikHelpers} from "formik";
import {FormikActivityReasonField} from "../../../components/FormikActivityReasonField";

type Props = {
    onFinish: (data: NewGatewayFormData) => Promise<boolean | undefined>
}

const kVALIDATION_SCHEMA = yup.object({
    key: yup.string().min(3, "Ingrese un identificador válido."),
    reason: yup.string().required("Especifique el motivo porque registra el nuevo gateway.").min(16, "Debe contener al menos 16 caracteres.")
})
export const GatewayCreateForm: FC<Props> = ({onFinish}) => {



    const onFormSubmit = useCallback((values: NewGatewayFormData, helpers: FormikHelpers<NewGatewayFormData>) => {
        onFinish(values).then(it=> {
            if(it) helpers.resetForm()
            helpers.setSubmitting(false)
        })
    }, [onFinish])


    return <Formik<NewGatewayFormData> validationSchema={kVALIDATION_SCHEMA} initialValues={{} as any} onSubmit={onFormSubmit}>
        <Form  autoComplete={"off"} layout={"vertical"}>
            <FormItem required name={'key'} label={'ID'} tooltip={
                'Este id es unico para cada Gateway. Ej: gw_0'
            }>
                <Input name={'key'} prefix={<FontAwesomeIcon icon={faKey}/>} id={"key"}
                       placeholder={"Identificador"}/>
            </FormItem>
            <FormItem name={'description'} label={'Descripcion (Opcional)'}>
                <Input name={'description'} />
            </FormItem>
            <FormikActivityReasonField />
            <SubmitButton icon={<FontAwesomeIcon icon={faPaperPlane} />}>Registrar</SubmitButton>
        </Form>
    </Formik>
}