import { FC, MouseEventHandler, useCallback, useEffect, useState } from "react";
import { useGatewayDeviceViewModel } from "../../../../viewmodel/GatewayDevice";
import { Button, Card, Descriptions, notification, Typography } from "antd";
import { EmptyData } from "../../../components/Empty";
import { AppLoader } from "../../../components/AppLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faPlugCircleXmark,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { AddGatewayDeviceModal } from "../components/ModalAddDevice";
import { useDeviceViewModel } from "../../../../viewmodel/Device";
import { Gateway } from "../../../../types/Gateway";
import { DashboardSubpageContainer } from "../../../components/DashboardContainer";
import { useNavigate } from "react-router-dom";
import { DeleteBottomSheet } from "../../../components/DeleteBottomSheet";
import { GatewayDevice } from "../../../../types/GatewayDevice";
import { useChartViewModel } from "../../../../viewmodel/Chart";

type Props = {
  gateway: Gateway;
};

export const GatewayDevicesTab: FC<Props> = ({ gateway }) => {
  const {
    gatewayDeviceList,
    createGatewayDevice,
    createGatewayDeviceState,
    onCreateGatewayDeviceStateReceived,
    onFetchListStateReceived,
    fetchListState,
    fetchList,
    addEvent,
    requestAddEvent,
    onAddEventCompleted,
    deleteGatewayDevice,
    deleteState,
    onDeleteStateReceived,
  } = useGatewayDeviceViewModel();

  const navigate = useNavigate();
  const {
    deviceList,
    fetchList: fetchDeviceList,
    fetchListState: fetchDeviceListState,
    onFetchListStateReceived: onFetchDeviceListStateReceived,
  } = useDeviceViewModel();

  const {
    fetchList: fetchChartList,
    fetchListState: fetchChartListState,
    onFetchListStateReceived: onFetchChartListStateReceived,
    chartList,
  } = useChartViewModel();

  const fetchGatewayDeviceList = useCallback(() => {
    void fetchList(gateway.id);
  }, [gateway]);

  useEffect(() => {
    void fetchGatewayDeviceList();
    void fetchDeviceList();
    void fetchChartList();
  }, []);

  useEffect(() => {
    if (!!fetchListState && !fetchListState.loading) {
      if (fetchListState.hasError) {
        notification.error({
          message: "Ocurrió un error al obtener los dispositivos.",
        });
      }
      onFetchListStateReceived();
    }
  }, [fetchListState]);

  useEffect(() => {
    if (!!fetchChartListState && !fetchChartListState.loading) {
      if (fetchChartListState.hasError) {
        notification.error({
          message: "Ocurrió un error al obtener los charts.",
        });
      }
      onFetchChartListStateReceived();
    }
  }, [fetchChartListState]);

  useEffect(() => {
    if (!!fetchDeviceListState && !fetchDeviceListState.loading) {
      if (fetchDeviceListState.hasError) {
        notification.error({
          message: "Ocurrió un error al obtener los dispositivos.",
        });
      }
      onFetchDeviceListStateReceived();
    }
  }, [fetchDeviceListState]);

  useEffect(() => {
    if (!!createGatewayDeviceState && !createGatewayDeviceState.loading) {
      if (createGatewayDeviceState.hasError) {
        notification.error({
          message: createGatewayDeviceState.error?.message,
        });
      } else {
        notification.success({
          message: "Dispositivo registrado correctamente.",
        });
        void fetchGatewayDeviceList();
      }
      onCreateGatewayDeviceStateReceived();
      onAddEventCompleted();
    }
  }, [createGatewayDeviceState]);

  const onCardClicked: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      const index = Number(event.currentTarget.dataset.index);
      const device = deviceList!![index];
      navigate("/dashboard/devices/" + device.id);
    },
    [navigate, deviceList]
  );

  const [toDeleteDevice, setToDeleteDevice] = useState<GatewayDevice | null>(
    null
  );

  const onDeleteEvent = useCallback(() => {
    setToDeleteDevice(null);
  }, [setToDeleteDevice]);

  const deleteEventHandler: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.stopPropagation();
      const index = Number(event.currentTarget.dataset.index);
      const device = gatewayDeviceList!![index];
      setToDeleteDevice(device);
    },
    [deleteGatewayDevice, gatewayDeviceList]
  );

  useEffect(() => {
    if (!!deleteState && !deleteState.loading) {
      if (deleteState.hasError) {
        notification.error({
          message: deleteState.error?.message,
        });
      } else {
        void fetchGatewayDeviceList();
        notification.success({
          message: "Variable eliminada.",
        });
        onDeleteEvent();
      }
      onDeleteStateReceived();
    }
  }, [deleteState]);

  return (
    <DashboardSubpageContainer>
      {addEvent ? (
        <AddGatewayDeviceModal
          deviceList={deviceList}
          chartList={chartList}
          gateway={gateway}
          onCancel={onAddEventCompleted}
          onFinish={createGatewayDevice}
        />
      ) : null}
      <AppLoader
        loading={
          (!!fetchDeviceListState && fetchDeviceListState.loading) ||
          (!!fetchListState && fetchListState.loading) ||
          (!!deleteState && deleteState.loading) ||
          (!!fetchChartListState && fetchChartListState.loading)
        }
      />
      <Typography.Text type={"secondary"}>
        {gatewayDeviceList?.length} variables en este gateway
      </Typography.Text>
      <Button.Group className={"block my-2"}>
        <Button
          type={"default"}
          onClick={fetchGatewayDeviceList}
          icon={<FontAwesomeIcon icon={faRefresh} />}
        >
          Actualizar
        </Button>
        <Button
          onClick={requestAddEvent}
          type={"default"}
          icon={<FontAwesomeIcon icon={faAdd} />}
        >
          Agregar
        </Button>
      </Button.Group>
      <div className={"overflow-y-auto"}>
        {!!gatewayDeviceList && gatewayDeviceList.length > 0 ? (
          gatewayDeviceList.map((gatewayDevice, index) => (
            <Card
              onClick={onCardClicked}
              data-index={index}
              key={`device-${index}`}
              className={"my-2 cursor-pointer"}
            >
              <Typography.Title level={5}>
                {gatewayDevice.Device.key}
              </Typography.Title>
              <Typography.Text type={"secondary"}>
                {gatewayDevice.Device.description}
              </Typography.Text>
              <Descriptions>
                <Descriptions.Item label={"ID"}>
                  {gatewayDevice.Device.id}
                </Descriptions.Item>
              </Descriptions>
              <Button
                data-index={index}
                onClick={deleteEventHandler}
                icon={<FontAwesomeIcon icon={faPlugCircleXmark} />}
              >
                Quitar
              </Button>
            </Card>
          ))
        ) : (
          <EmptyData
            description={"No hay dispositivos agregados al gateway."}
          />
        )}
      </div>
      <DeleteBottomSheet
        rowKey={"deleteGateway"}
        title={"Quitar Variable"}
        id={toDeleteDevice?.id || 0}
        onSubmit={deleteGatewayDevice}
        onClose={onDeleteEvent}
        open={!!toDeleteDevice}
      />
    </DashboardSubpageContainer>
  );
};
