import * as Yup from "yup";

const rucRegex = /^[0-9]{11}$/;

export const rucValidator = (schema: Yup.StringSchema) => {
  return schema
    .matches(rucRegex, "El RUC debe tener 11 dígitos")
    .test("checksum", "El RUC es inválido", (value) => {
      if (!value) return false;
      let ruc;
      try {
        ruc = parseInt(value);
      } catch (e) {
        return false;
      }
      if (
        !(
          (ruc >= 1e10 && ruc < 11e9) ||
          (ruc >= 15e9 && ruc < 18e9) ||
          (ruc >= 2e10 && ruc < 21e9)
        )
      )
        return false;
      for (var suma = -(ruc % 10 < 2), i = 0; i < 11; i++, ruc = (ruc / 10) | 0)
        suma += (ruc % 10) * ((i % 7) + ((i / 7) | 0) + 1);
      return suma % 11 === 0;
    });
};
