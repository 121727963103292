import { FC, MouseEventHandler, useCallback, useEffect } from "react";
import {Button, Card, Descriptions, notification, Typography} from "antd";
import { EmptyData } from "../../../../components/Empty";
import { AppLoader } from "../../../../components/AppLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { Customer } from "../../../../../types/Customer";
import { useVehicleViewModel } from "../../../../../viewmodel/Vehicle";
import { useNavigate } from "react-router-dom";
import {DashboardStateContainer} from "../../../../components/DashboardStateContainer";
import animationFleet from "../../../../../assets/animations/animation_fleet.json"
type Props = {
  customer: Customer;
};

export const CustomerVehiclesTab: FC<Props> = ({ customer }) => {
  const navigate = useNavigate();

  const { vehicleList, onFetchListStateReceived, fetchListState, fetchList } =
    useVehicleViewModel();

  const fetchCustomerVehicleList = useCallback(() => {
    void fetchList(customer.id);
  }, [customer]);

  useEffect(() => {
    void fetchCustomerVehicleList();
  }, []);

  useEffect(() => {
    if (!!fetchListState && !fetchListState.loading) {
      if (fetchListState.hasError) {
        notification.error({
          message: "Ocurrió un error al obtener los vehículos.",
        });
      }
      onFetchListStateReceived();
    }
  }, [fetchListState]);

  const onAddClicked = useCallback(() => {
    navigate("/dashboard/vehicles/create?customerId="+customer.id);
  }, [navigate]);

  const onCardClicked: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      const index = Number(event.currentTarget.dataset.index);
      const vehicle = vehicleList!![index];
      navigate("/dashboard/vehicles/" + vehicle.id);
    },
    [navigate, vehicleList],
  );

  return (
    <DashboardStateContainer>
        <AppLoader loading={!!fetchListState?.loading} />
        <Typography.Text type={"secondary"}>
            {vehicleList?.length} vehículos para este cliente.
        </Typography.Text>
        <Button.Group className={"block my-2"}>
            <Button
                type={"default"}
                onClick={fetchCustomerVehicleList}
                icon={<FontAwesomeIcon icon={faRefresh} />}
            >
                Actualizar
            </Button>
            <Button
                onClick={onAddClicked}
                type={"default"}
                icon={<FontAwesomeIcon icon={faAdd} />}
            >
                Nuevo
            </Button>
        </Button.Group>
        <div className={"overflow-y-auto"}>
            {!!vehicleList && vehicleList.length > 0 ? (
                vehicleList.map((vehicle, index) => (
                    <Card
                        onClick={onCardClicked}
                        data-index={index}
                        key={`device-${index}`}
                        className={"my-2 cursor-pointer"}
                    >
                        <Typography.Title level={5}>{vehicle.name}</Typography.Title>
                        <Typography.Text type={"secondary"}>{vehicle.description}</Typography.Text>
                        <Descriptions>
                            <Descriptions.Item label={"Marca"}>{vehicle.brand}</Descriptions.Item>
                            <Descriptions.Item label={"Model"}>{vehicle.model}</Descriptions.Item>
                            <Descriptions.Item label={"Placa"}>{vehicle.plate}</Descriptions.Item>

                        </Descriptions>
                    </Card>
                ))
            ) : (
                <EmptyData
                    description={"Este cliente no tiene vehículos"}
                    animation={animationFleet}
                />
            )}
        </div>
    </DashboardStateContainer>
  );
};
