import {FC, useCallback, useEffect, useState} from "react";
import {DashboardSubpageHeader} from "../../components/DashboardHeader";
import {AppLoader} from "../../components/AppLoader";
import { useNavigate, useParams, useSearchParams} from "react-router-dom";
import {
    Button,
    notification,
} from "antd";
import {useGatewayViewModel} from "../../../viewmodel/Gateway";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPeopleGroup,
    faRefresh,
    faServer, faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {GatewayDataComponent} from "./components/Data";
import {toNumber} from "lodash";
import {DashboardBreadCrumb} from "../../components/DashboardBreadcrumb";
import {DashboardSubpageContainer} from "../../components/DashboardContainer";
import {DeleteBottomSheet} from "../../components/DeleteBottomSheet";

export const DashboardGatewayPage: FC = () => {

    const {
        gateway,
        onFetchGatewayStateReceived,
        fetchGateway,
        fetchGatewayState,
        deleteGateway,
        deleteState,
        onDeleteStateReceived
    } = useGatewayViewModel();
    const {id} = useParams();
    const navigate = useNavigate();

    const [fromVehicle, setShowFromVehicle] = useState<boolean>(false)
    const fetchGatewayData = useCallback(() => {
        void fetchGateway(toNumber(id));
    }, [fetchGateway]);
    const [searchParams] = useSearchParams();
    useEffect(() => {
        void fetchGateway(Number(id));
        setShowFromVehicle(searchParams.get('fromVehicle') === 'true')
    }, []);

    const [deleteEvent, setDeleteEvent] = useState(false)
    const toggleDelete = useCallback(() => {
        setDeleteEvent(old => !old)
    }, [setDeleteEvent])

    useEffect(() => {
        if (!!fetchGatewayState && !fetchGatewayState.loading) {
            if (fetchGatewayState.hasError) {
                notification.error({
                    message:
                        fetchGatewayState.error?.message || "Error al obtener el gateway.",
                });
            }
            onFetchGatewayStateReceived();
        }
    }, [fetchGatewayState]);

    useEffect(() => {
        if (!!deleteState && !deleteState.loading) {
            if (deleteState.hasError) {
                notification.error({
                    message: deleteState.error?.message,
                });
            } else {
                notification.success({
                    message: "Gateway eliminado.",
                });
                navigate("/dashboard/gateways")
            }
            onDeleteStateReceived();
        }
    }, [deleteState]);

    return (
        <DashboardSubpageContainer className={"w-full h-fit overflow-hidden"}>
            <AppLoader
                loading={(!!fetchGatewayState && fetchGatewayState.loading) || (!!deleteState && deleteState.loading)}/>
            {
                fromVehicle ? <DashboardBreadCrumb
                    items={[
                        {
                            pathname: "/dashboard/customers",
                            title: "Clientes",
                            icon: <FontAwesomeIcon icon={faPeopleGroup}/>,
                        },
                        {
                            pathname: `/dashboard/customers/${gateway?.Vehicle?.Customer.id}`,
                            title: gateway?.Vehicle?.Customer.businessName,
                        },
                        {
                            pathname: `/dashboard/customers/${gateway?.Vehicle?.Customer.id}?tab=vehicles`,
                            title: 'Vehiculos',
                        },
                        {
                            pathname: `/dashboard/vehicles/${gateway?.Vehicle?.id}?tab=gateways`,
                            title: `${gateway?.Vehicle?.name} / Gateways`,
                        },
                        {
                            active: true,
                            title: gateway?.key
                        },
                    ]}
                /> : <DashboardBreadCrumb
                    items={[
                        {
                            pathname: "/dashboard/gateways",
                            title: "Gateways",
                            icon: <FontAwesomeIcon icon={faServer}/>,
                        },
                    ]}
                />
            }

            <DashboardSubpageHeader
                title={"Gateway"}
                subtitle={`${gateway?.key}`}
                extra={
                    <Button.Group>
                        <Button
                            type={"default"}
                            icon={<FontAwesomeIcon icon={faRefresh}/>}
                            onClick={fetchGatewayData}
                        >
                            Actualizar
                        </Button>
                        <Button type={"primary"} icon={<FontAwesomeIcon icon={faTrash}/>} onClick={toggleDelete}>
                            Eliminar
                        </Button>
                    </Button.Group>
                }
            />
            {
                !!gateway && <GatewayDataComponent onUpdateEnded={fetchGatewayData} gateway={gateway}/>
            }
            <DeleteBottomSheet rowKey={"deleteGateway"} title={"Eliminar Gateway"} id={toNumber(id!!)}
                               onSubmit={deleteGateway} onClose={toggleDelete} open={deleteEvent}/>
        </DashboardSubpageContainer>
    );
};
