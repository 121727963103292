import {RemoteSourceResponse, UpdateResult} from "../../../types/Source";
import {
  NewCustomerUserData,
  CustomerUser,
  UpdateCustomerUserData,
} from "../../../types/CustomerUser";
import { apiService } from "../../../services/RemoteClient";
import { BaseRemoteSource } from "../base/Remote";
import {DeleteData} from "../../../types/App";
import {Customer} from "../../../types/Customer";

export class RemoteCustomerUserSource extends BaseRemoteSource {
  static async getUser(
    id: number
  ): Promise<RemoteSourceResponse<CustomerUser> | undefined> {
    try {
      const user = await apiService.postWithAuth("/customer_user/get", {
        id,
      });
      this.checkResponseCredentials(user);
      return user.data as RemoteSourceResponse<CustomerUser>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async getCustomerUserList(
    customerId: number
  ): Promise<RemoteSourceResponse<CustomerUser[]> | undefined> {
    try {
      const userList = await apiService.postWithAuth("/customer_user/list", {
        customerId,
      });
      this.checkResponseCredentials(userList);
      return userList.data as RemoteSourceResponse<CustomerUser[]>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async createUser(
    data: NewCustomerUserData
  ): Promise<RemoteSourceResponse | undefined> {
    try {
      const response = await apiService.postWithAuth(
        "/customer_user/create",
        data
      );
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async updateUser(data: UpdateCustomerUserData) {
    try {
      const response = await apiService.putWithAuth(
        "/customer_user/update",
        data
      );
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async deleteCustomerUser(data: DeleteData) {
    try {
      const response = await apiService.deleteWithAuth("/customer_user/delete", {
        data
      });
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse<UpdateResult<Customer>>;
    } catch (e) {
      throw this.parseError(e);
    }
  }
}
