import {FC, useCallback} from "react";
import {TabsProps} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUserGroup,
  faTruck,
  faPalette,
} from "@fortawesome/free-solid-svg-icons";
import { CustomerMainTab } from "./main/TabMain";
import { CustomerVehiclesTab } from "./vehicles/TabVehicles";
import { Customer } from "../../../../types/Customer";
import { CustomerUserListTab } from "./user_list/UserList";
import { CustomerAppearanceTab } from "./appearance/TabAppearance";
import {DashboardTabWindow} from "../../../components/DashboardTabWindow";

type Props = {
  customer: Customer;
  onUpdateEnded: ()=>void
};

const items: TabsProps["items"] = [
  {
    key: "main",
    label: (
      <span>
        <FontAwesomeIcon icon={faHome} /> Principal
      </span>
    ),
  },
  {
    key: "vehicles",
    label: (
      <span>
        <FontAwesomeIcon icon={faTruck} /> Vehículos
      </span>
    ),
  },
  {
    key: "users",
    label: (
      <span>
        <FontAwesomeIcon icon={faUserGroup} /> Usuarios
      </span>
    ),
  },
  // {
  //   key: "appearance",
  //   label: (
  //     <span>
  //       <FontAwesomeIcon icon={faPalette} /> Apariencia
  //     </span>
  //   ),
  // },

];

export const CustomerPageTabs: FC<Props> = ({ customer, onUpdateEnded }) => {
  const onSelected = useCallback((key: string) => {
    switch (key) {
      case "main":
        return <CustomerMainTab onUpdateEnded={onUpdateEnded} customer={customer} />;
      case "vehicles":
        return <CustomerVehiclesTab customer={customer} />;
      case "users":
        return <CustomerUserListTab customer={customer} />;
      case "appearance":
        return <CustomerAppearanceTab customer={customer} />;
      default:
        return null;
    }
  }, []);

  return (
    <DashboardTabWindow items={items} defaultSelectedKey={"main"} onSelected={onSelected} />
  );
};
