import { FC } from "react";
import { Checkbox, Form, Input, Modal, Select } from "antd";
import { NewGatewayDeviceFormData } from "../../../../types/GatewayDevice";
import {
  handleCheckboxEvent,
  handleTextAreaEvent,
  handleValueChange,
  useFormValidation,
} from "../../../hooks/Validation";
import * as yup from "yup";
import { Gateway } from "../../../../types/Gateway";
import { Chart } from "../../../../types/Chart";

type Props = {
  deviceList?: Array<Gateway> | null;
  chartList?: Array<Chart> | null;
  onFinish: (data: NewGatewayDeviceFormData) => void;
  onCancel: () => void;
  gateway: Gateway;
};

export const AddGatewayDeviceModal: FC<Props> = ({
  deviceList,
  onFinish,
  gateway,
  onCancel,
  chartList,
}) => {
  const { valid, formData, errors, updateData } =
    useFormValidation<NewGatewayDeviceFormData>(
      yup.object({
        deviceId: yup.number().required("Debes seleccionar un dispositivo."),
        gatewayId: yup.number().required("El gateway es requerido"),
        chartId: yup.number().required("Tipo de chart"),
        report: yup.boolean().required(""),
        historic: yup.boolean().required(),
        dashboard: yup.boolean().required(),
        reason: yup
          .string()
          .required(
            "Es necesario especificar por qué registra un nuevo dispositivo."
          )
          .min(16, "Detalle el cambio a realizar con al menos 16 caracteres."),
      }),
      {
        gatewayId: gateway.id,
        reason: "",
        dashboard: false,
        historic: false,
        report: false,
        chartId: undefined,
      }
    );

  console.log(errors);
  const onSubmit = () => {
    if (valid) onFinish(formData);
  };

  return (
    <Modal
      open={true}
      title={"Añadir dispositivo"}
      onCancel={onCancel}
      okButtonProps={{
        disabled: !valid,
      }}
      onOk={onSubmit}
    >
      <Form.Item
        label={"Seleccione un dispositivo"}
        status={errors.gatewayId ? "error" : "validating"}
        help={errors.gatewayId}
      >
        <Select
          fieldNames={{
            label: "description",
            value: "id",
          }}
          options={deviceList as any}
          title={"Dispositivo"}
          placeholder={"Seleccione un dispositivo"}
          onChange={handleValueChange(updateData, "deviceId")}
          value={formData.deviceId}
        />
      </Form.Item>
      <Form.Item
        label={"Seleccione un chart"}
        status={errors.chartId ? "error" : "validating"}
        help={errors.chartId}
      >
        <Select
          fieldNames={{
            label: "key",
            value: "id",
          }}
          options={chartList as any}
          title={"Chart"}
          placeholder={"Seleccione un chart"}
          onChange={handleValueChange(updateData, "chartId")}
          value={formData.chartId}
        />
      </Form.Item>
      <Form.Item label="Mostrar en">
        <Checkbox
          name="historic"
          value={formData.historic}
          onChange={handleCheckboxEvent(updateData)}
        >
          Histórico
        </Checkbox>
        <Checkbox
          name="report"
          value={formData.report}
          onChange={handleCheckboxEvent(updateData)}
        >
          Reportes
        </Checkbox>
        <Checkbox
          name="dashboard"
          value={formData.dashboard}
          onChange={handleCheckboxEvent(updateData)}
        >
          Dashboard
        </Checkbox>
      </Form.Item>
      <Form.Item
        label={"Detalles"}
        status={errors.reason ? "error" : "validating"}
        help={errors.reason}
      >
        <Input.TextArea
          name={"reason"}
          placeholder={"Detalle el cambio a realizar."}
          onChange={handleTextAreaEvent(updateData)}
          value={formData.reason}
        />
      </Form.Item>
    </Modal>
  );
};
