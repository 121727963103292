import { FC, useCallback, useEffect } from "react";
import { DashboardSubpageContainer } from "../../components/DashboardContainer";
import { DashboardSubpageHeader } from "../../components/DashboardHeader";
import { Button } from "antd";
import { faPlus, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { DashboardBreadCrumb } from "../../components/DashboardBreadcrumb";
import { useDashboardHeader } from "../../hooks/Header";
import { AppLoader } from "../../components/AppLoader";
import { useChartViewModel } from "../../../viewmodel/Chart";
import { Chart } from "../../../types/Chart";
import { ChartListData } from "./components/Data";

export const DashboardChartListPage: FC = () => {
  const { fetchList, chartList, fetchListState, onFetchListStateReceived } =
    useChartViewModel();
  const { setTitle } = useDashboardHeader();
  const navigate = useNavigate();
  const onNewChartClick = useCallback(() => {
    navigate("/dashboard/charts/create");
  }, [navigate]);
  const onItemClicked = useCallback(
    (record: Chart) => {
      navigate(`/dashboard/charts/${record.id}`);
    },
    [chartList, navigate]
  );

  useEffect(() => {
    setTitle("Charts");
    fetchCharts();
  }, []);

  useEffect(() => {
    if (!!fetchListState && !fetchListState?.loading) {
      if (fetchListState?.hasError) {
        toast.error(fetchListState?.error?.message);
      }
      onFetchListStateReceived();
    }
  }, [fetchListState]);

  const fetchCharts = useCallback(() => {
    void fetchList();
  }, [fetchList]);

  return (
    <DashboardSubpageContainer className={"w-full h-fit overflow-x-hidden"}>
      <AppLoader loading={!!fetchListState && fetchListState.loading} />
      <DashboardBreadCrumb
        items={[
          {
            active: true,
            title: "Charts",
          },
        ]}
      />
      <DashboardSubpageHeader
        title={"Charts"}
        extra={
          <Button.Group>
            <Button
              type={"primary"}
              icon={<FontAwesomeIcon icon={faRefresh} />}
              onClick={fetchCharts}
              size={"small"}
            >
              Actualizar
            </Button>
            <Button
              type={"dashed"}
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={onNewChartClick}
              size={"small"}
            >
              Nuevo
            </Button>
          </Button.Group>
        }
      />
      <ChartListData data={chartList} onItemClicked={onItemClicked} />
    </DashboardSubpageContainer>
  );
};
