import {
  NewCustomerFormData,
  UpdateCustomerFormData,
} from "../../types/Customer";
import { RemoteCustomerSource } from "../source/customer/Remote";
import { omit } from "lodash";
import { UpdateCustomerStyleFormData } from "../../types/CustomerStyle";
import {DeleteFormData} from "../../types/App";

export class CustomerRepository {
  static async createCustomer(data: NewCustomerFormData) {
    const activity = {
      reason: data.reason,
    };
    return RemoteCustomerSource.createCustomer({
      data: omit(data, ["reason"]),
      activity,
    });
  }

  static async getCustomer(id: number) {
    return RemoteCustomerSource.getCustomer(id);
  }

  static async getCustomerList() {
    return RemoteCustomerSource.getCustomerList();
  }

  static async updateCustomer(id: number, data: UpdateCustomerFormData) {
    const activity = {
      reason: data.reason,
    };
    return RemoteCustomerSource.updateCustomer({
      data: omit(data, ["reason"]),
      activity,
      id,
    });
  }

  static async getCustomerStyle(customerId: number) {
    return RemoteCustomerSource.getCustomerStyle(customerId);
  }

  static async updateCustomerStyle(
    customerId: number,
    style: UpdateCustomerStyleFormData,
  ) {
    const activity = {
      reason: style.reason,
    };
    const data = {
      ...style,
    };
    //@ts-ignore
    delete data.reason;
    return RemoteCustomerSource.setCustomerStyle(customerId, {
      activity,
      data,
      customerId,
    });
  }

  static async updateCustomerLogo(customerId: number, logo: File) {
    return RemoteCustomerSource.setCustomerLogo(customerId, logo);
  }

  static async deleteCustomer(data: DeleteFormData) {
    return RemoteCustomerSource.deleteCustomer({
      activity: {
        reason: data.reason
      },
      id: data.id
    })
  }
}
