import { FC, useCallback, useEffect } from "react";
import { DashboardSubpageContainer } from "../../components/DashboardContainer";
import { DashboardSubpageHeader } from "../../components/DashboardHeader";
import { Button } from "antd";
import {
  faPlus,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUserViewModel } from "../../../viewmodel/User";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { UserListData } from "./components/Data";
import { User } from "../../../types/User";
import { AppLoader } from "../../components/AppLoader";
import { DashboardBreadCrumb } from "../../components/DashboardBreadcrumb";
import {useDashboardHeader} from "../../hooks/Header";

export const DashboardUserListPage: FC = () => {
  const { fetchList, fetchListState, userList, onFetchListStateReceived } =
    useUserViewModel();
  const navigate = useNavigate();
    const { setTitle } = useDashboardHeader();


    const onNewUserClick = useCallback(() => {
    navigate("/dashboard/users/create");
  }, [navigate]);

  const onDataItemClick = useCallback(
    (item: User) => {
      navigate("/dashboard/users/" + item.id);
    },
    [navigate, userList],
  );

  useEffect(() => {
    void fetchList();
    setTitle("Usuarios")
  }, []);

  useEffect(() => {
    if (!!fetchListState && !fetchListState?.loading) {
      if (fetchListState?.hasError) {
        toast.error(fetchListState?.error?.message);
      } else {
        console.log(userList);
      }
      onFetchListStateReceived();
    }
  }, [fetchListState]);

  return (
    <DashboardSubpageContainer className={"w-full h-fit overflow-hidden"}>
      <AppLoader loading={!!fetchListState && fetchListState.loading} />
      <DashboardBreadCrumb
        items={[
          {
            active: true,
            title: "...",
          },
        ]}
      />
      <DashboardSubpageHeader
        title={"Usuarios ACME"}
        subtitle={userList?.length + " usuarios"}
        extra={
          <Button.Group>
            <Button
              type={"default"}
              icon={<FontAwesomeIcon icon={faRefresh} />}
              onClick={fetchList}
            >
              Actualizar
            </Button>
            <Button
              type={"primary"}
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={onNewUserClick}
            >
              Nuevo
            </Button>
          </Button.Group>
        }
      />
      <UserListData onItemClicked={onDataItemClick} data={userList} />
    </DashboardSubpageContainer>
  );
};
