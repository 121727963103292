import { FC } from "react";
import { FieldProps } from "formik";
import { Checkbox, ColorPicker } from "antd";

export const ColorPickerField: FC<any> = ({
  field: { value, name },
  form: { setFieldValue },
}: FieldProps<string | undefined>) => {
  return (
    <div className={"flex flex-row gap-2"}>
      <Checkbox
        checked={!!value}
        onChange={(e) => {
          if (e.target.checked) void setFieldValue(name, "#FFC107");
          else void setFieldValue(name, undefined);
        }}
      />
      <ColorPicker
        showText
        value={value}
        disabled={!value}
        onChange={(e) => {
          void setFieldValue(name, e.toHexString());
        }}
      />
    </div>
  );
};
