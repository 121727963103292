import React, { FC, useEffect, useMemo, useRef } from "react";
import { ChartData1 } from "../../../types/charts/Chart";
import {
  generateChartColors,
  generateChartTitles,
  parseChartData1ForApex,
} from "../../../utils/Charts";
import ApexCharts, { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import es from "apexcharts/dist/locales/es.json";
import { useDayNightTheme } from "../../hooks/SwitchTheme";
import { ChartSchemaDefinition } from "../../../types/Chart";

type Props = {
  tooltipTitle: string;
  items: Array<ChartData1<number>>;
  schema: ChartSchemaDefinition;
  type: "line" | "area" | "bar";
  id: string;
};

export const BaseLinearChart: FC<Props> = ({
  tooltipTitle,
  items,
  schema,
  type,
  id,
}) => {
  const colors = useMemo(() => generateChartColors(schema), [schema]);
  const titles = useMemo(() => generateChartTitles(schema), [schema]);
  const chartId = useRef<string>(id + "chart");
  const brushId = useRef<string>(id + "brush");
  const { darkMode } = useDayNightTheme();
  useEffect(() => {
    const result: ApexAxisChartSeries = parseChartData1ForApex(schema, items);
    ApexCharts.exec(chartId.current, "updateSeries", result);
    ApexCharts.exec(brushId.current, "updateSeries", result);
  }, [items]);

  const series = useMemo<ApexAxisChartSeries>(() => {
    const result: ApexAxisChartSeries = schema.properties.map((it) => {
      return {
        name: titles[it.name],
        group: it.name,
        color: colors[it.name],
        data: [],
      };
    });
    return result;
  }, [colors, titles, schema]);

  const seriesLine = useMemo<ApexAxisChartSeries>(() => {
    return [
      {
        data: [],
      },
    ];
  }, []);

  const options = useMemo<ApexOptions>(() => {
    return {
      chart: {
        id: chartId.current,
        locales: [es],
        defaultLocale: "es",
        height: 350,
        type: type,
        animations: {
          enabled: true,
          easing: "linear",
        },
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        background: darkMode ? "#424242" : "transparent",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: tooltipTitle,
        align: "center",
      },
      markers: {
        size: 0,
      },
      xaxis: {
        type: "datetime",
        labels: {
          datetimeUTC: false,
        },
      },
      theme: {
        mode: darkMode ? "dark" : "light",
      },
    };
  }, [darkMode]);

  const optionsLine = useMemo<ApexOptions>(() => {
    return {
      chart: {
        id: brushId.current,
        height: 130,
        type: type,
        brush: {
          target: chartId.current,
          enabled: true,
        },
        selection: {
          enabled: true,
        },
        background: darkMode ? "#424242" : "transparent",
      },
      legend: {
        show: false,
      },
      xaxis: {
        type: "datetime",
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        tickAmount: 2,
      },
      theme: {
        mode: darkMode ? "dark" : "light",
      },
    };
  }, [darkMode]);

  return (
    <div className={"flex flex-col gap-2"}>
      <ReactApexChart
        options={options}
        series={series}
        type={type}
        height={350}
      />
      <ReactApexChart
        options={optionsLine}
        series={seriesLine}
        type={type}
        height={130}
      />
    </div>
  );
};
