import {FC, MouseEventHandler} from "react";
import { Typography} from "antd";
import {fastFormatDate} from "../../../../../../utils/Dates";
import {GatewayLocation} from "../../../../../../types/DeviceData";

type CardProps = {
    selected: boolean;
    location: GatewayLocation;
    index: number;
    onClick: MouseEventHandler<HTMLDivElement>;
};

export const GatewayLocationCard: FC<CardProps> = ({
                                                       selected,
                                                       location,
                                                       index,
                                                       onClick,
                                                   }) => {
    return (
        <div
            className={`rounded-lg p-2 flex flex-col ${selected ? 'bg-secondary text-white' : 'bg-white'}`}
            data-index={index}
            onClick={onClick}
        >
            <Typography.Text type={"secondary"} keyboard style={{
                color: selected ? 'white' : "inherit"
            }}>
                {fastFormatDate(location.date, "DD/MM/yyyy hh:mm")}
            </Typography.Text>
            <Typography.Text style={{
                color: selected ? 'white' : "inherit"
            }} type={"secondary"} className={`${selected} ? 'text-neutral-200' : ''`}>
                {location.latitude} / {location.longitude}
            </Typography.Text>
        </div>
    );
};
