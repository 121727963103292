import { RemoteCustomerUserSource } from "../source/customer_user/Remote";
import {
  NewCustomerUserFormData,
  UpdateCustomerUserFormData,
} from "../../types/CustomerUser";
import { omit } from "lodash";
import {DeleteFormData} from "../../types/App";

export class CustomerUserRepository {
  static async getUserList(customerId: number) {
    return await RemoteCustomerUserSource.getCustomerUserList(customerId);
  }

  static async getUser(id: number) {
    return await RemoteCustomerUserSource.getUser(id);
  }

  static async updateUser(id: number, data: UpdateCustomerUserFormData) {
    const activity = {
      reason: data.reason,
    };
    return await RemoteCustomerUserSource.updateUser({
      activity,
      id,
      data: omit(data, ["reason"]),
    });
  }
  static async createUser(data: NewCustomerUserFormData) {
    return await RemoteCustomerUserSource.createUser({
      data: {
        name: data.name,
        birthDate: data.birthDate,
        dni: data.dni,
        email: data.email,
        corporateEmail: data.corporateEmail,
        lastname: data.lastname,
        password: data.password,
        roleId: data.roleId,
        username: data.username,
        customerId: data.customerId,
      },
      activity: {
        reason: data.reason,
      },
    });
  }

  static async deleteCustomerUser(data: DeleteFormData) {
    return RemoteCustomerUserSource.deleteCustomerUser({
      activity: {
        reason: data.reason
      },
      id: data.id
    })
  }
}
