import * as Nominatim from "nominatim-browser";
import { GatewayLocation } from "../../types/DeviceData";

export class LocationRepository {
  static async getAddress(location: GatewayLocation) {
    try {
      return await Nominatim.reverseGeocode({
        lat: location.latitude.toString(),
        lon: location.longitude.toString(),
        addressdetails: true,
      });
    } catch (e) {
      return null;
    }
  }
}
