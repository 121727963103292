import { Vehicle } from "../../../../../types/Vehicle";
import {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Card, notification, Radio as AntdRadio, Spin, Typography } from "antd";
import { DashboardSubpageContainer } from "../../../../components/DashboardContainer";
import { GatewayLocation } from "../../../../../types/DeviceData";
import { socketOff, socketOn } from "../../../../../services/SocketIO";
import { Gateway } from "../../../../../types/Gateway";
import { useGatewayViewModel } from "../../../../../viewmodel/Gateway";
import { MapContainer, TileLayer } from "react-leaflet";
import { Map, Marker, Icon } from "leaflet";
import { useLocationViewModel } from "../../../../../viewmodel/Location";
import { FullscreenToggle } from "../../../../components/Fullscreen";
import { fastFormatDate } from "../../../../../utils/Dates";

type Props = {
  vehicle: Vehicle;
};

export const VehicleTrackingTab: FC<Props> = ({ vehicle }) => {
  const {
    fetchAddress,
    fetchAddressState,
    onFetchAddressStateReceived,
    address,
  } = useLocationViewModel();
  const [selectedGw, setSelectedGw] = useState<Gateway | null>(null);
  const [currentLocation, setCurrentLocation] =
    useState<GatewayLocation | null>(null);
  const map = useRef<Map>(null);
  const lastAddressRef = useRef<number>(0) as MutableRefObject<number>
  const marker = useRef<Marker>(null) as MutableRefObject<Marker>;
  const {
    fetchVehicleGateway,
    fetchGatewayState,
    onFetchGatewayStateReceived,
    vehicleGateway,
  } = useGatewayViewModel();

  useEffect(() => {
    void fetchVehicleGateway(vehicle.id);
  }, []);

  const listener = useCallback(
    (data: any) => {
      const current = data as GatewayLocation;
      const newPos = {
        lat: current.latitude,
        lng: current.longitude,
      };
      if((Date.now() - lastAddressRef.current) > 5000) {
        void fetchAddress(current);
        lastAddressRef.current = Date.now()
      }
      setCurrentLocation(current);
      if (map.current) {
        if (marker.current) {
          map.current.removeLayer(marker.current);
        }
        marker.current = new Marker(newPos, {
          icon: new Icon({
            iconUrl: `${process.env.PUBLIC_URL}/marker.png`,
            iconSize: [22, 32],
            iconAnchor: [16, 32],
          }),
        });
        map.current.addLayer(marker.current);
        map.current.setView(newPos, 20);
      }
    },
    [setCurrentLocation, map, lastAddressRef],
  );

  useEffect(() => {
    if (!selectedGw) return;
    const key = `data/${selectedGw.key}/gps_live`;
    socketOn(key, listener);
    return () => {
      socketOff(key, listener);
    };
  }, [selectedGw]);

  useEffect(() => {
    if (!!fetchGatewayState && !fetchGatewayState.loading) {
      if (fetchGatewayState.hasError) {
        notification.error({
          message: "Error al obtener el gateway.",
          description: fetchGatewayState.error?.message,
        });
      } else {
        if(!!vehicleGateway && vehicleGateway.length>0) {
          setSelectedGw(vehicleGateway[0])
        }
      }
      onFetchGatewayStateReceived();
    }
  }, [fetchGatewayState]);

  useEffect(() => {
    if (!!fetchAddressState && !fetchAddressState.loading) {
      if (fetchAddressState.hasError) {
        notification.error({
          message: "Error al obtener la dirección.",
          description: fetchAddressState.error?.message,
        });
      } else {
        console.log(address);
      }
      onFetchAddressStateReceived();
    }
  }, [fetchAddressState]);

  const mapContainerRef = useRef<HTMLDivElement>(null);

  return (
    <DashboardSubpageContainer className={"w-full h-fit overflow-y-auto"}>
      <Typography.Text type={"secondary"} className={"block mb-2"}>
        Selecciona un Gateway
      </Typography.Text>
      <AntdRadio.Group
        className={"mb-4"}
        onChange={(selection) => {
          setSelectedGw(
            vehicleGateway?.filter(
              (it) => it.key === selection.target.value,
            )[0] || null,
          );
        }}
        value={selectedGw?.key}
      >
        {vehicleGateway?.map((it, index) => {
          return (
            <AntdRadio key={`gw-${it.key}`} value={it.key}>
              {it.key}
            </AntdRadio>
          );
        })}
      </AntdRadio.Group>
      <div className={"w-full h-fit relative"} ref={mapContainerRef}>
        <div
          className={
            "absolute right-0 top-0 p-4 z-50 flex flex-col gap-2 justify-end content-end items-end"
          }
        >
          <FullscreenToggle containerRef={mapContainerRef} />
          {currentLocation && (
            <Card>
              <div className={"flex flex-col"}>
                <Typography.Text type={"secondary"} strong>
                  Ubicación actual
                </Typography.Text>
                {!!fetchAddressState && fetchAddressState.loading ? (
                  <Spin />
                ) : address ? (
                  <Typography.Text className={"flex flex-row gap-1 flex-wrap"}>
                    <span>{address?.address.road}</span>
                    <span> {address?.address.suburb}</span>
                    <span>{address?.address.region}</span>
                    <span>{address?.address.country}</span>
                  </Typography.Text>
                ) : (
                  <Typography.Text type={"warning"}>
                    No es posible obtener la dirección
                  </Typography.Text>
                )}
                <Typography.Text type={"secondary"}>
                  Actualizado {fastFormatDate(currentLocation.date, "hh:mm:ss")}
                </Typography.Text>
              </div>
            </Card>
          )}
        </div>
        <MapContainer
          className={"w-full min-h-screen z-10"}
          zoom={13}
          scrollWheelZoom={false}
          ref={map}
          center={[-12.1700352, -76.9589248]}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </MapContainer>
      </div>
    </DashboardSubpageContainer>
  );
};
