import { FC, useCallback } from "react";
import { SubmitButton, Form, Input, FormItem } from "formik-antd";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faICursor, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { Formik, FormikHelpers } from "formik";
import { FormikActivityReasonField } from "../../../components/FormikActivityReasonField";
import { NewRoleFormData } from "../../../../types/Role";

type Props = {
  onFinish: (data: NewRoleFormData) => Promise<boolean | undefined>;
  initialValues?: Partial<NewRoleFormData>;
};

const kNEW_ROLE_VALIDATION = yup.object({
  label: yup.string().min(3, "Ingrese un nombre válido."),
  description: yup.string().min(3, "Ingrese una descripción válida."),
  reason: yup
    .string()
    .required("Especifique el motivo porque registra el nuevo vehículo.")
    .min(16, "Debe contener al menos 16 caracteres."),
});

export const UserRoleCreateForm: FC<Props> = ({ onFinish, initialValues }) => {
  const onFormSubmit = useCallback(
    (data: NewRoleFormData, helpers: FormikHelpers<NewRoleFormData>) => {
      onFinish(data).then((res) => {
        helpers.setSubmitting(false);
        if (res) helpers.resetForm();
      });
    },
    [onFinish]
  );

  return (
    <Formik<NewRoleFormData>
      initialValues={{} as NewRoleFormData}
      enableReinitialize
      onSubmit={onFormSubmit}
      validationSchema={kNEW_ROLE_VALIDATION}
      validateOnMount={true}
    >
      <Form layout={"vertical"}>
        <FormItem
          name={"label"}
          label={"Nombre"}
          tooltip={"Será utilizado como un identificador del rol de usuario."}
          required
        >
          <Input
            name={"label"}
            allowClear
            prefix={<FontAwesomeIcon icon={faICursor} />}
          />
        </FormItem>

        <FormItem
          label={"Descripción"}
          tooltip={
            "Puede detallar una breve descripción del vehículo o su finalidad."
          }
          name={"description"}
        >
          <Input.TextArea
            autoComplete={"off"}
            allowClear
            name={"description"}
          />
        </FormItem>

        <FormikActivityReasonField />
        <SubmitButton icon={<FontAwesomeIcon icon={faPaperPlane} />}>
          Registrar
        </SubmitButton>
      </Form>
    </Formik>
  );
};
