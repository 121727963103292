import { useState } from "react";
import { AppState, TaskState } from "../data/domain/State";
import { useLoaderData } from "react-router-dom";
import { Chart, NewChartFormData, UpdateChartFormData } from "../types/Chart";
import { ChartRepository } from "../data/repository/Chart";
import { DeleteFormData } from "../types/App";

export function useChartViewModel() {
  const initialChart = useLoaderData() as Chart | null;
  const [fetchListState, setFetchListState] =
    useState<AppState<boolean> | null>(null);
  const [chartList, setChartList] = useState<Chart[] | null>(null);
  const [createChartState, setCreateChartState] =
    useState<AppState<boolean> | null>(null);
  const [updateChartState, setUpdateChartState] =
    useState<AppState<boolean> | null>(null);
  const [fetchChartState, setFetchChartState] =
    useState<AppState<boolean> | null>(null);
  const [chart, setChart] = useState<Chart | null>(initialChart);
  const [deleteState, setDeleteState] = useState<AppState<boolean> | null>();
  async function fetchList() {
    if (fetchListState?.loading) return;
    setFetchListState(TaskState.loading());
    try {
      const list = await ChartRepository.getChartList();
      if (list.ok) {
        setChartList(list.data!!);
        setFetchListState(TaskState.success(true));
      } else setFetchListState(TaskState.error(new Error(list.message!!)));
    } catch (error: any) {
      setFetchListState(TaskState.error(error));
    }
  }

  function onFetchListStateReceived() {
    setFetchListState(null);
  }

  async function createChart(chart: NewChartFormData) {
    if (createChartState?.loading) return;
    setCreateChartState(TaskState.loading());
    try {
      const newChart = await ChartRepository.createChart(chart);
      if (newChart.ok) {
        setCreateChartState(TaskState.success(true));
        return true;
      } else {
        setCreateChartState(TaskState.error(new Error(newChart.message!!)));
      }
    } catch (error: any) {
      setCreateChartState(TaskState.error(error));
    }
  }

  function onCreateChartStateReceived() {
    setCreateChartState(null);
  }

  async function updateChart(data: UpdateChartFormData) {
    if (updateChartState?.loading) return;
    setUpdateChartState(TaskState.loading());
    if (!chart)
      setUpdateChartState(TaskState.error(new Error("No chart data")));
    try {
      const updateChart = await ChartRepository.updateChart(chart!!.id, data);
      if (updateChart.ok) {
        setUpdateChartState(TaskState.success(true));
        return true;
      } else {
        setUpdateChartState(TaskState.error(new Error(updateChart.message!!)));
      }
    } catch (e: any) {
      setUpdateChartState(TaskState.error(e));
    }
  }

  function onUpdateChartStateReceived() {
    setUpdateChartState(null);
  }

  async function fetchChart(id: number) {
    if (fetchChartState?.loading) return;
    setFetchChartState(TaskState.loading());
    try {
      const chart = await ChartRepository.getChart(id);
      if (chart.ok) {
        setFetchChartState(TaskState.success(true));
        setChart(chart.data!!);
      } else {
        setFetchChartState(TaskState.error(new Error(chart.message!!)));
      }
    } catch (e: any) {
      setFetchChartState(TaskState.error(e));
    }
  }

  function onFetchChartStateReceived() {
    setFetchChartState(null);
  }

  async function deleteChart(data: DeleteFormData) {
    if (deleteState?.loading) return;
    setDeleteState(TaskState.loading());
    try {
      await ChartRepository.deleteChart(data);
      setDeleteState(TaskState.success(true));
      return true;
    } catch (e: any) {
      setDeleteState(TaskState.error(e));
    }
  }

  function onDeleteStateReceived() {
    setDeleteState(null);
  }

  return {
    fetchListState,
    chartList,
    fetchList,
    onFetchListStateReceived,
    createChartState,
    createChart,
    onCreateChartStateReceived,
    updateChartState,
    updateChart,
    onUpdateChartStateReceived,
    fetchChartState,
    fetchChart,
    onFetchChartStateReceived,
    chart,
    deleteState,
    deleteChart,
    onDeleteStateReceived,
  };
}
