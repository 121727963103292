import { RemoteSourceResponse, UpdateResult } from "../../../types/Source";
import { apiService } from "../../../services/RemoteClient";
import { BaseRemoteSource } from "../base/Remote";
import { Customer, NewCustomer, UpdateCustomer } from "../../../types/Customer";
import {
  CustomerStyle,
  UpdateCustomerStyle,
} from "../../../types/CustomerStyle";
import {DeleteData} from "../../../types/App";

export class RemoteCustomerSource extends BaseRemoteSource {
  static async createCustomer(
    data: NewCustomer,
  ): Promise<RemoteSourceResponse<Customer>> {
    try {
      const response = await apiService.postWithAuth("/customer/create", data);
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse<Customer>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async getCustomer(
    id: number,
  ): Promise<RemoteSourceResponse<Customer>> {
    try {
      const customer = await apiService.postWithAuth("/customer/get", { id });
      this.checkResponseCredentials(customer);
      return customer.data as RemoteSourceResponse<Customer>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async getCustomerList(): Promise<
    RemoteSourceResponse<Array<Customer>>
  > {
    try {
      const customerList = await apiService.getWithAuth("/customer/list");
      this.checkResponseCredentials(customerList);
      return customerList.data as RemoteSourceResponse<Array<Customer>>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async updateCustomer(data: UpdateCustomer) {
    try {
      const response = await apiService.putWithAuth("/customer/update", data);
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse<UpdateResult<Customer>>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async deleteCustomer(data: DeleteData) {
    try {
      const response = await apiService.deleteWithAuth("/customer/delete", {
        data
      });
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async getCustomerStyle(customerId: number) {
    try {
      const response = await apiService.postWithAuth("/customer_style/get", {
        customerId,
      });
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse<CustomerStyle>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async setCustomerStyle(customerId: number, data: UpdateCustomerStyle) {
    try {
      const response = await apiService.putWithAuth(
        "/customer_style/update",
        data,
      );
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse<UpdateResult<CustomerStyle>>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async setCustomerLogo(customerId: number, logo: File) {
    try {
      const formData = new FormData();
      formData.append("photo", logo);
      const response = await apiService.putWithAuth(
        `/customer_style/${customerId}/logo`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse
    } catch (e) {
      throw this.parseError(e);
    }
  }
}
