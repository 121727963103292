import { FC, useCallback } from "react";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapPin,
  faBuilding,
  faMapMarkerAlt,
  faLink,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { NewCustomerFormData } from "../../../../types/Customer";
import { rucValidator } from "../../../../utils/Validation";
import { Formik, FormikHelpers } from "formik";
import { Form, FormItem, Input, SubmitButton } from "formik-antd";
import { Typography } from "antd";
import { FormikActivityReasonField } from "../../../components/FormikActivityReasonField";

const kNEW_CUSTOMER_SCHEMA = yup.object({
  businessName: yup
    .string()
    .required("Es necesario espeficiar la identidad comercial del cliente"),
  ruc: rucValidator(
    yup.string().required("Es necesario ingresar el RUC de la empresa."),
  ),
  address: yup
    .string()
    .required("Es necesario espeficiar la dirección del cliente"),
  reason: yup.string().required().min(16),
});

type Props = {
  onFinish: (data: NewCustomerFormData) => Promise<boolean | undefined>;
};
export const CustomerCreateForm: FC<Props> = ({ onFinish }) => {
  const onSubmit = useCallback(
    (
      values: NewCustomerFormData,
      helpers: FormikHelpers<NewCustomerFormData>,
    ) => {
      helpers.setSubmitting(true);
      onFinish(values).then((res) => {
        if (res) helpers.resetForm();
        helpers.setSubmitting(false);
      });
    },
    [onFinish],
  );

  return (
    <Formik<NewCustomerFormData>
      validationSchema={kNEW_CUSTOMER_SCHEMA}
      initialValues={{} as NewCustomerFormData}
      onSubmit={onSubmit}
      validateOnMount
      validateOnBlur
      validateOnChange={false}
    >
      <Form>
        <FormItem
          required
          label={"Razón Social"}
          name={"businessName"}
          tooltip={"Identidad comercial del cliente"}
        >
          <Input
            required
            allowClear
            name={"businessName"}
            prefix={<FontAwesomeIcon icon={faBuilding} />}
          />
        </FormItem>

        <FormItem
          required
          label={"RUC"}
          name={"ruc"}
          tooltip={"Ej. 20123456789"}
        >
          <Input
            required
            allowClear
            name={"ruc"}
            prefix={<FontAwesomeIcon icon={faBuilding} />}
          />
        </FormItem>

        <FormItem required name={"address"} label={"Dirección"}>
          <Input
            required
            name={"address"}
            allowClear
            prefix={<FontAwesomeIcon icon={faMapPin} />}
          />
        </FormItem>

        <FormItem name={"postalCode"} label={"Código postal"}>
          <Input
            name={"postalCode"}
            allowClear
            prefix={<FontAwesomeIcon icon={faMapMarkerAlt} />}
          />
        </FormItem>

        <FormItem
          name={"subdomain"}
          className={"w-fit"}
          label={"Subdominio"}
          tooltip={
            "(Opcional) Indicar en caso el cliente desea un dominio personalizado. Ej: cliente.acmecia.com"
          }
        >
          <Input
            className={"w-fit"}
            suffix={
              <Typography.Text type={"secondary"}>.acmecia.com</Typography.Text>
            }
            name={"subdomain"}
            allowClear
            prefix={<FontAwesomeIcon icon={faLink} />}
          />
        </FormItem>

        <FormikActivityReasonField />
        <SubmitButton icon={<FontAwesomeIcon icon={faPaperPlane} />}>
          Registrar
        </SubmitButton>
      </Form>
    </Formik>
  );
};
