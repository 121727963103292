import { toNumber } from "lodash";

export const formatNumber = (number: number) => {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + "M";
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + "K";
  } else {
    return number.toString();
  }
};

export async function testAsNumber(
  value: string | undefined | null,
): Promise<number> {
  return new Promise((resolve, reject) => {
    if (!!value) {
      let result: number | undefined;
      try {
        result = toNumber(value);
        return resolve(result);
      } catch (e) {}
    }
    return reject("Invalid number!");
  });
}
