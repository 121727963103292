import { RemoteSourceResponse, UpdateResult } from "../../../types/Source";
import { apiService } from "../../../services/RemoteClient";
import { BaseRemoteSource } from "../base/Remote";
import { Device, NewDevice, UpdateDevice } from "../../../types/Device";
import { DeleteData } from "../../../types/App";

export class RemoteDeviceSource extends BaseRemoteSource {
  static async getDevice(id: number): Promise<RemoteSourceResponse<Device>> {
    try {
      const device = await apiService.postWithAuth("/device/get", { id });
      this.checkResponseCredentials(device);
      return device.data as RemoteSourceResponse<Device>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async getAllDevices(): Promise<RemoteSourceResponse<Device[]>> {
    try {
      const deviceList = await apiService.postWithAuth("/device/list");
      this.checkResponseCredentials(deviceList);
      return deviceList.data as RemoteSourceResponse<Device[]>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async createDevice(
    data: NewDevice
  ): Promise<RemoteSourceResponse<Device>> {
    try {
      const response = await apiService.postWithAuth("/device/create", data);
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse<Device>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async updateDevice(
    updateData: UpdateDevice
  ): Promise<RemoteSourceResponse<UpdateResult<Device>>> {
    try {
      const response = await apiService.putWithAuth(
        "/device/update",
        updateData
      );
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse<UpdateResult<Device>>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async deleteDevice(data: DeleteData) {
    try {
      const response = await apiService.deleteWithAuth("/device/delete", {
        data,
      });
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse;
    } catch (e) {
      throw this.parseError(e);
    }
  }
}
