import { omit } from "lodash";
import { RemoteGatewayDeviceSource } from "../source/gateway_device/Remote";
import {
  GatewayDeviceSelection,
  NewGatewayDeviceFormData,
} from "../../types/GatewayDevice";
import { DeleteFormData } from "../../types/App";

export class GatewayDeviceRepository {
  static async getGatewayDevices(
    gatewayId: number,
    selection?: GatewayDeviceSelection
  ) {
    return await RemoteGatewayDeviceSource.getGatewayDevices(
      gatewayId,
      selection
    );
  }

  static async createGatewayDevice(data: NewGatewayDeviceFormData) {
    const activity = {
      reason: data.reason,
    };
    return await RemoteGatewayDeviceSource.createGatewayDevice({
      activity,
      data: omit(data, ["reason"]),
    });
  }

  static async deleteGatewayDevice(data: DeleteFormData) {
    return RemoteGatewayDeviceSource.deleteGatewayDevice({
      activity: {
        reason: data.reason,
      },
      id: data.id,
    });
  }
}
