import {UpdateVehicleFormData, Vehicle} from "../../../../types/Vehicle";
import {FC, useCallback, useEffect, useState} from "react";
import {notification, Typography} from "antd";
import {
  DescriptionsItem,
  DescriptionsList,
} from "../../../components/DescriptionsList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTag,
  faHashtag,
  faBuilding, faIdCard, faToggleOn,
} from "@fortawesome/free-solid-svg-icons";
import { fastFormatDate } from "../../../../utils/Dates";
import { CustomNavigator } from "../../../components/CustomLink";
import {DashboardSubpageContainer} from "../../../components/DashboardContainer";
import {useVehicleViewModel} from "../../../../viewmodel/Vehicle";
import {NewUserFormData} from "../../../../types/User";
import {UpdateBottomSheet, UpdateBottomSheetFormItemType} from "../../../components/UpdateBottomSheet";
import * as yup from "yup";
import {AppLoader} from "../../../components/AppLoader";

type Props = {
  vehicle: Vehicle;
  onUpdateEnded: ()=>void
};

const kUPDATE_VEHICLE_ITEMS : UpdateBottomSheetFormItemType[] = [{
  name: "name",
  label: "Nombre",
  type: "string",
  renderProps: {
    placeholder: "Ingresar Nombre",
    prefix: <FontAwesomeIcon icon={faTag} />
  }
}, {
  name: "plate",
  label: "Placa",
  type: "string",
  renderProps: {
    placeholder: "Ingresar placa",
    prefix: <FontAwesomeIcon icon={faIdCard} />
  }
}, {
  name: "brand",
  label: "Marca",
  type: "string",
  renderProps: {
    placeholder: "Ingresar marca",
    prefix: <FontAwesomeIcon icon={faTag} />
  }
},
  {
    name: "model",
    label: "Modelo",
    type: "string",
    renderProps: {
      placeholder: "Ingresar modelo",
      prefix: <FontAwesomeIcon icon={faTag} />
    }
  },

  {
    name: "description",
    label: "Descripción",
    type: "textarea",
    renderProps: {
      placeholder: "Ingresar descripción",
    }
  },
  {
  name: "enabled",
  label: "Vehículo habilitado",
  type: "switch",
}]

const kUPDATE_VEHICLE_SCHEMA = yup.object({
  name: yup.string(),
  plate: yup
      .string(),
  brand: yup.string(),
  model: yup.string(),
  description: yup.string(),
  enabled: yup.boolean(),
  reason: yup.string().required("Detalle la descripción de esta actividad: ")
})

export const VehicleMainTab: FC<Props> = ({ vehicle, onUpdateEnded }) => {
  const {updateVehicle, updateVehicleState, onUpdateVehicleStateReceived} = useVehicleViewModel()
  const [activeEditableItem, setActiveEditableItem] = useState<string[] | undefined>()
  const [initialValues, setInitialValues] = useState<Partial<UpdateVehicleFormData>>({})
  const onEditClicked = useCallback((name: string) => {
    if(name === "enabled") {
      setInitialValues({
        enabled: vehicle?.enabled
      })
    } else {
      setInitialValues({})
    }
    setActiveEditableItem([name])
  }, [setActiveEditableItem, setInitialValues, vehicle]);
  useEffect(() => {
    if (!!updateVehicleState && !updateVehicleState.loading) {
      if (updateVehicleState.hasError) {
        notification.error({
          message: updateVehicleState.error?.message,
        });
      } else {
        notification.success({
          message: "Información actualizada."
        })
        onUpdateEnded()
      }
      onUpdateVehicleStateReceived();
    }
  }, [updateVehicleState]);
  const updateVehicleNow = useCallback(async (data: UpdateVehicleFormData)=>{
    return updateVehicle(vehicle.id, data)
  }, [vehicle])
  return (
    <DashboardSubpageContainer>
      <UpdateBottomSheet<UpdateVehicleFormData> defaultValues={initialValues} validationSchema={kUPDATE_VEHICLE_SCHEMA} rowKey={"vehicle"} title={"Actualizar Información"} items={kUPDATE_VEHICLE_ITEMS} onSubmit={updateVehicleNow} open={!!activeEditableItem} activeItems={activeEditableItem} onClose={()=>setActiveEditableItem(undefined)} />
      <AppLoader loading={(!!updateVehicleState && updateVehicleState.loading)} />
      <DescriptionsList rowKey={"vehicle"} onEditClicked={onEditClicked}>
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faTag} />}
          title={"Nombre"}
          label={vehicle.name}
          editable
          itemKey={"name"}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faHashtag} />}
          title={"ID"}
          label={vehicle.id}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faTag} />}
          title={"Marca"}
          label={vehicle.brand}
          editable
          itemKey={"brand"}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faTag} />}
          title={"Modelo"}
          label={vehicle.model}
          editable
          itemKey={"model"}
        />
        <DescriptionsItem
            icon={<FontAwesomeIcon icon={faIdCard} />}
            title={"Placa"}
            label={vehicle.plate}
            editable
            itemKey={"plate"}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faTag} />}
          title={"Fecha registro"}
          label={fastFormatDate(vehicle.createdAt, "dd 'de' MMMM yyyy")}
        />
        <DescriptionsItem
            icon={<FontAwesomeIcon icon={faTag} />}
            title={"Descripción"}
            label={vehicle.description}
            editable
            itemKey={"description"}
        />
        <DescriptionsItem
            icon={<FontAwesomeIcon icon={faToggleOn} />}
            title={"Estado"}
            label={vehicle?.enabled ? "Vehículo habilitado" : "Vehículo deshabilitado"}
            editable={true}
            itemKey={"enabled"}
        />
        <CustomNavigator to={`/dashboard/customers/${vehicle.Customer.id}`}>
          <DescriptionsItem
            icon={<FontAwesomeIcon icon={faBuilding} />}
            title={"Cliente"}
            content={
              <Typography.Link>{vehicle.Customer.businessName}</Typography.Link>
            }
          />
        </CustomNavigator>
      </DescriptionsList>
    </DashboardSubpageContainer>
  );
};
