import { FC } from "react";
import { DashboardSubpageHeader } from "../../../components/DashboardHeader";
import { Button } from "antd";
import { fastFormatDate } from "../../../../utils/Dates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
type Props = {
  syncDate?: Date;
  onRefreshClicked: () => void;
};
export const DashboardOverviewHeader: FC<Props> = ({
  syncDate,
  onRefreshClicked,
}) => {
  return (
    <DashboardSubpageHeader
      title={"Resúmen"}
      subtitle={
        syncDate
          ? `Actualizado a las ${fastFormatDate(syncDate, "HH:mm:ss")}`
          : "Sin actualizar"
      }
      extra={
        <Button
          type={"default"}
          icon={<FontAwesomeIcon icon={faRefresh} />}
          onClick={onRefreshClicked}
        >
          Actualizar
        </Button>
      }
    />
  );
};
