import { ErrorMessage, ErrorMessageProps } from "formik";
import { Typography } from "antd";
import React from "react";

export const CustomErrorMessage = (props: ErrorMessageProps) => (
  <ErrorMessage {...props}>
    {(errorMessage) => {
      return <Typography.Text type={"danger"}>{errorMessage}</Typography.Text>;
    }}
  </ErrorMessage>
);
