import { FC, PropsWithChildren, useCallback } from "react";
import { To, useNavigate } from "react-router-dom";

export const CustomNavigator: FC<
  PropsWithChildren<{
    to: To;
  }>
> = ({ to, children }) => {
  const navigate = useNavigate();
  const onNavigate = useCallback(() => {
    navigate(to);
  }, [to]);

  return (
    <div onClick={onNavigate} className={"w-fit h-fit cursor-pointer"}>
      {children}
    </div>
  );
};
