import { FC, useCallback, useEffect, useState } from "react";
import { DashboardSubpageHeader } from "../../components/DashboardHeader";
import { DashboardStateContainer } from "../../components/DashboardStateContainer";
import { AppLoader } from "../../components/AppLoader";
import { useNavigate, useParams } from "react-router-dom";
import { Button, notification, Typography } from "antd";
import { DashboardBreadCrumb } from "../../components/DashboardBreadcrumb";
import * as Yup from "yup";
import {
  UpdateBottomSheet,
  UpdateBottomSheetFormItemType,
} from "../../components/UpdateBottomSheet";
import { useDashboardHeader } from "../../hooks/Header";
import {
  DescriptionsItem,
  DescriptionsList,
} from "../../components/DescriptionsList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faHashtag,
  faTag,
  faTextSlash,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { fastFormatDate } from "../../../utils/Dates";
import { toNumber } from "lodash";
import { GraphicPrerenderer } from "./components/GraphicPrerenderer";
import { kCHART_SCHEMA_SCHEMA } from "./components/FormikChartSchemaEditor";
import { DeleteBottomSheet } from "../../components/DeleteBottomSheet";
import { useChartViewModel } from "../../../viewmodel/Chart";
import { UpdateChartFormData } from "../../../types/Chart";

const kCHART_UPDATE_SCHEMA = Yup.object({
  reason: Yup.string()
    .required(
      "Se requiere información sobre el reciente cambio realizado en la plataforma."
    )
    .min(16, "Se requieren al menos 16 caracteres."),
  description: Yup.string(),
  schema: kCHART_SCHEMA_SCHEMA.default(null).nullable().notRequired(),
});

const kCHART_UPDATE_ITEMS: UpdateBottomSheetFormItemType[] = [
  {
    name: "schema",
    label: "Gráficos",
    type: "string",
    renderProps: {
      placeholder: "Ingresar esquema de gráficos",
    },
  },
  {
    name: "description",
    label: "Descripción",
    type: "textarea",
    renderProps: {
      placeholder: "Ingresar descripción",
    },
  },
];

export const DashboardChartUpdatePage: FC = () => {
  const { setTitle } = useDashboardHeader();
  const {
    chart,
    updateChart,
    updateChartState,
    onUpdateChartStateReceived,
    onFetchChartStateReceived,
    fetchChart,
    fetchChartState,
    deleteChart,
    deleteState,
    onDeleteStateReceived,
  } = useChartViewModel();
  const { id } = useParams();
  const navigate = useNavigate();

  const [deleteEvent, setDeleteEvent] = useState(false);
  const toggleDelete = useCallback(() => {
    setDeleteEvent((old) => !old);
  }, [setDeleteEvent]);

  useEffect(() => {
    setTitle("Chart");
    fetchChartData();
  }, []);

  const fetchChartData = useCallback(() => {
    void fetchChart(toNumber(id));
  }, [fetchChart]);

  useEffect(() => {
    if (!!fetchChartState && !fetchChartState.loading) {
      if (fetchChartState.hasError) {
        notification.error({
          message:
            fetchChartState.error?.message || "Error al obtener el chart.",
        });
      }
      onFetchChartStateReceived();
    }
  }, [fetchChartState]);

  useEffect(() => {
    if (!!updateChartState && !updateChartState.loading) {
      if (updateChartState.hasError) {
        notification.error({
          message:
            updateChartState.error?.message || "Error al actualizar el chart.",
        });
      } else {
        notification.success({
          message: "Chart actualizado con éxito",
        });
        fetchChartData();
      }
      onUpdateChartStateReceived();
    }
  }, [updateChartState]);

  useEffect(() => {
    if (!!deleteState && !deleteState.loading) {
      if (deleteState.hasError) {
        notification.error({
          message: deleteState.error?.message,
        });
      } else {
        notification.success({
          message: "Chart eliminado.",
        });
        navigate(-1);
      }
      onDeleteStateReceived();
    }
  }, [deleteState]);

  const [activeEditableItem, setActiveEditableItem] = useState<
    string[] | undefined
  >();

  const onEditClicked = useCallback(
    (name: string) => {
      setActiveEditableItem([name]);
    },
    [setActiveEditableItem]
  );

  const [editSchema, setEditSchema] = useState(false);

  return (
    <DashboardStateContainer
      state={fetchChartState}
      className={"w-full h-fit overflow-x-hidden"}
    >
      <UpdateBottomSheet<UpdateChartFormData>
        defaultValues={{}}
        validationSchema={kCHART_UPDATE_SCHEMA}
        rowKey={"chart"}
        title={"Actualizar Información"}
        items={kCHART_UPDATE_ITEMS}
        onSubmit={updateChart}
        open={!!activeEditableItem}
        activeItems={activeEditableItem}
        onClose={() => setActiveEditableItem(undefined)}
      />
      <UpdateBottomSheet<UpdateChartFormData>
        defaultValues={{
          schema: !!chart?.schema ? chart.schema : ({} as any),
        }}
        size={"large"}
        validationSchema={kCHART_UPDATE_SCHEMA}
        rowKey={"schema"}
        title={"Actualizar Gráficos"}
        items={[
          {
            label: "Gráficos",
            type: "graphic",
            name: "schema",
          },
        ]}
        onSubmit={updateChart}
        open={!!editSchema}
        activeItems={["schema"]}
        onClose={() => setEditSchema(false)}
        placement={"bottom"}
      />
      <DashboardBreadCrumb
        items={[
          {
            pathname: "/dashboard/charts",
            title: "Charts",
          },
          {
            title: chart?.key,
            active: true,
          },
        ]}
      />
      <AppLoader
        loading={
          (!!fetchChartState && fetchChartState.loading) ||
          (!!updateChartState && updateChartState.loading) ||
          (!!deleteState && deleteState.loading)
        }
      />
      <DashboardSubpageHeader
        title={"Chart"}
        subtitle={chart?.description}
        extra={
          <Button
            type={"primary"}
            icon={<FontAwesomeIcon icon={faTrash} />}
            onClick={toggleDelete}
          >
            Eliminar
          </Button>
        }
      />
      <DescriptionsList rowKey={"chart"} onEditClicked={onEditClicked}>
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faHashtag} />}
          title={"ID"}
          label={chart?.id}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faTag} />}
          title={"Key"}
          label={chart?.key}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faTextSlash} />}
          title={"Descripción"}
          label={chart?.description}
          editable={true}
          itemKey={"description"}
        />
        <DescriptionsItem
          icon={<FontAwesomeIcon icon={faCalendar} />}
          title={"Creado el"}
          label={fastFormatDate(chart?.createdAt!!, "dd MMM yyyy")}
        />
      </DescriptionsList>
      <div className={"mt-8 flex flex-col gap-2 items-start"}>
        <Typography.Text type={"secondary"}>
          {!!chart?.schema
            ? "GRÁFICO DE EJEMPLO"
            : "Esta variable no tiene gráficas configuradas."}
        </Typography.Text>
        <Button
          onClick={() => setEditSchema(true)}
          type={"default"}
          icon={<FontAwesomeIcon icon={faEdit} />}
        >
          Editar
        </Button>
        {!!chart?.schema && <GraphicPrerenderer schema={chart.schema} />}
      </div>
      <DeleteBottomSheet
        rowKey={"deleteChart"}
        title={"Eliminar Dispositivo"}
        id={toNumber(id!!)}
        onSubmit={deleteChart}
        onClose={toggleDelete}
        open={deleteEvent}
      />
    </DashboardStateContainer>
  );
};
