import { FC, useState } from "react";
import { Checkbox, Modal, Select, Typography } from "antd";
import {
  PermissionEntry,
  RolePermissions,
  CreateRolePermissionsData,
} from "../../../../../types/Role";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { isNil } from "lodash";

type Props = {
  onClose: () => void;
  onFinish: (data: CreateRolePermissionsData) => void;
  tables: Array<PermissionEntry>;
  role: RolePermissions;
};

export const AddRolePermissionModal: FC<Props> = ({
  onClose,
  tables,
  onFinish,
  role,
}) => {
  const initial = {
    create: false,
    read: true,
    update: false,
    delete: false,
  };
  const [entryId, setEntryId] = useState<number | undefined>();
  const [editValues, setEditValues] = useState(initial);

  const onChange = (e: CheckboxChangeEvent) => {
    if (e.target.id) {
      setEditValues((old) => ({
        ...old,
        [e.target.id as string]: e.target.checked,
      }));
    }
  };

  return (
    <Modal
      open={true}
      title={"Designar permisos"}
      onOk={() => {
        const newData: CreateRolePermissionsData = {
          ...editValues,
          entryId: entryId!!,
          id: role.id,
        };
        onFinish(newData);
      }}
      onCancel={onClose}
      okText={"Guardar"}
      okButtonProps={{
        disabled: isNil(entryId),
        icon: <FontAwesomeIcon icon={faSave} />,
      }}
      cancelText={"Cancelar"}
    >
      <div className={"w-full flex flex-col gap-4 py-2"}>
        <Select
          className={"w-full"}
          placeholder="Selecciona una entidad"
          optionLabelProp="id"
          onChange={(value) => {
            setEntryId(value);
            setEditValues(initial);
          }}
        >
          {tables.map((it) => {
            return (
              <Select.Option
                key={`op-${it.name}`}
                value={it.name}
                label={it.name}
              >
                <div className={"flex flex-col w-full"}>
                  <Typography.Title className={"w-full"} level={5}>
                    {it.name}
                  </Typography.Title>
                  <Typography.Paragraph className={"w-full"}>
                    {it.description}
                  </Typography.Paragraph>
                </div>
              </Select.Option>
            );
          })}
        </Select>
        {entryId ? (
          <div className={"flex w-full flex-row gap-2 flex-wrap"}>
            <Checkbox
              onChange={onChange}
              id={"create"}
              checked={editValues.create}
            >
              Crear
            </Checkbox>
            <Checkbox
              onChange={onChange}
              id={"update"}
              checked={editValues.update}
            >
              Editar
            </Checkbox>
            <Checkbox
              onChange={onChange}
              id={"delete"}
              checked={editValues.delete}
            >
              Eliminar
            </Checkbox>
            <Checkbox onChange={onChange} id={"read"} checked={editValues.read}>
              Leer
            </Checkbox>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};
