import axios, { AxiosRequestConfig } from "axios";
import { getAccessToken, getRefreshToken } from "../utils/Token";
import FileDownload from "js-file-download";
export const hostConfig = {
  host: process.env.REACT_APP_REMOTE_HOST || "localhost",
  apiVersion: 1,
};

const RemoteApiClient = axios.create({
  baseURL: `${hostConfig.host}/v${hostConfig.apiVersion}`,
  withCredentials: true,
});

export const apiService = {
  get: async (url: string, config?: AxiosRequestConfig) =>
    await RemoteApiClient.get(url, config),
  getWithAuth: async <T>(url: string, data?: T, config?: AxiosRequestConfig) =>
    await RemoteApiClient.get(url, {
      ...config,
      headers: {
        Authorization: getAccessToken(),
        "X-RefreshToken": getRefreshToken(),
      },
      data,
    }),
  downloadWithAuth: async <T>(url: string, config?: AxiosRequestConfig) => {
    const result = await RemoteApiClient.get(url, {
      ...config,
      headers: {
        Authorization: getAccessToken(),
        "X-RefreshToken": getRefreshToken(),
      },
    });
    const contentDisposition = result.headers["content-disposition"];
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    const filename =
      matches && matches[1] ? matches[1].replace(/['"]/g, "") : "noname";
    FileDownload(result.data, filename);
    return result;
  },
  post: async <T>(url: string, data?: T, config?: AxiosRequestConfig) =>
    await RemoteApiClient.post(url, data, config),
  postWithAuth: async <T>(url: string, data?: T, config?: AxiosRequestConfig) =>
    await RemoteApiClient.post(url, data, {
      ...config,
      headers: {
        Authorization: getAccessToken(),
        "X-RefreshToken": getRefreshToken(),
      },
    }),
  put: async <T>(url: string, data?: T, config?: AxiosRequestConfig) =>
    await RemoteApiClient.put(url, data, config),
  putWithAuth: async <T>(url: string, data?: T, config?: AxiosRequestConfig) =>
    await RemoteApiClient.put(url, data, {
      ...config,
      headers: {
        Authorization: getAccessToken(),
        "X-RefreshToken": getRefreshToken(),
      },
    }),
  delete: async (url: string, config?: AxiosRequestConfig) =>
    await RemoteApiClient.delete(url, config),
  deleteWithAuth: async (url: string, config?: AxiosRequestConfig) =>
    await RemoteApiClient.delete(url, {
      ...config,
      headers: {
        Authorization: getAccessToken(),
        "X-RefreshToken": getRefreshToken(),
      },
    }),
};
