import { useState } from "react";
import { AppState, TaskState } from "../data/domain/State";
import { CustomerRepository } from "../data/repository/Customer";
import {
  Customer,
  NewCustomerFormData,
  UpdateCustomerFormData,
} from "../types/Customer";
import {
  CustomerStyle,
  UpdateCustomerStyleFormData,
} from "../types/CustomerStyle";
import {DeleteFormData} from "../types/App";
import {CustomerUserRepository} from "../data/repository/CustomerUser";

export function useCustomerViewModel() {
  const [fetchListState, setFetchListState] =
    useState<AppState<boolean> | null>(null);
  const [customerList, setCustomerList] = useState<Customer[] | undefined>();
  const [createCustomerState, setCreateCustomerState] =
    useState<AppState<boolean> | null>(null);
  const [updateCustomerState, setUpdateCustomerState] =
    useState<AppState<boolean> | null>(null);
  const [fetchCustomerState, setFetchCustomerState] =
    useState<AppState<boolean> | null>(null);
  const [customer, setCustomer] = useState<Customer | null>();
  const [customerStyle, setCustomerStyle] = useState<CustomerStyle | null>(
    null,
  );
  const [fetchCustomerStyleState, setFetchCustomerStyleState] =
    useState<AppState<boolean> | null>(null);
  const [updateCustomerStyleState, setUpdateCustomerStyleState] =
    useState<AppState<boolean> | null>(null);
  const [updateCustomerLogoState, setUpdateCustomerLogoState] =
    useState<AppState<boolean> | null>(null);
  const [deleteState, setDeleteState] = useState<AppState<boolean> | null>()

  async function fetchList() {
    if (fetchListState?.loading) return;
    setFetchListState(TaskState.loading());
    try {
      const list = await CustomerRepository.getCustomerList();
      if (list.ok) {
        setCustomerList(list.data!!);
        setFetchListState(TaskState.success(true));
      } else setFetchListState(TaskState.error(new Error(list.message!!)));
    } catch (error: any) {
      setFetchListState(TaskState.error(error));
    }
  }

  function onFetchListStateReceived() {
    setFetchListState(null);
  }

  async function fetchCustomerStyle(customerId: number) {
    if (fetchCustomerStyleState?.loading) return;
    setFetchCustomerStyleState(TaskState.loading());
    try {
      const style = await CustomerRepository.getCustomerStyle(customerId);
      if (style.ok) {
        setFetchCustomerStyleState(TaskState.success(true));
        setCustomerStyle(style.data!!);
      } else {
        setFetchCustomerStyleState(TaskState.error(new Error(style.message!!)));
      }
    } catch (e: any) {
      setFetchCustomerStyleState(TaskState.error(e));
    }
  }

  function onFetchCustomerStyleStateReceived() {
    setFetchCustomerStyleState(null);
  }

  async function createCustomer(customer: NewCustomerFormData) {
    if (createCustomerState?.loading) return;
    setCreateCustomerState(TaskState.loading());
    try {
      const newCustomer = await CustomerRepository.createCustomer(customer);
      if (newCustomer.ok) {
        setCreateCustomerState(TaskState.success(true));
        setCustomerList([...(customerList ?? []), newCustomer.data!!]);
        return true;
      } else {
        setCreateCustomerState(
          TaskState.error(new Error(newCustomer.message!!)),
        );
      }
    } catch (error: any) {
      setCreateCustomerState(TaskState.error(error));
    }
  }

  function onCreateCustomerStateReceived() {
    setCreateCustomerState(null);
  }

  async function updateCustomer(id: number, data: UpdateCustomerFormData) {
    if (updateCustomerState?.loading) return;
    setUpdateCustomerState(TaskState.loading());
    try {
      const updatedCustomer = await CustomerRepository.updateCustomer(id, data);
      if (updatedCustomer.ok) {
        setUpdateCustomerState(TaskState.success(true));
        const newList =
          customerList?.filter((c) => c.id !== updatedCustomer.data!!.old.id) ??
          [];
        setCustomerList([...newList, updatedCustomer.data!!.data]);
        return true;
      } else {
        setUpdateCustomerState(
          TaskState.error(new Error(updatedCustomer.message!!)),
        );
      }
    } catch (e: any) {
      setUpdateCustomerState(TaskState.error(e));
    }
  }

  async function updateCustomerStyle(
    customerId: number,
    formData: UpdateCustomerStyleFormData,
  ) {
    if (updateCustomerStyleState?.loading) return;
    setUpdateCustomerStyleState(TaskState.loading());
    try {
      const updatedCustomerStyle = await CustomerRepository.updateCustomerStyle(
        customerId,
        formData,
      );
      if (updatedCustomerStyle.ok) {
        setUpdateCustomerStyleState(TaskState.success(true));
      } else {
        setUpdateCustomerStyleState(
          TaskState.error(new Error(updatedCustomerStyle.message!!)),
        );
      }
    } catch (e: any) {
      setUpdateCustomerStyleState(TaskState.error(e));
    }
  }

  async function updateCustomerLogo(customerId: number, logo: File) {
    if (updateCustomerLogoState?.loading) return;
    setUpdateCustomerLogoState(TaskState.loading());
    try {
      const updatedCustomerStyle = await CustomerRepository.updateCustomerLogo(
        customerId,
        logo,
      );
      if (updatedCustomerStyle.ok) {
        setUpdateCustomerLogoState(TaskState.success(true));
      } else {
        setUpdateCustomerLogoState(
          TaskState.error(new Error(updatedCustomerStyle.message!!)),
        );
      }
    } catch (e: any) {
      setUpdateCustomerLogoState(TaskState.error(e));
    }
  }

  function onUpdateCustomerLogoStateReceived() {
    setUpdateCustomerLogoState(null);
  }

  function onUpdateCustomerStyleStateReceived() {
    setUpdateCustomerStyleState(null);
  }

  function onUpdateCustomerStateReceived() {
    setUpdateCustomerState(null);
  }

  async function fetchCustomer(id: number) {
    if (fetchCustomerState?.loading) return;
    setFetchCustomerState(TaskState.loading());
    try {
      const customer = await CustomerRepository.getCustomer(id);
      if (customer.ok) {
        setFetchCustomerState(TaskState.success(true));
        setCustomer(customer.data!!);
      } else {
        setFetchCustomerState(TaskState.error(new Error(customer.message!!)));
      }
    } catch (e: any) {
      setFetchCustomerState(TaskState.error(e));
    }
  }

  function onFetchCustomerStateReceived() {
    setFetchCustomerState(null);
  }

  async function deleteCustomer(data: DeleteFormData) {
    if(deleteState?.loading) return
    setDeleteState(TaskState.loading())
    try {
      await CustomerRepository.deleteCustomer(data)
      setDeleteState(TaskState.success(true))
      return true
    } catch (e: any) {
      setDeleteState(TaskState.error(e))
    }
  }

  function onDeleteStateReceived() {
    setDeleteState(null)
  }


  return {
    fetchListState,
    customerList,
    fetchList,
    onFetchListStateReceived,
    createCustomerState,
    createCustomer,
    onCreateCustomerStateReceived,
    updateCustomerState,
    updateCustomer,
    onUpdateCustomerStateReceived,
    fetchCustomerState,
    fetchCustomer,
    onFetchCustomerStateReceived,
    customer,
    customerStyle,
    fetchCustomerStyleState,
    fetchCustomerStyle,
    onFetchCustomerStyleStateReceived,
    updateCustomerLogo,
    updateCustomerStyle,
    updateCustomerStyleState,
    onUpdateCustomerStyleStateReceived,
    updateCustomerLogoState,
    onUpdateCustomerLogoStateReceived,
    deleteCustomer,
    deleteState,
    onDeleteStateReceived
  };
}
