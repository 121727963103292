import { To, useNavigate } from "react-router-dom";
import { FC, MouseEventHandler, ReactNode, useCallback, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faArrowLeft,
  faHomeAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "antd";
type Item = {
  pathname?: string;
  icon?: ReactNode;
  title?: string;
  active?: boolean;
};

type Props = {
  items: Item[];
};
export const DashboardBreadCrumb: FC<Props> = ({ items }) => {
  const navigate = useNavigate();
  const onButtonClick: MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      e.preventDefault();
      const p = e.currentTarget.dataset["to"]!!;
      const a = e.currentTarget.dataset["active"]!!;
      if (!a) navigate((p === "-1" ? -1 : p) as To);
    },
    [navigate],
  );
  const realItems: Item[] = useMemo(() => {
    return [
      {
        pathname: "/dashboard/overview",
        title: "Inicio",
        icon: <FontAwesomeIcon icon={faHomeAlt} />,
      },
      ...items,
    ];
  }, [items]);
  return (
    <nav className="w-full flex flex-wrap gap-2" aria-label="Breadcrumb">
      <Button
        type={"text"}
        icon={<FontAwesomeIcon icon={faArrowLeft} />}
        onClick={onButtonClick}
        data-to={"-1"}
        className={"text-opacity-40 text-black dark:text-white"}
      />
      <ol className="inline-flex items-center gap-x-1 md:gap-x-4">
        {realItems.map((it, index) => {
          return (
            <li
              key={`b-${index}`}
              className="gap-x-1 md:gap-x-4 inline-flex items-center text-opacity-40 text-black content-center justify-center align-middle"
            >
              {index > 0 && (
                <span className={"text-opacity-20 text-black dark:text-white"}>
                  <FontAwesomeIcon icon={faChevronRight} />
                </span>
              )}
              <Button
                size={"small"}
                className={"px-2 mx-0 text-opacity-40 text-black dark:text-white"}
                type={"text"}
                data-to={it.pathname}
                data-active={it.active}
                icon={it.icon}
                onClick={onButtonClick}
              >
                {it.title}
              </Button>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
