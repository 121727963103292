import { FC, Fragment, useEffect, useRef, useState } from "react";
import { DashboardOverviewData } from "../../../../types/Dashboard";
import { Card, Statistic, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { BarChart } from "../../../components/charts/Bar";
import { PieChart } from "../../../components/charts/Pie";
import { formatNumber } from "../../../../utils/Number";

type Props = {
  data: DashboardOverviewData;
};

export const DashboardOverviewDataGrid: FC<Props> = ({ data }) => {
  return (
    <div className={"w-full h-fit mx-auto flex flex-col gap-4"}>
      {data.map((item, index) => (
        <div key={`c-1`} className={"flex flex-col gap-2 w-full"}>
          <Typography.Text strong type={"secondary"}>
            {item.title}
          </Typography.Text>
          <div
            className={`w-full flex gap-2 ${
              index % 2 === 0 ? "flex-col" : "flex-col-reverse"
            }`}
          >
            <div className={"flex w-full flex-row flex-wrap gap-2"}>
              {item.statistics.map((dataItem, dataIndex) => {
                return (
                  <Card
                    key={`${index}-${dataIndex}-c`}
                    className={"flex-grow flex-shrink"}
                  >
                    <Statistic
                      prefix={
                        dataItem.difference ? (
                          dataItem.difference > 0 ? (
                            <FontAwesomeIcon icon={faArrowUp} />
                          ) : dataItem.difference < 0 ? (
                            <FontAwesomeIcon icon={faArrowDown} />
                          ) : null
                        ) : null
                      }
                      valueStyle={{
                        color: dataItem.difference
                          ? dataItem.difference > 0
                            ? "#3f8600"
                            : dataItem.difference < 0
                            ? "#cf1322"
                            : "inherit"
                          : "inherit",
                      }}
                      title={item.title + " " + dataItem.title}
                      value={formatNumber(dataItem.count)}
                    />
                  </Card>
                );
              })}
            </div>
            {item.graphics &&
              item.graphics.map((it, i) => (
                <Card key={`${index}-${i}-c`}>
                  <div className={"overflow-visible"}>
                    {index % 2 === 0 ? (
                      <BarChart id={it.title} tooltipTitle={"Data"} items={it.data} />
                    ) : (
                      <PieChart id={it.title} tooltipTitle={"Data"} items={it.data} />
                    )}
                  </div>
                </Card>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};
