import React, {Key, useCallback} from "react";
import {
    Drawer,
    Button,
} from "antd";
import {Formik, FormikHelpers, FormikValues} from "formik";
import {
    Form,
    SubmitButton,
} from "formik-antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {FormikActivityReasonField} from "./FormikActivityReasonField";
import {DeleteFormData} from "../../types/App";
import * as yup from "yup";
import {kACTIVITY_REASON_SCHEMA} from "../../utils/Schema";

type Props = {
    rowKey: Key;
    title: string;
    id: number

    onSubmit: (data: DeleteFormData) => Promise<boolean | undefined>

    onClose: () => void
    open: boolean
};

const kDELETE_SCHEMA = yup.object({
    id: yup.number().required("Es necesario ingresar el id."),
    reason: kACTIVITY_REASON_SCHEMA
})

export function DeleteBottomSheet({
                                      title,
                                      rowKey,
                                      id, onSubmit, onClose, open
                                  }: Props) {
    const onSubmitForm = useCallback(
        async (values: FormikValues, helpers: FormikHelpers<FormikValues>) => {
            const it = await onSubmit(values as DeleteFormData);
            helpers.setSubmitting(false);
            if (it) onClose();
        },
        [onClose],
    );
    return (
        <Drawer
            destroyOnClose
            title={title}
            open={open}
            onClose={onClose}
        >
            <Formik<Partial<DeleteFormData>>
                validateOnChange={true}
                validateOnBlur
                initialValues={{
                    id
                }}
                enableReinitialize
                onSubmit={onSubmitForm}
                validationSchema={kDELETE_SCHEMA}
            >
                <Form layout={"vertical"} className={"mx-auto"}>
                    <FormikActivityReasonField/>
                    <Button.Group className={"my-4"}>
                        <SubmitButton icon={<FontAwesomeIcon icon={faPaperPlane}/>}>
                            Continuar
                        </SubmitButton>
                    </Button.Group>
                </Form>
            </Formik>
        </Drawer>
    );
}
