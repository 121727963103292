import { FC, useEffect, useState } from "react";
import { DashboardSubpageContainer } from "../../components/DashboardContainer";
import { DashboardSubpageHeader } from "../../components/DashboardHeader";
import { VehicleCreateForm } from "./components/Form";
import { toast } from "react-toastify";
import { useCustomerViewModel } from "../../../viewmodel/Customer";
import { useVehicleViewModel } from "../../../viewmodel/Vehicle";
import { AppLoader } from "../../components/AppLoader";
import { useSearchParams } from "react-router-dom";
import { Customer } from "../../../types/Customer";
import { notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import { DashboardBreadCrumb } from "../../components/DashboardBreadcrumb";
import { testAsNumber } from "../../../utils/Number";

type Props = {};
export const DashboardVehicleCreatePage: FC<Props> = ({}) => {
  const [searchParams] = useSearchParams();
  const [desiredCustomer, setDesiredCustomer] = useState<Customer | null>(null);
  const {
    fetchList: fetchCustomerList,
    fetchListState: fetchCustomerListState,
    onFetchListStateReceived: onFetchCustomerListStateReceived,
    customerList,
  } = useCustomerViewModel();
  const { createVehicle, createVehicleState, onCreateVehicleStateReceived } =
    useVehicleViewModel();
  useEffect(() => {
    if (!!fetchCustomerListState && !fetchCustomerListState.loading) {
      onFetchCustomerListStateReceived();
      if (fetchCustomerListState.hasError) {
        toast.error(fetchCustomerListState.error?.message);
      } else {
        testAsNumber(searchParams.get("customerId"))
          .then((id) => {
            const desiredC = customerList!!.filter((it) => it.id === id);
            if (!!desiredC && desiredC.length >= 1) {
              setDesiredCustomer(desiredC[0]);
            } else {
              notification.error({ message: "El id de cliente no es válido" });
            }
          })
          .catch(() => {
            notification.error({ message: "El id de cliente no es válido." });
          });
      }
    }
  }, [fetchCustomerListState]);

  useEffect(() => {
    if (!!createVehicleState && !createVehicleState.loading) {
      if (createVehicleState.hasError) {
        toast.error(createVehicleState.error?.message);
      } else {
        toast.success("Vehículo registrado correctamente.");
      }
      onCreateVehicleStateReceived();
    }
  }, [createVehicleState]);

  useEffect(() => {
    void fetchCustomerList();
  }, []);

  return (
    <DashboardSubpageContainer className={"w-full h-fit overflow-x-hidden"}>
      <AppLoader
        loading={
          (!!fetchCustomerListState && fetchCustomerListState.loading) ||
          (!!createVehicleState && createVehicleState.loading)
        }
      />
      {desiredCustomer ? (
        <DashboardBreadCrumb
          items={[
            {
              pathname: "/dashboard/customers",
              title: "Clientes",
              icon: <FontAwesomeIcon icon={faPeopleGroup} />,
            },
            {
              pathname: `/dashboard/customers/${desiredCustomer?.id}`,
              title: desiredCustomer.businessName,
            },
            {
              pathname: `/dashboard/customers/${desiredCustomer?.id}?tab=vehicles`,
              title: "Vehículos",
            },
            {
              active: true,
              title: "Nuevo",
            },
          ]}
        />
      ) : (
        <DashboardBreadCrumb
          items={[
            {
              pathname: "/dashboard/vehicles",
              title: "Vehículos",
              icon: <FontAwesomeIcon icon={faCar} />,
            },
            {
              active: true,
              title: "Nuevo",
            },
          ]}
        />
      )}
      <DashboardSubpageHeader
        title={"Nuevo Vehículo"}
        subtitle={
          desiredCustomer ? `Para ${desiredCustomer.businessName}` : undefined
        }
      />

      <VehicleCreateForm
        initialValues={{
          customerId: desiredCustomer?.id,
        }}
        customerList={customerList}
        onFinish={createVehicle}
      />
    </DashboardSubpageContainer>
  );
};
