import {FC, useCallback, useEffect} from "react";
import { TabsProps } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faNetworkWired } from "@fortawesome/free-solid-svg-icons";
import { Gateway } from "../../../../types/Gateway";
import { GatewayMainTab } from "../tabs/TabMain";
import { GatewayDevicesTab } from "../tabs/TabDevices";
import {useDashboardHeader} from "../../../hooks/Header";
import {DashboardTabWindow} from "../../../components/DashboardTabWindow";

type Props = {
  gateway: Gateway;
  onUpdateEnded : ()=>void
};

const items: TabsProps["items"] = [
  {
    key: "main",
    label: (
      <span>
        <FontAwesomeIcon icon={faHome} /> Principal
      </span>
    ),
  },
  {
    key: "devices",
    label: (
      <span>
        <FontAwesomeIcon icon={faNetworkWired} /> Variables
      </span>
    ),
  },
];

export const GatewayDataComponent: FC<Props> = ({ gateway, onUpdateEnded }) => {
  const { setTitle } = useDashboardHeader();

  useEffect(() => {
    setTitle("Gateway");
  }, []);
  const onSelected = useCallback((key: string) => {
    switch (key) {
      case "devices":
        return <GatewayDevicesTab gateway={gateway} />;
      default:
        return <GatewayMainTab onUpdate={onUpdateEnded} gateway={gateway} />;
    }
  }, []);

  return (
      <DashboardTabWindow
          items={items}
          defaultSelectedKey={"main"}
          onSelected={onSelected}
      />
  );
};
