import { Vehicle } from "../../../../types/Vehicle";
import { FC, MouseEventHandler, useCallback, useEffect } from "react";
import { useGatewayViewModel } from "../../../../viewmodel/Gateway";
import {
  Button,
  Card,
  Descriptions,
  List,
  notification,
  Tag,
  Typography,
} from "antd";
import { AppLoader } from "../../../components/AppLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faRefresh,
  faPlugCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { EmptyData } from "../../../components/Empty";
import { AddVehicleGatewayModal } from "../components/ModalAddGateway";
import { DashboardStateContainer } from "../../../components/DashboardStateContainer";
import { useNavigate } from "react-router-dom";

type Props = {
  vehicle: Vehicle;
};

export const VehicleGatewayTab: FC<Props> = ({ vehicle }) => {
  const {
    fetchGatewayState,
    fetchVehicleGateway,
    onFetchGatewayStateReceived,
    addEvent,
    requestAddEvent,
    onAddEventComplete,
    fetchAvailableList,
    fetchListState,
    gatewayList,
    assignVehicleGateway,
    vehicleGateway,
    onFetchListStateReceived,
    updateGatewayState,
    onUpdateGatewayStateReceived,
  } = useGatewayViewModel();
  const navigate = useNavigate();

  useEffect(() => {
    if (!!fetchGatewayState && !fetchGatewayState?.loading) {
      if (fetchGatewayState.hasError) {
        notification.error({
          message: fetchGatewayState.error?.message,
        });
      }
      onFetchGatewayStateReceived();
    }
  }, [fetchGatewayState]);

  useEffect(() => {
    if (!!fetchListState && !fetchListState?.loading) {
      if (fetchListState.hasError) {
        notification.error({
          message: fetchListState.error?.message,
        });
      }
      onFetchListStateReceived();
    }
  }, [fetchListState]);

  useEffect(() => {
    if (!!updateGatewayState && !updateGatewayState.loading) {
      if (updateGatewayState.hasError) {
        notification.error({
          message: updateGatewayState.error?.message,
        });
      } else {
        notification.success({
          message: "Se ha asignado el gateway correctamente.",
        });
      }
      onAddEventComplete();
      void fetchVehicleGatewayList();
      onUpdateGatewayStateReceived();
    }
  }, [updateGatewayState]);

  const fetchVehicleGatewayList = useCallback(() => {
    void fetchVehicleGateway(vehicle.id);
  }, [vehicle]);

  useEffect(() => {
    void fetchVehicleGatewayList();
    void fetchAvailableList();
  }, []);

  const onCardClicked: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      const index = Number(event.currentTarget.dataset.index);
      const gw = vehicleGateway!![index];
      navigate("/dashboard/gateways/" + gw.id + "?fromVehicle=true");
    },
    [navigate, vehicleGateway],
  );

  const deleteEventHandler: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.stopPropagation();
      const index = Number(event.currentTarget.dataset.index);
      const gw = vehicleGateway!![index];
      console.log(gw);
      void assignVehicleGateway({
        vehicleId: null,
        gatewayId: gw.id,
        reason: "Desvincular gateway.",
      });
    },
    [assignVehicleGateway, vehicleGateway],
  );
  return (
    <DashboardStateContainer>
      <AppLoader loading={!!fetchListState?.loading} />
      <Typography.Text type={"secondary"}>
        {vehicleGateway?.length} gateways en este vehículo.
      </Typography.Text>
      {addEvent ? (
        <AddVehicleGatewayModal
          gatewayList={gatewayList}
          onFinish={assignVehicleGateway}
          onCancel={onAddEventComplete}
          initialValues={{ vehicleId: vehicle.id }}
        />
      ) : null}
      <AppLoader
        loading={
          !!fetchGatewayState?.loading ||
          !!fetchListState?.loading ||
          !!updateGatewayState?.loading
        }
      />
      <Button.Group className={"block my-2"}>
        <Button
          type={"default"}
          onClick={fetchVehicleGatewayList}
          icon={<FontAwesomeIcon icon={faRefresh} />}
        >
          Actualizar
        </Button>
        <Button
          onClick={requestAddEvent}
          type={"default"}
          icon={<FontAwesomeIcon icon={faAdd} />}
        >
          Agregar
        </Button>
      </Button.Group>
      <div className={"overflow-y-auto flex flex-col gap-2"}>
        {!!vehicleGateway && vehicleGateway.length > 0 ? (
          vehicleGateway.map((gateway, index) => (
            <Card
              onClick={onCardClicked}
              data-index={index}
              key={`gateway-${index}`}
            >
              <div className={"flex flex-col"}>
                <Typography.Text type={"secondary"} strong>
                  {gateway.description}
                </Typography.Text>
                <Typography.Text type={"secondary"}>
                  {gateway.id} / {gateway.key}
                </Typography.Text>
                {gateway.devices && gateway.devices.length > 0 ? (
                  <div className={"flex flex-col gap-3"}>
                    <Typography.Text strong>
                      Variables Disponibles
                    </Typography.Text>
                    <div className={"flex flex-row flex-wrap gap-2"}>
                      {gateway.devices.map((device, i) => {
                        return (
                          <Card key={`gw-var-${i}`} className={""}>
                            <div className={"flex flex-col justify-center"}>
                              <Typography.Text>
                                {device.description}
                              </Typography.Text>
                              <div className={"flex flex-row gap-2"}>
                                <Typography.Text type={"secondary"}>
                                  {device.key}
                                </Typography.Text>
                                <Tag
                                  color={
                                    device.visibility === 0 ? "green" : "yellow"
                                  }
                                >
                                  {device.visibility === 0
                                    ? "Dashboard"
                                    : "General"}
                                </Tag>
                              </div>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <Typography.Text type={"secondary"}>
                    No hay variables disponibles en este gateway.
                  </Typography.Text>
                )}
                <Button
                  data-index={index}
                  onClick={deleteEventHandler}
                  className={"mt-2"}
                  icon={<FontAwesomeIcon icon={faPlugCircleXmark} />}
                >
                  Quitar
                </Button>
              </div>
            </Card>
          ))
        ) : (
          <EmptyData description={"No hay gateways en el vehículo."} />
        )}
      </div>
    </DashboardStateContainer>
  );
};
