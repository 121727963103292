import { FC, useEffect } from "react";
import { DashboardSubpageContainer } from "../../components/DashboardContainer";
import { DashboardSubpageHeader } from "../../components/DashboardHeader";
import { AppLoader } from "../../components/AppLoader";
import { notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsersGear } from "@fortawesome/free-solid-svg-icons";
import { DashboardBreadCrumb } from "../../components/DashboardBreadcrumb";
import { useRoleViewModel } from "../../../viewmodel/Role";
import { UserRoleCreateForm } from "./components/Form";

type Props = {};
export const DashboardUserRoleCreatePage: FC<Props> = ({}) => {
  const { createRole, createState, onCreateStateReceived } = useRoleViewModel();
  useEffect(() => {
    if (!!createState && !createState.loading) {
      onCreateStateReceived();
      if (createState.hasError) {
        notification.error({
          message: "Nuevo Rol de Usuario",
          description: createState.error?.message,
        });
      } else {
        notification.success({
          message: "Nuevo Rol de Usuario",
          description: "Rol registrado correctamente.",
        });
      }
    }
  }, [createState]);

  return (
    <DashboardSubpageContainer className={"w-full h-fit overflow-x-hidden"}>
      <AppLoader loading={!!createState && createState.loading} />
      <DashboardBreadCrumb
        items={[
          {
            pathname: "/dashboard/users/roles",
            title: "Roles de Usuario",
            icon: <FontAwesomeIcon icon={faUsersGear} />,
          },
          {
            active: true,
            title: "Nuevo",
          },
        ]}
      />
      <DashboardSubpageHeader title={"Nuevo Rol de Usuario"} />

      <UserRoleCreateForm onFinish={createRole} />
    </DashboardSubpageContainer>
  );
};
