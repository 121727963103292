import { FC, useCallback, useEffect } from "react";
import { DashboardSubpageContainer } from "../../components/DashboardContainer";
import { DashboardSubpageHeader } from "../../components/DashboardHeader";
import { Button } from "antd";
import { faPlus, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useCustomerViewModel } from "../../../viewmodel/Customer";
import { CustomerListData } from "./components/Data";
import { Customer } from "../../../types/Customer";
import {AppLoader} from "../../components/AppLoader";
import {useDashboardHeader} from "../../hooks/Header";
import {DashboardBreadCrumb} from "../../components/DashboardBreadcrumb";

export const DashboardCustomerListPage: FC = () => {
  const { fetchList, customerList, fetchListState, onFetchListStateReceived } =
    useCustomerViewModel();
  const navigate = useNavigate();

  const onNewCustomerClick = useCallback(() => {
    navigate("/dashboard/customers/create");
  }, [navigate]);

  const onItemClicked = useCallback(
    (item: Customer) => {
      navigate(`/dashboard/customers/${item.id}`);
    },
    [navigate, customerList],
  );

  const { setTitle } = useDashboardHeader();

  useEffect(() => {
    void fetchList();
    setTitle("Clientes")
  }, []);

  useEffect(() => {
    if (!!fetchListState && !fetchListState?.loading) {
      if (fetchListState?.hasError) {
        toast.error(fetchListState?.error?.message);
      } else {
        console.log(customerList);
      }
      onFetchListStateReceived();
    }
  }, [fetchListState]);

  return (
    <DashboardSubpageContainer className={"w-full h-fit overflow-hidden"}>
      <AppLoader loading={!!fetchListState && fetchListState.loading}/>
      <DashboardBreadCrumb
          items={[
            {
              active: true,
              title: "...",
            },
          ]}
      />
      <DashboardSubpageHeader
        title={"Clientes"}
        subtitle={`${customerList?.length} clientes`}
        extra={
          <Button.Group>
            <Button
              type={"default"}
              icon={<FontAwesomeIcon icon={faRefresh} />}
              onClick={fetchList}
            >
              Actualizar
            </Button>
            <Button
              type={"primary"}
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={onNewCustomerClick}
            >
              Nuevo
            </Button>
          </Button.Group>
        }
      />
      <div className={"w-full h-full"}>
        <CustomerListData data={customerList} onItemClicked={onItemClicked} />
      </div>
    </DashboardSubpageContainer>
  );
};
