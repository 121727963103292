import { FC, useMemo } from "react";
import { extractMonth } from "../../../utils/Dates";
import Plot from "react-plotly.js";
import { Data } from "plotly.js";
import { ChartData2 } from "../../../types/charts/Chart";
import { ChartSchemaDefinition } from "../../../types/Chart";
import {
  generateChartColors,
  generateChartTitles,
  parseChartDate,
} from "../../../utils/Charts";
type Props = {
  tooltipTitle: string;
  items: Array<ChartData2>;
  schema?: ChartSchemaDefinition;
  id: string;
};
export const HPlotChart: FC<Props> = ({ tooltipTitle, items, schema }) => {
  console.log(items);
  const colors = useMemo(() => generateChartColors(schema), [schema]);
  const titles = useMemo(() => generateChartTitles(schema), [schema]);
  const data: Data[] = items.map((it) => {
    return {
      x: [it.repeats],
      y: [extractMonth(it.start)],
      name: it.title,
      orientation: "h",
      marker: {
        color: colors ? colors[it.name] : "#FFF",
        width: 1,
        line: {
          color: "#000",
          width: 2,
        },
      },
      type: "bar",
      showlegend: true,
      text: [titles ? titles[it.name] : it.title],
      hovertemplate:
        "<b>" +
        it.repeats +
        "</b>" +
        "<b>Horas</b>: %{x:.2f}" +
        "<br>%{text}" +
        "<br><b>Inicio: </b>" +
        it.start +
        "<br><b>Fin: </b>" +
        it.end,
    };
  });
  return (
    <Plot
      data={data}
      layout={{
        //title: 'FRECUENCIA DE USO MENSUAL',
        barmode: "stack",
        xaxis: {
          title: {
            text: "Horas (h)",
          },
        },
        paper_bgcolor: "transparent",
        showlegend: true,
        legend: { orientation: "v" },
        hovermode: "closest",
        hoverlabel: {
          align: "left",
          bordercolor: "#BDBDBD",
          font: {
            color: "#424242",
            size: 15,
            family: "'Roboto Condensed', sans-serif",
          },
        },
      }}
    />
  );
};
