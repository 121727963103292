import { AllBaseConfig, Gauge, GaugeConfig, Plot } from "@ant-design/charts";
import { FC, MutableRefObject, useEffect, useMemo, useRef } from "react";
import { ChartData1 } from "../../../types/charts/Chart";
import { filter, first, isEmpty, last, toNumber } from "lodash";
import { Typography } from "antd";
import {
  ChartGaugeDefinition,
  ChartSchemaDefinition,
} from "../../../types/Chart";

type Props = {
  tooltipTitle: string;
  items: Array<Array<ChartData1<number>>>;
  schema?: ChartSchemaDefinition;
  id: string;
};
export const GaugeChart: FC<Props> = ({ tooltipTitle, items, schema }) => {
  const gaugesRef = useRef<{
    [key: string]: MutableRefObject<Plot<AllBaseConfig>>;
  }>({});
  const labelsRef = useRef({}) as MutableRefObject<{
    [key: string]: string;
  }>;

  const gauges = useMemo(() => {
    const result: Array<{
      config: GaugeConfig;
      key: string;
      gauge: ChartGaugeDefinition;
    }> = [];
    schema?.gauge?.forEach((gauge) => {
      const colors: Array<string> = [];
      gauge.colors?.forEach((it) => {
        colors.push(it || "#FFC107");
      });
      result.push({
        config: {
          percent: 0,
          appendPadding: 0,
          autoFit: true,
          startAngle: gauge.startAngle,
          endAngle: gauge.endAngle,
          axis: {
            label: {
              formatter(v) {
                const max = gauge?.maxLimit || 1;
                const min = gauge?.minLimit || 0;
                return min + (max - min) * toNumber(v);
              },
            },
            subTickLine: {
              count: 3,
            },
          },
          range: {
            ticks: new Array(colors.length + 1).fill(0).map((_, index) => {
              const size = 1 / colors.length;
              return index * size;
            }),
            color: colors,
          },
          animation: false,
          indicator: {
            pointer: {
              style: {
                stroke: "#D0D0D0",
              },
            },
            pin: {
              style: {
                stroke: "#D0D0D0",
              },
            },
          },
          style: {
            padding: 0,
            margin: 0,
          },
          gaugeStyle: {
            padding: 0,
            margin: 0,
          },
          statistic: {
            content: {
              style: {
                fontSize: "0",
                lineHeight: "36px",
                color: "#D0D0D0",
              },
            },
          },
        },
        key: gauge.property,
        gauge,
      });
    });
    return result;
  }, [schema]);

  useEffect(() => {
    if (!isEmpty(items)) {
      const lastSegment = last(items);
      lastSegment?.forEach((it) => {
        const gaugeFilter = filter(
          schema?.gauge,
          (g) => g.property === it.name
        );
        if (!isEmpty(gaugeFilter)) {
          const gauge = first(gaugeFilter);
          const max = gauge?.maxLimit || 1;
          const min = gauge?.minLimit || 0;
          const _value = it.value;
          const date = it.date;
          const convertedValue = (_value - min) / (max - min);
          const newVal = {
            value: convertedValue,
            date,
            title: tooltipTitle,
            name: tooltipTitle,
            display: _value.toString() + "°",
          };
          const ref = gaugesRef.current[gauge?.property!!];
          if (ref && ref.current) {
            ref.current.changeData(newVal.value);
            labelsRef.current = {
              ...labelsRef.current,
              [gauge!!.property]: newVal.display,
            };
          }
        }
      });
    }
  }, [schema, items, gaugesRef]);

  return (
    <div
      className={
        "flex flex-row gap-2 flex-wrap items-center w-fit h-fit relative m-auto"
      }
    >
      {gauges.map((it, index) => (
        <Component
          labelReferences={labelsRef}
          config={it.config}
          key={`gauge${index}${it.key}`}
          references={gaugesRef}
          gaugeKey={it.key}
          gauge={it.gauge}
        />
      ))}
    </div>
  );
};

function Component({
  config,
  references,
  gaugeKey,
  gauge,
  labelReferences,
}: {
  config: GaugeConfig;
  references: MutableRefObject<{
    [key: string]: MutableRefObject<Plot<AllBaseConfig>>;
  }>;
  labelReferences: MutableRefObject<{ [key: string]: string }>;
  gaugeKey: string;
  gauge: ChartGaugeDefinition;
}) {
  const currRef = useRef<Plot<AllBaseConfig>>(null) as MutableRefObject<
    Plot<AllBaseConfig>
  >;
  useEffect(() => {
    references.current = {
      ...references.current,
      [gaugeKey]: currRef,
    };
  }, [currRef]);

  useEffect(() => {
    console.log(labelReferences);
  }, [labelReferences.current]);

  return (
    <div className={"relative h-fit w-fit max-w-xs"}>
      <Typography.Title
        level={4}
        className={"bottom-0 text-center w-full absolute p-0 m-0"}
        type={"secondary"}
      >
        {labelReferences?.current[gauge.property]}
      </Typography.Title>
      <Typography.Text
        strong
        className={"bottom-8 text-center w-full absolute p-0 m-0"}
        type={"secondary"}
      >
        {gauge.title}
      </Typography.Text>
      <Gauge {...config} chartRef={currRef} />
    </div>
  );
}
