import React, { FC, useCallback } from "react";
import {DatePicker, DatePickerProps} from "antd";
import { Field, FieldProps } from "formik";
import dayjs from "dayjs";

type Props = DatePickerProps & {
  name: string; };

export const FormikAntDatePicker: FC<Props> = (props) => {
    return (
        <Field name={props.name} >
            {(fProps: any) => <FieldRender {...fProps} itemProps={props}/>}
        </Field>
    )
};

const FieldRender = ({ field: { onChange, value, name }, itemProps }:  FieldProps  & { itemProps: Props }) => {
  const onInputChange = useCallback(
    (value: dayjs.Dayjs | null) => {
      onChange({
        target: {
          value: value,
            name: name
        },
      });
    },
    [onChange],
  );
  return <DatePicker picker={"date"} value={value} onChange={onInputChange} {...itemProps} />;
};
