import {FC, ReactNode, useCallback, useMemo} from "react";
import {ConfigProvider, Menu, MenuProps, TabsProps} from "antd";
import {SecondaryMenuTheme} from "../../utils/Theme";
import {useQuery} from "../hooks/Query";
import {isSm, isXs, screenIsAnyOf} from "../../utils/tailwind.screens";
import {useBreakpoints} from "../hooks/Breakpoint";
import {useNavigate} from "react-router-dom";

type Props = {
    items: TabsProps["items"]
    defaultSelectedKey: string
    onSelected: (key: string)=>ReactNode
}

export const DashboardTabWindow : FC<Props> = ({items, defaultSelectedKey, onSelected}) => {
    const query = useQuery().get("tab");
    const { breakpoint } = useBreakpoints();
    const navigate = useNavigate()
    const isMobile = screenIsAnyOf(breakpoint, isXs, isSm);
    const Element = useMemo(() => {
        if(!!query) {
            return onSelected(query)
        } else {
            return onSelected(defaultSelectedKey)
        }
    }, [query]);

    const onInternalSelected : MenuProps["onSelect"]= useCallback((info : any)=>{
        navigate(`?tab=${info.key}`);
    }, [navigate])
    return <div className={"w-full h-fit overflow-x-hidden overflow-y-auto flex flex-col md:flex-row"}>
        <ConfigProvider theme={SecondaryMenuTheme}>
            <Menu
                mode={isMobile ? "horizontal" : "vertical"}
                selectedKeys={[query || defaultSelectedKey]}
                items={items}
                onSelect={onInternalSelected}
            />
        </ConfigProvider>
        <div className={"w-full p-1 overflow-x-hidden overflow-y-auto"}>{Element}</div>
    </div>
}