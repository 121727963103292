/**
 * @type {import("tailwindcss").Config.theme.screens}
 * */
export const screens = {
    xs: 0,
    sm: 480,
    md: 640,
    lg: 1024,
    xl: 1280,
    '2xl': 1536,
}

export function isXs(bp: keyof typeof screens | undefined) {
    return !!bp && screens[bp] === screens.xs
}

export function isSm(bp: keyof typeof screens | undefined) {
    return !!bp && screens[bp] === screens.sm
}

export function isMd(bp: keyof typeof screens | undefined) {
    return !!bp && screens[bp] === screens.md
}

export function isLg(bp: keyof typeof screens | undefined) {
    return !!bp && screens[bp] === screens.lg
}

export function isXl(bp: keyof typeof screens | undefined) {
    return !!bp && screens[bp] === screens.xl
}

export function is2Xl(bp: keyof typeof screens | undefined) {
    return !!bp && screens[bp] === screens["2xl"]
}

export function screenIsAnyOf(bp: keyof typeof screens | undefined, ...test: Array<(bp: keyof typeof screens | undefined) => boolean>) {
    for (let tester of test) {
        if (tester(bp)) {
            return true
        }
    }
    return false
}


export const zPHONE_SCREENS : Array<keyof typeof screens> = ["xs"]
export const zSMALL_SCREENS : Array<keyof typeof screens> = ["xs", "sm"]

export const zMEDIUM_SCREENS : Array<keyof typeof screens> = ["md"]

export const zLARGE_SCREENS  : Array<keyof typeof screens> = ["lg"]

export const zX_LARGE_SCREENS : Array<keyof typeof screens> = ["xl", "2xl"]

export function selectByScreen<T>(bp: keyof typeof  screens,options :Array<keyof typeof screens>, value: T ) {
    if(options.includes(bp)) return value
    else return null
}