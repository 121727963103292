import { FC } from "react";
import * as yup from "yup";
import { Gateway, UpdateGatewayFormData } from "../../../../types/Gateway";
import { Formik, FormikHelpers } from "formik";
import { Form, FormItem, Select, SubmitButton } from "formik-antd";
import { Alert, Button, Modal, Typography } from "antd";
import { FormikActivityReasonField } from "../../../components/FormikActivityReasonField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faPaperPlane,
  faServer,
} from "@fortawesome/free-solid-svg-icons";
import { CustomNavigator } from "../../../components/CustomLink";

type Props = {
  gatewayList?: Array<Gateway> | null;
  onFinish: (data: UpdateGatewayFormData) => Promise<boolean | undefined>;
  onCancel: () => void;
  initialValues: Partial<UpdateGatewayFormData>;
};

const kVEHICLE_GATEWAY_VALIDATION = yup.object({
  vehicleId: yup.number().required("El vehículo es requerido"),
  gatewayId: yup.number().required("El gateway es requerido"),
  reason: yup
    .string()
    .required(
      "Es necesario especificar por qué asigna este gateway a este vehículo.",
    )
    .min(16, "Detalle el cambio a realizar con al menos 16 caracteres."),
});

export const AddVehicleGatewayModal: FC<Props> = ({
  gatewayList,
  onFinish,
  initialValues,
  onCancel,
}) => {
  const onSubmit = (
    values: UpdateGatewayFormData,
    helpers: FormikHelpers<UpdateGatewayFormData>,
  ) => {
    onFinish(values).then((it) => {
      helpers.setSubmitting(false);
      onCancel();
    });
  };

  return (
    <Modal
      open={true}
      title={"Añadir gateway"}
      footer={null}
      onCancel={onCancel}
    >
      {(!gatewayList || gatewayList.length <= 0) && (
        <Alert
          closable
          description={
            <div className={"flex flex-col gap-2"}>
              <Typography.Text type={"secondary"}>
                No hay gateways disponibles
              </Typography.Text>
              <Typography.Text>
                No hay suficientes gateways o todos se encuentran en uso.
              </Typography.Text>
              <Typography.Text>
                Intenta crear uno nuevo o verifica a qué vehículo está vinculado
                el gateway que deseas agregar aquí.
              </Typography.Text>
              <div className={"flex flex-row gap-2"}>
                <CustomNavigator to={"/dashboard/gateways/create"}>
                  <Button
                    icon={<FontAwesomeIcon icon={faAdd} />}
                    type={"primary"}
                  >
                    Nuevo
                  </Button>
                </CustomNavigator>
                <CustomNavigator to={"/dashboard/gateways"}>
                  <Button icon={<FontAwesomeIcon icon={faServer} />}>
                    Ver gateways
                  </Button>
                </CustomNavigator>
              </div>
            </div>
          }
          className={"my-2"}
        />
      )}
      <Formik
        validationSchema={kVEHICLE_GATEWAY_VALIDATION}
        enableReinitialize
        initialValues={initialValues as any}
        onSubmit={onSubmit}
      >
        <Form>
          <FormItem name={"gatewayId"} label={"Seleccione un gateway"}>
            <Select
              fieldNames={{
                label: "key",
                value: "id",
              }}
              name={"gatewayId"}
              options={gatewayList as any}
              placeholder={"Seleccione un gateway"}
            />
          </FormItem>
          <FormikActivityReasonField />
          <SubmitButton icon={<FontAwesomeIcon icon={faPaperPlane} />}>
            Enviar
          </SubmitButton>
        </Form>
      </Formik>
    </Modal>
  );
};
