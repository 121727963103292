import { useState } from "react";
import { AppState, TaskState } from "../data/domain/State";
import {
  NewGatewayDeviceFormData,
  GatewayDevice,
  GatewayDeviceSelection,
} from "../types/GatewayDevice";
import { GatewayDeviceRepository } from "../data/repository/GatewayDevice";
import { DeleteFormData } from "../types/App";

export function useGatewayDeviceViewModel() {
  const [fetchListState, setFetchListState] =
    useState<AppState<boolean> | null>(null);
  const [gatewayDeviceList, setGatewayDeviceList] = useState<
    GatewayDevice[] | null
  >(null);
  const [createGatewayDeviceState, setCreateGatewayDeviceState] =
    useState<AppState<boolean> | null>(null);
  const [addEvent, setAddEvent] = useState<boolean | null>(null);
  const [deleteState, setDeleteState] = useState<AppState<boolean> | null>();

  async function fetchList(id: number, selection?: GatewayDeviceSelection) {
    if (fetchListState?.loading) return;
    setFetchListState(TaskState.loading());
    try {
      const list = await GatewayDeviceRepository.getGatewayDevices(
        id,
        selection
      );
      if (list.ok) {
        setGatewayDeviceList(list.data!!);
        setFetchListState(TaskState.success(true));
      } else setFetchListState(TaskState.error(new Error(list.message!!)));
    } catch (error: any) {
      setFetchListState(TaskState.error(error));
    }
  }

  function onFetchListStateReceived() {
    setFetchListState(null);
  }

  async function createGatewayDevice(gatewayDevice: NewGatewayDeviceFormData) {
    if (createGatewayDeviceState?.loading) return;
    setCreateGatewayDeviceState(TaskState.loading());
    try {
      const newDevice =
        await GatewayDeviceRepository.createGatewayDevice(gatewayDevice);
      if (newDevice.ok) {
        setCreateGatewayDeviceState(TaskState.success(true));
      } else {
        setCreateGatewayDeviceState(
          TaskState.error(new Error(newDevice.message!!))
        );
      }
    } catch (error: any) {
      setCreateGatewayDeviceState(TaskState.error(error));
    }
  }

  function onCreateGatewayDeviceStateReceived() {
    setCreateGatewayDeviceState(null);
  }

  function requestAddEvent() {
    setAddEvent(true);
  }

  function onAddEventCompleted() {
    setAddEvent(null);
  }

  async function deleteGatewayDevice(data: DeleteFormData) {
    if (deleteState?.loading) return;
    setDeleteState(TaskState.loading());
    try {
      await GatewayDeviceRepository.deleteGatewayDevice(data);
      setDeleteState(TaskState.success(true));
      return true;
    } catch (e: any) {
      setDeleteState(TaskState.error(e));
    }
  }

  function onDeleteStateReceived() {
    setDeleteState(null);
  }

  return {
    fetchListState,
    fetchList,
    onFetchListStateReceived,
    gatewayDeviceList,
    createGatewayDeviceState,
    createGatewayDevice,
    onCreateGatewayDeviceStateReceived,
    addEvent,
    requestAddEvent,
    onAddEventCompleted,
    deleteState,
    deleteGatewayDevice,
    onDeleteStateReceived,
  };
}
