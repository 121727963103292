import { FC, useCallback } from "react";
import { NewUserFormData } from "../../../../types/User";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faICursor,
  faIdCard,
  faAt,
  faKey,
  faUserGear,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { Role } from "../../../../types/Role";
import {
  Form,
  Input,
  Select,
  SubmitButton,
  FormItem,
  ResetButton,
  Switch,
} from "formik-antd";
import { Formik, FormikHelpers } from "formik";
import { Button } from "antd";
import { FormikAntDatePicker } from "../../../components/FormikAntDatePicker";
import { FormikActivityReasonField } from "../../../components/FormikActivityReasonField";

type Props = {
  userRoleList?: Role[];
  onFinish: (data: NewUserFormData) => Promise<boolean | undefined>;
};

const kNEW_USER_SCHEMA = yup.object({
  email: yup.string().email("Ingresa un email válido"),
  password: yup
    .string()
    .required("Ingresa una contraseña")
    .min(8, "La contraseña necesita al menos 8 caracteres."),
  corporateEmail: yup
    .string()
    .required("Ingresa un email")
    .email("Ingresa un email válido"),
  dni: yup
    .string()
    .required("Ingresa un número de DNI.")
    .length(8, "Ingrese un número de DNI válido.")
    .matches(/^(0|1)?\d{8}$/, "Ingrese un DNI válido."),
  name: yup.string().min(3, "Ingrese un nombre válido."),
  lastname: yup.string().min(3, "Ingrese un apellido válido."),
  username: yup
    .string()
    .required("Es necesario un nombre de usuario.")
    .min(6, "Es necesario al menos 6 caracteres."),
  birthDate: yup
    .date()
    .required("Es necesario registrar la fecha de nacimiento."),
  roleId: yup
    .number()
    .required("Es necesario especificar un rol para el usuario."),
  reason: yup
    .string()
    .required("Especifique el motivo porque registra al nuevo usuario.")
    .min(16, "Debe contener al menos 16 caracteres."),
});

export const UserCreateForm: FC<Props> = ({ userRoleList, onFinish }) => {
  const onSubmit = useCallback(
    (values: NewUserFormData, helpers: FormikHelpers<NewUserFormData>) => {
      helpers.setSubmitting(true);
      onFinish(values).then((res) => {
        if (res) helpers.resetForm();
        helpers.setSubmitting(false);
      });
    },
    [onFinish],
  );

  return (
    <Formik<NewUserFormData>
      validationSchema={kNEW_USER_SCHEMA}
      onSubmit={onSubmit}
      initialValues={{} as NewUserFormData}
      validateOnMount
      validateOnBlur
      validateOnChange={false}
    >
      <Form>
        <FormItem name={"name"} label={"Nombre(s)"} required>
          <Input
            allowClear
            prefix={<FontAwesomeIcon icon={faICursor} />}
            name={"name"}
            placeholder={"Nombre(s)"}
          />
        </FormItem>
        <FormItem name={"lastname"} label={"Apellido(s)"} required>
          <Input
            allowClear
            prefix={<FontAwesomeIcon icon={faICursor} />}
            name={"lastname"}
            placeholder={"Apellido(s)"}
          />
        </FormItem>
        <FormItem name={"dni"} label={"DNI"} required>
          <Input
            prefix={<FontAwesomeIcon icon={faIdCard} />}
            name={"dni"}
            placeholder={"N° DNI"}
          />
        </FormItem>
        <FormItem name={"corporateEmail"} label={"Correo corporativo"} required>
          <Input
            allowClear
            prefix={<FontAwesomeIcon icon={faAt} />}
            name={"corporateEmail"}
            placeholder={"Correo Corporativo"}
          />
        </FormItem>

        <FormItem name={"email"} label={"Correo secundario"}>
          <Input
            allowClear
            prefix={<FontAwesomeIcon icon={faAt} />}
            name={"email"}
            placeholder={"Correo Secundario"}
          />
        </FormItem>

        <FormItem name={"username"} label={"Nombre de Usuario"} required>
          <Input
            autoComplete={"off"}
            allowClear
            prefix={<FontAwesomeIcon icon={faICursor} />}
            name={"username"}
            placeholder={"Nombre de Usuario"}
          />
        </FormItem>

        <FormItem name={"password"} label={"Contraseña"} required>
          <Input.Password
            allowClear
            autoComplete={"off"}
            prefix={<FontAwesomeIcon icon={faKey} />}
            name={"password"}
            placeholder={"Contraseña"}
          />
        </FormItem>

        <FormItem name={"roleId"} required>
          <Select
            placeholder={"Seleccione un rol de usuario"}
            suffixIcon={<FontAwesomeIcon icon={faUserGear} />}
            options={userRoleList}
            name={"roleId"}
            fieldNames={{
              label: "label",
              value: "id",
            }}
          />
        </FormItem>
        <FormItem name={"birthDate"} required>
          <FormikAntDatePicker name={"birthDate"} />
        </FormItem>
        <FormItem name={"enabled"} label={"Estado"}>
          <Switch name={"enabled"} />
        </FormItem>

        <FormikActivityReasonField />

        <Button.Group>
          <SubmitButton
            type={"primary"}
            icon={<FontAwesomeIcon icon={faPaperPlane} />}
          >
            Guardar
          </SubmitButton>
          <ResetButton>Borrar</ResetButton>
        </Button.Group>
      </Form>
    </Formik>
  );
};
