import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { Button, Layout, Switch, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGear,
  faUser,
  faArrowLeft,
  faBars,
  faSun,
  faMoon,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useDayNightTheme } from "../../hooks/SwitchTheme";
import { UserModal } from "./UserModal";
import { useDashboardHeader } from "../../hooks/Header";
import { fastFormatDate } from "../../../utils/Dates";

type Props = {
  openDrawer: () => void;
  isMediumScreen: boolean;
};

const { Header } = Layout;

export const DashboardLayoutHeader: FC<Props> = ({
  isMediumScreen,
  openDrawer,
}) => {
  const [currentTime, setCurrentTime] = useState<string>("");
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const { setDarkMode, darkMode } = useDayNightTheme();
  const switchModal = useCallback(() => {
    setModalVisible((old) => !old);
  }, [setModalVisible]);

  const { title } = useDashboardHeader();

  const timeCallback = useCallback(() => {
    setCurrentTime(fastFormatDate(new Date(), "DD MMMM yyyy HH:mm:ss"));
  }, []);

  useEffect(() => {
    const id = setInterval(timeCallback, 1000);
    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <Fragment>
      {modalVisible ? <UserModal onClose={switchModal} /> : null}
      {isMediumScreen ? (
        <Header
          className={"flex flex-row pl-4 md:pl-8 pr-3 sticky top-0 z-50"}
          style={{ borderBottom: "solid 1px rgba(0, 0, 0, 0.15)" }}
        >
          <div className={"w-full h-full flex flex-row place-items-center"}>
            <div className={"flex flex-col"}>
              {title && (
                <Typography.Text
                  style={{
                    padding: 0,
                    margin: 0,
                  }}
                  type={"secondary"}
                  strong
                >
                  {title}
                </Typography.Text>
              )}
              <Typography.Text
                style={{
                  padding: 0,
                  margin: 0,
                }}
                type={"secondary"}
              >
                {currentTime}
              </Typography.Text>
            </div>
            <Button.Group className={"place-items-center ml-auto"}>
              <Button
                size={"middle"}
                shape={"round"}
                type={"text"}
                ghost={true}
                icon={<FontAwesomeIcon icon={faUser} />}
                onClick={switchModal}
              />
              {/*<Button*/}
              {/*  size={"middle"}*/}
              {/*  shape={"round"}*/}
              {/*  type={"text"}*/}
              {/*  icon={<FontAwesomeIcon icon={faGear} />}*/}
              {/*/>*/}
              <Switch
                checked={darkMode}
                checkedChildren={<FontAwesomeIcon icon={faSun} />}
                unCheckedChildren={<FontAwesomeIcon icon={faMoon} />}
                onChange={setDarkMode}
              />
            </Button.Group>
          </div>
        </Header>
      ) : (
        <div className={"flex flex-col w-full"}>
          {/*Add here*/}
          <div className={"flex flex-col p-0"}>
            <div
              className={
                "w-full h-fit flex flex-row gap-2 py-3 pl-2 place-items-center"
              }
            >
              <Button
                type={"text"}
                shape={"circle"}
                icon={<FontAwesomeIcon icon={faBars} />}
                onClick={openDrawer}
              />
              <Button
                className={"text-neutral-400 mr-2"}
                type={"text"}
                icon={<FontAwesomeIcon icon={faArrowLeft} />}
                onClick={() => {
                  navigate(-1);
                }}
              ></Button>

              <Button.Group className={"ml-auto place-items-center"}>
                <Button
                  shape={"circle"}
                  type={"text"}
                  ghost={true}
                  icon={<FontAwesomeIcon icon={faUser} />}
                />
                <Button
                  shape={"circle"}
                  type={"text"}
                  icon={<FontAwesomeIcon icon={faGear} />}
                />
                <Switch
                  checked={darkMode}
                  checkedChildren={<FontAwesomeIcon icon={faSun} />}
                  unCheckedChildren={<FontAwesomeIcon icon={faMoon} />}
                  onChange={setDarkMode}
                />
              </Button.Group>
            </div>
          </div>
          <hr className={"border-0 h-0.5 bg-neutral-200"} />
        </div>
      )}
    </Fragment>
  );
};
