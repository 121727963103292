import { Vehicle } from "../../../../../types/Vehicle";
import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { useGatewayDeviceViewModel } from "../../../../../viewmodel/GatewayDevice";
import { Card, notification, Radio as AntdRadio, Typography } from "antd";
import { useDeviceDataViewModel } from "../../../../../viewmodel/DeviceData";
import { useGatewayViewModel } from "../../../../../viewmodel/Gateway";
import { AppLoader } from "../../../../components/AppLoader";
import { EmptyData } from "../../../../components/Empty";
import { DashboardSubpageContainer } from "../../../../components/DashboardContainer";
import { Gateway } from "../../../../../types/Gateway";
import { Formik, FormikHelpers } from "formik";
import {
  Form,
  FormItem,
  SubmitButton,
  Radio,
  Checkbox,
  DatePicker,
} from "formik-antd";
import * as Yup from "yup";
import dayjs from "dayjs";

type Props = {
  vehicle: Vehicle;
};

type FetchData = {
  devices: string[];
  dateRange: [Date, Date];
  fileType: string;
};

const kREPORT_FORM_VALIDATION = Yup.object({
  devices: Yup.array(Yup.string())
    .min(1, "Selecciona al menos 1 dispositivo.")
    .required("Selecciona al menos 1 dispositivo"),
  dateRange: Yup.array(Yup.date())
    .length(2)
    .required("Selecciona un rango correcto."),
  fileType: Yup.string().required("Selecciona un tipo de archivo."),
});

export const VehicleLogsTab: FC<Props> = ({ vehicle }) => {
  const { fetchState, downloadDeviceLogs, onFetchStateReceived } =
    useDeviceDataViewModel();
  const [selectedGw, setSelectedGw] = useState<Gateway | null>(null);
  const {
    fetchList,
    onFetchListStateReceived,
    gatewayDeviceList,
    fetchListState,
  } = useGatewayDeviceViewModel();
  const {
    fetchVehicleGateway,
    fetchGatewayState,
    onFetchGatewayStateReceived,
    vehicleGateway,
  } = useGatewayViewModel();

  useEffect(() => {
    if (!!selectedGw) void fetchList(selectedGw.id, { historic: true });
  }, [selectedGw]);

  useEffect(() => {
    void fetchVehicleGateway(vehicle.id);
  }, []);

  useEffect(() => {
    if (!!fetchListState && !fetchListState.loading) {
      if (fetchListState.hasError) {
        notification.error({
          message: "Error al obtener los dispositivos.",
          description: fetchListState.error?.message,
        });
      }
      onFetchListStateReceived();
    }
  }, [fetchListState]);

  useEffect(() => {
    if (!!fetchGatewayState && !fetchGatewayState.loading) {
      if (fetchGatewayState.hasError) {
        notification.error({
          message: "Error al obtener el gateway.",
          description: fetchGatewayState.error?.message,
        });
      } else {
        if (!!vehicleGateway && vehicleGateway.length > 0) {
          setSelectedGw(vehicleGateway[0]);
        }
      }
      onFetchGatewayStateReceived();
    }
  }, [fetchGatewayState]);

  useEffect(() => {
    if (!!fetchState && !fetchState.loading) {
      if (fetchState.hasError) {
        notification.error({
          message: "Error al obtener los datos.",
          description: fetchState.error?.message,
        });
        console.log(fetchState.error);
      }
      onFetchStateReceived();
    }
  }, [fetchState]);

  const fetchLogs = useCallback(
    (formData: FetchData, helpers: FormikHelpers<any>) => {
      downloadDeviceLogs(
        selectedGw!!.key,
        formData.devices,
        formData.dateRange[0],
        formData.dateRange[1],
        formData.fileType
      ).then((_) => {
        helpers.setSubmitting(false);
      });
    },
    [downloadDeviceLogs]
  );

  return (
    <DashboardSubpageContainer>
      <AppLoader
        loading={
          (!!fetchListState && fetchListState.loading) ||
          (!!fetchGatewayState && fetchGatewayState.loading) ||
          (!!fetchState && fetchState.loading)
        }
      />

      {!vehicleGateway || vehicleGateway.length === 0 ? (
        <EmptyData
          description={
            "El vehículo no está vinculado a ningún gateway, no es posible generar reportes"
          }
          title={"No hay reportes"}
        />
      ) : (
        <div
          className={
            "w-full h-fit gap-2 flex flex-col relative overflow-x-hidden overflow-y-auto"
          }
        >
          <Typography.Text type={"secondary"}>
            Selecciona un Gateway
          </Typography.Text>
          <AntdRadio.Group
            onChange={(selection) => setSelectedGw(selection.target.value)}
            value={selectedGw}
          >
            {vehicleGateway?.map((it, index) => {
              return (
                <AntdRadio key={`gw-${it.key}`} value={it}>
                  {it.key}
                </AntdRadio>
              );
            })}
          </AntdRadio.Group>
          {!!selectedGw && (
            <Fragment>
              {!!gatewayDeviceList && gatewayDeviceList.length >= 1 ? (
                <Card className={`${gatewayDeviceList?.length}`}>
                  <Formik<FetchData>
                    initialValues={{} as any}
                    onSubmit={fetchLogs}
                    validationSchema={kREPORT_FORM_VALIDATION}
                  >
                    <Form layout={"vertical"}>
                      <FormItem name={"device"} label={"Dispositivo"}>
                        <Checkbox.Group
                          name={"devices"}
                          options={gatewayDeviceList?.map((it) => ({
                            label: it.Device.key,
                            value: it.Device.key,
                          }))}
                        />
                      </FormItem>
                      <FormItem name={"dateRange"} label={"Periodo"}>
                        <DatePicker.RangePicker
                          name={"dateRange"}
                          presets={[
                            {
                              label: "Hoy",
                              value: [
                                dayjs().startOf("day"),
                                dayjs().endOf("day"),
                              ],
                            },
                            {
                              label: "Esta semana",
                              value: [dayjs().add(-7, "d"), dayjs()],
                            },
                            {
                              label: "Últimas 2 semanas",
                              value: [dayjs().add(-14, "d"), dayjs()],
                            },
                            {
                              label: "Este mes",
                              value: [dayjs().add(-30, "d"), dayjs()],
                            },
                            {
                              label: "Últimos 3 meses",
                              value: [dayjs().add(-90, "d"), dayjs()],
                            },
                          ]}
                        />
                      </FormItem>
                      <FormItem name={"fileType"} label={"Tipo de archivo"}>
                        <Radio.Group
                          name={"fileType"}
                          options={[
                            {
                              label: "CSV",
                              value: "csv",
                            },
                          ]}
                        />
                      </FormItem>
                      <SubmitButton>Descargar Datos</SubmitButton>
                    </Form>
                  </Formik>
                </Card>
              ) : (
                <Typography.Text type={"secondary"}>
                  No hay variables disponibles en el gateway seleccionado.
                </Typography.Text>
              )}
            </Fragment>
          )}
        </div>
      )}
    </DashboardSubpageContainer>
  );
};
