import { FC, useEffect } from "react";
import { DashboardSubpageContainer } from "../../components/DashboardContainer";
import { DashboardSubpageHeader } from "../../components/DashboardHeader";
import { CustomerCreateForm } from "./components/Form";
import { toast } from "react-toastify";
import { useCustomerViewModel } from "../../../viewmodel/Customer";
import { AppLoader } from "../../components/AppLoader";
import { DashboardBreadCrumb } from "../../components/DashboardBreadcrumb";

type Props = {};
export const DashboardCustomerCreatePage: FC<Props> = ({}) => {
  const { createCustomer, createCustomerState, onCreateCustomerStateReceived } =
    useCustomerViewModel();

  useEffect(() => {
    if (!!createCustomerState && !createCustomerState.loading) {
      if (createCustomerState.hasError) {
        toast.error(createCustomerState.error?.message);
      } else {
        toast.success("Cliente registrado correctamente.");
      }
      onCreateCustomerStateReceived();
    }
  }, [createCustomerState]);

  return (
    <DashboardSubpageContainer className={"w-full h-fit overflow-x-hidden"}>
      <AppLoader
        loading={!!createCustomerState && createCustomerState.loading}
      />
      <DashboardBreadCrumb
        items={[
          {
            pathname: "/dashboard/customers",
            title: "Clientes",
          },
          {
            title: "Nuevo",
            active: true,
          },
        ]}
      />
      <DashboardSubpageHeader title={"Nuevo Cliente"} />
      <CustomerCreateForm onFinish={createCustomer} />
    </DashboardSubpageContainer>
  );
};
